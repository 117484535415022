import { instance } from "./../../helpers/instance";
import { CURRENT_USER } from "./../types/index";
import { toast } from "react-toastify";

// User Login
export const signin = (data) => async (dispatch) => {
  try {
    const response = await instance.post("users/login", data);

    if (response.data !== "") {
      // console.log(response.data);

      dispatch({ type: CURRENT_USER, payload: response.data });
    }

    return response.data;
  } catch (error) {
    console.log(error);

    if (error?.response && error?.response?.status === 403) {
      toast.error("Incorrect Credentials !", {
        position: "top-right",
      });
    }

    throw error;
  }
};
