import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  Form,
  Alert,
  ListGroup,
} from "react-bootstrap";
import {
  getMoqBids,
  createBid,
  editBid,
  deleteBid,
  getMoreMoqBids,
} from "./../../store/actions/BidActions";
import { getMoqs, showMoq } from "./../../store/actions/MoqActions";
import { getSizes } from "./../../store/actions/SizeActions";
import { getColors } from "./../../store/actions/ColorActions";
import { getProduct } from "./../../store/actions/ProductActions";
import { instance, setAuthToken } from "./../../helpers/instance";
import { Formik } from "formik";
import * as yup from "yup";
import { createSchema } from "../../helpers/validation/bid";
import { toast } from "react-toastify";
import Select from "react-select";
import BlockLoader from "./../contentloaders/BlockLoader";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import LoadingOverlay from "react-loading-overlay-ts";
// import { date } from 'yup';
import Moment from "react-moment";
import { titleCase } from "./../../utils/index";
import BidItem from "./../common/BidItem";

class Bids extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      moqs_data: [],

      bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],

      readable_colors: [],

      readable_sizes: [],

      product: {},

      loading: false,

      bid: {},

      editModal: false,

      selectedProduct: null,

      checked: false,

      selectedMoq: null,

      priviledge: 0,

      edit_bid_loading: false,

      contacts: [],

      selected_contacts: [],

      all_contacts: [],

      unpaid_contacts: [],

      paid_contacts: [],

      send_sms_modal: false,

      message: "",

      contacts_filter: "all_contacts",

      deleteModal: false,

      selected_bid: null,

      bidRemarksModal: false,

      remark: "",

      showDeliveryStatusAlertModal: false,

      data: {},
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const res = await this.props.showMoq(params.id);

    let bids_result = await this.props.getMoqBids(params.id);

    if (bids_result && bids_result.orders !== undefined) {
      let bids = bids_result.orders,
        all_contacts = [],
        paid_contacts = [],
        unpaid_contacts = [],
        contacts = [];

      bids.forEach((bid) => {
        all_contacts.push({
          label: bid.user[0].msisdn,

          value: bid.user[0].msisdn,
        });

        contacts.push({
          label: bid.user[0].msisdn,

          value: bid.user[0].msisdn,
        });

        if (bid.status === 0) {
          unpaid_contacts.push({
            label: bid.user[0].msisdn,

            value: bid.user[0].msisdn,
          });
        }

        if (bid.status === 1) {
          paid_contacts.push({
            label: bid.user[0].msisdn,

            value: bid.user[0].msisdn,
          });
        }
      });

      this.setState({
        all_contacts,
        unpaid_contacts,
        paid_contacts,
        contacts,
        selected_contacts: contacts,
      });
    }

    await this.props.getSizes();

    await this.props.getColors();

    const result = await this.props.getMoqs();

    await this.getMoqs(result);

    this.getDefaultProduct(res.product[0].id);

    let response = await this.setProductSizesAndColors(res.product[0].id);

    if (response) {
      this.setState({ loading: false });
    }
  };

  loadContacts = () => {
    const {
      bids: { data },
    } = this.props;

    let bids = data,
      all_contacts = [],
      paid_contacts = [],
      unpaid_contacts = [],
      contacts = [];

    bids.forEach((bid) => {
      all_contacts.push({
        label: bid.user[0].msisdn,

        value: bid.user[0].msisdn,
      });

      contacts.push({
        label: bid.user[0].msisdn,

        value: bid.user[0].msisdn,
      });

      if (bid.status === 0) {
        unpaid_contacts.push({
          label: bid.user[0].msisdn,

          value: bid.user[0].msisdn,
        });
      }

      if (bid.status === 1) {
        paid_contacts.push({
          label: bid.user[0].msisdn,

          value: bid.user[0].msisdn,
        });
      }
    });

    this.setState({
      all_contacts,
      unpaid_contacts,
      paid_contacts,
      contacts,
      selected_contacts: contacts,
    });
  };

  handleInputChange = (e, index) => {
    const { bid_attributes } = this.state;

    const { name, value } = e.target;

    let attributes = [...bid_attributes];

    attributes[index][name] = parseInt(value);

    this.setState({ bid_attributes: attributes });
  };

  handleRemoveClick = (index) => {
    const { bid_attributes } = this.state;

    let list = [...bid_attributes];

    list.splice(index, 1);

    this.setState({ bid_attributes: list });
  };

  handleAddClick = () => {
    // console.log('you clicked me');

    let { bid_attributes } = this.state;

    this.setState({
      bid_attributes: [
        ...bid_attributes,
        { size_id: 0, quantity: 0, color_id: 0 },
      ],
    });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleSendSMSModal = () => {
    this.setState({ send_sms_modal: !this.state.send_sms_modal });
  };

  toggleDeliveryStatusAlertModal = (val = null) => {
    if (val && val !== null) {
      this.setState({ data: val });
    }
    this.setState({
      showDeliveryStatusAlertModal: !this.state.showDeliveryStatusAlertModal,
    });
  };

  toggleDeleteBidModal = (bid = null) => {
    if (bid !== null) {
      this.setState({ selected_bid: bid });
    }

    this.setState({ deleteModal: !this.state.deleteModal });
  };

  toggleBidRemarksModal = (bid = null) => {
    if (bid !== null) {
      this.setState({ selected_bid: bid });
    }

    this.setState({ bidRemarksModal: !this.state.bidRemarksModal });
  };

  createBid = async (values) => {
    try {
      const {
        match: { params },
      } = this.props;

      let data = [],
        quantity = 0;

      const { bid_attributes, checked } = this.state;

      for (let i = 0; i < bid_attributes.length; i++) {
        if (bid_attributes[i].size_id === 0) {
          return toast.warning(
            "Please select appropriate size in bid attributes !",
            {
              position: "top-right",
            }
          );
        }

        if (bid_attributes[i].color_id === 0) {
          return toast.warning(
            "Please select appropriate color in bid attributes  !",
            {
              position: "top-right",
            }
          );
        }

        if (bid_attributes[i].quantity === 0) {
          return toast.warning(
            "Quantity On Bid Attributes Should Be More Than Zero !",
            {
              position: "top-right",
            }
          );
        }
      }

      if (
        bid_attributes.length > 0 &&
        bid_attributes[0].size_id !== 0 &&
        bid_attributes[0].quantity !== 0 &&
        bid_attributes[0].color_id !== 0
      ) {
        data = bid_attributes;

        bid_attributes.forEach((attribute) => {
          quantity = quantity + attribute.quantity;
        });

        if (parseInt(values.quantity) !== quantity) {
          toast.warning(
            "Sum Of Quantity On Bid Attrbutes Must Be Same As Quantity Provided !",
            {
              position: "top-right",
            }
          );

          return null;
        }
      }

      if (checked === true) {
        values.send_sms = 1;
      } else {
        delete values.send_sms;
      }

      values.status = 0;

      values.bid_attributes = data;

      const result = await this.props.createBid(values);

      if (result) {
        toast.success("New Bid Created Successfully !", {
          position: "top-right",
        });

        this.setState({
          showModal: false,
          bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],
        });

        // await this.props.getMoqBids();

        await this.props.getMoqBids(params.id);

        return;
      }
    } catch (error) {
      if (
        error &&
        error.response.data !== undefined &&
        error.response.data.message !== undefined
      ) {
        return toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      }

      toast.error("Please try again later !", {
        position: "top-right",
      });
    }
  };

  getMoqs = async (result) => {
    try {
      if (result.moqs !== undefined) {
        let moqs = result.moqs,
          data = [];

        for (let i = 0; i < moqs.length; i++) {
          data.push({
            value: moqs[i].id,

            label: moqs[i].product[0].name,

            product_id: moqs[i].product[0].id,
          });
        }

        // console.log(data);

        this.setState({ moqs_data: data });
      }

      // const result = await this.props.getMoqs();

      // if(result) {

      //     console.log(result);

      // let colours = result.colors, selectedColors = [];

      // this.setState({ selectedColors });

      // }
    } catch (error) {
      console.log(error);
    }
  };

  getDefaultProduct = (id) => {
    const { moqs_data } = this.state;

    let result = moqs_data.find((data) => {
      return data.product_id === id;
    });

    // console.log(result)

    this.setState({ selectedProduct: result });

    return result;
  };

  setProductSizesAndColors = async (id) => {
    try {
      const product = await this.props.getProduct(id);

      if (product) {
        this.setState({
          readable_colors: product.readable_colors,
          readable_sizes: product.readable_sizes,
          product,
        });
      }

      return product;
    } catch (error) {
      console.log(error);
    }
  };

  toggleEditModal = async (bid = null) => {
    const { moqs_data } = this.state;

    if (bid !== null) {
      this.setState({ edit_bid_loading: true, selected_bid: bid });

      let moq = moqs_data.find((m) => {
        return m.value === bid.moq_id;
      });

      if (bid.product !== undefined) {
        await this.setProductSizesAndColors(bid.product[0].id);
      }
      const result = await this.props.getMoqs();

      await this.getMoqs(result);

      if (bid.bid_attributes !== undefined && bid.bid_attributes.length === 0) {
        this.setState({ selectedMoq: moq });
      } else {
        this.setState({ bid_attributes: bid.bid_attributes, selectedMoq: moq });
      }

      this.setState({ edit_bid_loading: false });
    }

    if (bid === null) {
      this.setState({
        bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],
        bid: {},
        editModal: !this.state.editModal,
      });
    } else {
      this.setState({
        editModal: !this.state.editModal,
        bid: bid === null ? {} : bid,
      });
    }

    // this.setState({ editModal: !this.state.editModal, bid: bid === null ? {} : bid });
  };

  editBid = async (values) => {
    try {
      let data = [],
        quantity = 0;

      const { bid_attributes, bid } = this.state;

      if (
        bid_attributes.length > 0 &&
        bid_attributes[0].size_id !== 0 &&
        bid_attributes[0].quantity !== 0 &&
        bid_attributes[0].color_id !== 0
      ) {
        data = bid_attributes;

        bid_attributes.forEach((attribute) => {
          quantity = quantity + attribute.quantity;
        });

        if (parseInt(values.quantity) !== quantity) {
          toast.warning(
            "Sum Of Quantity On Bid Attrbutes Must Be Same As Quantity Provided !",
            {
              position: "top-right",
            }
          );

          return null;
        }
      }

      if (values.shipping_status === 1 && values.total_shipping === 0) {
        return toast.error(
          "Total Shipping Fee is Required And Must Be Greater Than 0 !",
          {
            position: "top-right",
          }
        );
      }

      values.bid_attributes = data;

      const result = await this.props.editBid(
        this.state.selected_bid.id,
        values
      );

      if (result) {
        toast.success("Bid Updated Successfully !", {
          position: "top-right",
        });

        this.setState({
          editModal: false,
          bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],
          showDeliveryStatusAlertModal: false,
          data: null,
        });

        const {
          match: { params },
        } = this.props;

        await this.props.getMoqBids(params.id);
      }
    } catch (error) {
      // console.log(error.response);

      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data
      ) {
        // console.log(error.response.data.message);

        return toast.warning(error.response.data.message, {
          position: "top-right",
        });
      }

      toast.warning(
        "We could not finish your request. Please try again later !",
        {
          position: "top-right",
        }
      );
    }
  };

  deleteBid = async () => {
    try {
      const { selected_bid } = this.state;

      this.setState({ loading: true });

      let obj = { active: 0 };

      const result = await this.props.deleteBid(selected_bid.id, obj);

      if (result) {
        const {
          match: { params },
        } = this.props;

        await this.props.getMoqBids(params.id);

        this.setState({ loading: false, deleteModal: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-right",
        });
      }
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioContactsFilterChange = (e) => {
    this.setState({ contacts_filter: e.target.value });

    if (e.target.value === "all_contacts") {
      this.setState({
        contacts: this.state.all_contacts,
        selected_contacts: this.state.all_contacts,
      });
    }

    if (e.target.value === "paid_contacts") {
      this.setState({
        contacts: this.state.paid_contacts,
        selected_contacts: this.state.paid_contacts,
      });
    }

    if (e.target.value === "unpaid_contacts") {
      this.setState({
        contacts: this.state.unpaid_contacts,
        selected_contacts: this.state.unpaid_contacts,
      });
    }
  };

  sendBulkSMS = async () => {
    try {
      let msisdn = [],
        obj = {};

      const { selected_contacts, message } = this.state;

      selected_contacts.forEach((contact) => {
        msisdn.push(contact.value);
      });

      if (selected_contacts.length === 0) {
        toast.warning("Please select atleast one contact to send sms !", {
          position: "top-right",
        });

        this.setState({ loading: false });

        return;
      }

      if (message === "") {
        toast.warning("Message Is Required !", {
          position: "top-right",
        });

        this.setState({ loading: false });

        return;
      }

      obj.msisdn = msisdn;

      obj.message = message;

      this.setState({ loading: true });

      setAuthToken(instance);

      const response = await instance.post("sms", obj);

      if (response.data !== "") {
        this.setState({ loading: false, send_sms_modal: false, message: "" });

        toast.success("Message Sent To Recepients Successfully !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      toast.error("An Error Occured. Please try again later !", {
        position: "top-right",
      });
    }
  };

  addRemark = async () => {
    try {
      const { remark, selected_bid } = this.state;

      if (remark !== "") {
        let values = {
          bid_remarks: remark,
        };

        this.setState({ loading: true });

        const result = await this.props.editBid(selected_bid.id, values);

        if (result) {
          toast.success("Bid Remark Added Successfully !", {
            position: "top-right",
          });

          this.setState({ loading: false, bidRemarksModal: false });

          const {
            match: { params },
          } = this.props;

          await this.props.getMoqBids(params.id);
        }
      } else {
        return toast.error("Remark field in required !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      toast.error("An Error Occured. Please try again later !", {
        position: "top-right",
      });
    }
  };

  fetchMoreBids = async () => {
    try {
      const {
        match: { params },
      } = this.props;

      this.setState({ loading: true });

      const {
        bids: { next_page },
      } = this.props;

      const result = await this.props.getMoreMoqBids(params?.id, next_page);

      if (result) {
        this.setState({ loading: false });
      }

      this.loadContacts();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      bids,
      moqs: { moq },
      bids: { page, pages },
    } = this.props;

    const {
      bid_attributes,
      readable_colors,
      readable_sizes,
      product,
      loading,
      editModal,
      bid,
      priviledge,
      edit_bid_loading,
      send_sms_modal,
      selected_bid,
    } = this.state;

    return (
      <LoadingOverlay active={edit_bid_loading} spinner text="Wait ...">
        <Layout>
          <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
            <Container>
              <Row>
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  {!loading && moq.product !== undefined && (
                    <h4>
                      Bids For{" "}
                      <Link
                        to={`/admin/products/${moq.product[0].id}`}
                        style={{ textDecoration: "none" }}
                      >
                        {titleCase(moq.product[0].name)}
                      </Link>{" "}
                      - MOQ {moq.moq_number}
                    </h4>
                  )}

                  {loading && (
                    <div style={{ width: "100px", height: "35px" }}>
                      <BlockLoader
                        width="300px"
                        height="35"
                        innerHeight="100%"
                        innerWidth="100%"
                        style={{ margin: "0", padding: "0" }}
                      />
                    </div>
                  )}

                  {!loading && (
                    <div>
                      <Button
                        disabled={moq.moq_ordered === 1 ? true : false}
                        variant="success"
                        size="sm"
                        onClick={() => this.toggleModal()}
                      >
                        New Bid
                      </Button>

                      <Button
                        style={{ marginLeft: "10px" }}
                        variant="primary"
                        size="sm"
                        onClick={() => this.toggleSendSMSModal()}
                      >
                        Send SMS
                      </Button>
                    </div>
                  )}
                </Col>

                <Col sm="12">
                  {loading && (
                    <BlockLoader
                      width="100%"
                      height="400px"
                      innerWidth="100%"
                      innerHeight="100%"
                    />
                  )}
                </Col>

                <Col sm="12">
                  {!loading &&
                    bids.data.map((bid, i) => (
                      <BidItem
                        key={i}
                        bid={bid}
                        moq={moq}
                        toggleEditModal={this.toggleEditModal}
                        toggleBidRemarksModal={this.toggleBidRemarksModal}
                        toggleDeleteBidModal={this.toggleDeleteBidModal}
                      />
                    ))}
                </Col>

                {page < pages && (
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "2em",
                    }}
                  >
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => this.fetchMoreBids()}
                      disabled={loading}
                    >
                      {loading === true ? "Loading ..." : "Load More"}
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </Col>

          {/* New Bid Modal */}
          <Modal
            show={this.state.showModal}
            onHide={this.toggleModal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>New Bid</Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                moq_id:
                  moq !== null && moq !== undefined && moq.id !== undefined
                    ? moq.id.toString()
                    : "",
                quantity: "",
                shipping: "",
                msisdn: "",
                bid_remarks: "",
              }}
              validationSchema={createSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // values.moq_id = values.moq_id.value !== undefined ? (values.moq_id.value).toString() : null;

                values.quantity = values.quantity.toString();

                values.msisdn = values.msisdn.toString();

                this.createBid(values);

                // console.log(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <div>
                  <Modal.Body>
                    <Row>
                      <Form.Group
                        as={Col}
                        sm="6"
                        controlId="msisdn"
                        style={{ marginTop: "1em" }}
                      >
                        <Form.Label column sm="12">
                          Phone Number
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            type="number"
                            placeholder="Phone Number"
                            name="msisdn"
                            isValid={touched.msisdn && !errors.msisdn}
                            isInvalid={errors.msisdn && touched.msisdn}
                            value={values.msisdn}
                            onChange={handleChange}
                          />

                          {errors.msisdn && touched.msisdn && (
                            <Form.Control.Feedback type="invalid">
                              {errors.msisdn}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        sm="6"
                        controlId="moq_id"
                        style={{ marginTop: "1em" }}
                      >
                        <Form.Label column sm="12">
                          Product
                        </Form.Label>

                        <Col sm="12">
                          {/* <Select defaultValue={values.moq_id} value={selectedProduct !== null ? (selectedProduct.value).toString() : ''} options={moqs_data} onChange={async (value) => { 

                                                // console.log(value);

                                                await this.setProductSizesAndColors(value.product_id);
                                                
                                                setFieldValue('moq_id', value); 
                                                
                                            }} onBlur={() => { setFieldTouched('moq_id', true); }} touched={touched.moq_id} isDisabled/> */}

                          <Form.Control
                            as="select"
                            isValid={touched.moq_id && !errors.moq_id}
                            defaultValue={values.moq_id}
                            isInvalid={errors.moq_id && touched.moq_id}
                            name="moq_id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          >
                            {/* <option value="">Choose ...</option> */}

                            <option value={`${moq.id}`}>
                              {moq.product[0].name}
                            </option>

                            {/* {moqs_data.map((moq, i) => <option key={i} value={`${moq.value}`}>{moq.label}</option>)} */}
                          </Form.Control>

                          {errors.moq_id && touched.moq_id && (
                            <Form.Control.Feedback type="invalid">
                              {errors.moq_id}
                            </Form.Control.Feedback>
                          )}

                          {/* {errors.moq_id && touched.moq_id && <Form.Text style={{ color: '#dc3545' }}>{errors.moq_id}</Form.Text>} */}
                        </Col>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Col}
                        sm="6"
                        controlId="quantity"
                        style={{ marginTop: "1em" }}
                      >
                        <Form.Label column sm="12">
                          Quantity
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            type="number"
                            placeholder="Quantity"
                            name="quantity"
                            isValid={touched.quantity && !errors.quantity}
                            isInvalid={errors.quantity && touched.quantity}
                            value={values.quantity}
                            onChange={handleChange}
                          />

                          {errors.quantity && touched.quantity && (
                            <Form.Control.Feedback type="invalid">
                              {errors.quantity}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        sm="6"
                        controlId="shipping"
                        style={{ marginTop: "1em" }}
                      >
                        <Form.Label column sm="12">
                          Shipping
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            as="select"
                            isValid={touched.shipping && !errors.shipping}
                            defaultValue={values.shipping}
                            isInvalid={errors.shipping && touched.shipping}
                            name="shipping"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Choose ...</option>

                            <option value="Air">Air</option>

                            <option value="Sea">Sea</option>
                          </Form.Control>

                          {errors.shipping && touched.shipping && (
                            <Form.Control.Feedback type="invalid">
                              {errors.shipping}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>
                    </Row>
                    {(product.readable_colors !== null ||
                      product.readable_sizes !== null) && (
                      <Form.Group
                        as={Row}
                        controlId="shipping"
                        style={{ marginTop: "1em" }}
                      >
                        <Form.Label column sm="12">
                          Bid Attributes
                        </Form.Label>

                        <Col sm="12">
                          {bid_attributes.map((x, i) => {
                            return (
                              <Row style={{ marginBottom: "10px" }}>
                                <Col sm="3">
                                  <Form.Control
                                    as="select"
                                    name="size_id"
                                    value={x.size_id}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  >
                                    <option value="">Sizes ...</option>

                                    {readable_sizes !== null &&
                                      readable_sizes.map((size, i) => (
                                        <option key={i} value={size.id}>
                                          {size.label}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </Col>

                                <Col sm="3">
                                  <Form.Control
                                    as="select"
                                    name="color_id"
                                    value={x.color_id}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  >
                                    <option value="">Colors ...</option>

                                    {readable_colors !== null &&
                                      readable_colors.map((color, i) => (
                                        <option key={i} value={color.id}>
                                          {color.label}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </Col>

                                <Col sm="3">
                                  <Form.Control
                                    type="number"
                                    placeholder="Quantity"
                                    name="quantity"
                                    value={x.quantity}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  />
                                </Col>

                                <Col sm="3">
                                  {bid_attributes.length - 1 === i && (
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "10px" }}
                                      onClick={this.handleAddClick}
                                    >
                                      <FiPlus />
                                    </Button>
                                  )}

                                  {bid_attributes.length !== 1 && (
                                    <Button
                                      variant="danger"
                                      onClick={() => this.handleRemoveClick(i)}
                                    >
                                      <FiTrash2 />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>

                        {/* <Col sm="12"><div style={{ marginTop: 20 }}>{JSON.stringify(bid_attributes)}</div></Col> */}
                      </Form.Group>
                    )}
                    <Row>
                      <Form.Group
                        as={Col}
                        sm="12"
                        controlId="bid_remarks"
                        style={{ marginTop: "1em" }}
                      >
                        <Form.Label column sm="12">
                          Bid Remarks
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            as="textarea"
                            rows="3"
                            placeholder="Bid Remarks"
                            name="bid_remarks"
                            isValid={touched.bid_remarks && !errors.bid_remarks}
                            isInvalid={
                              errors.bid_remarks && touched.bid_remarks
                            }
                            value={values.bid_remarks}
                            onChange={handleChange}
                          />

                          {errors.bid_remarks && touched.bid_remarks && (
                            <Form.Control.Feedback type="invalid">
                              {errors.bid_remarks}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>
                    </Row>
                    <Form.Group
                      controlId="formBasicCheckbox"
                      style={{ marginTop: "1em" }}
                    >
                      <Form.Check
                        checked={this.state.checked}
                        onChange={(e) => {
                          this.setState({ checked: e.target.checked });
                        }}
                        type="checkbox"
                        label="Send SMS"
                      />
                    </Form.Group>

                    {this.state.checked === true && (
                      <Col sm="12">
                        <Alert variant="warning">
                          An SMS with bid details will be sent to{" "}
                          {values.msisdn}. Proceed ?
                        </Alert>
                      </Col>
                    )}
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.toggleModal}>
                      Close
                    </Button>

                    <Button variant="primary" onClick={handleSubmit}>
                      Create Bid
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>

          {/* Edit Bid Modal */}
          <Modal show={editModal} onHide={this.toggleEditModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Edit Bid</Modal.Title>
            </Modal.Header>

            <Formik
              enableReinitialize
              initialValues={{
                status: bid.status !== undefined ? bid.status : 0,
                quantity: bid.quantity !== undefined ? bid.quantity : "",
                moq_id:
                  selected_bid !== undefined && selected_bid !== null
                    ? selected_bid.moq_id
                    : "",
                msisdn:
                  bid.user !== undefined ? bid.user[0].msisdn.toString() : "",
                shipping: bid.shipping !== undefined ? bid.shipping : "",
                is_collected:
                  bid.is_collected !== undefined ? bid.is_collected : "",
                total_shipping:
                  bid.total_shipping !== undefined &&
                  bid.total_shipping !== null
                    ? bid.total_shipping
                    : 0,
                shipping_status:
                  bid.shipping_status !== undefined ? bid.shipping_status : "",
                bid_remarks: "",
              }}
              validationSchema={yup.object().shape({
                status: yup.number().required(),

                total_shipping:
                  moq.moq_order_status ===
                  "Goods arrived at Litudian - Ready to collect"
                    ? yup.number()
                    : "",
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                values.quantity = values.quantity.toString();

                values.total_shipping =
                  values.total_shipping !== null
                    ? parseInt(values.total_shipping)
                    : null;

                if (
                  bid &&
                  bid?.delivery_status !== undefined &&
                  bid?.delivery_status === 0 &&
                  values?.shipping_status === "1"
                ) {
                  // this.toggleEditModal();
                  this.toggleDeliveryStatusAlertModal(values);
                } else {
                  this.editBid(values);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <div>
                  <Modal.Body>
                    <Row>
                      <Form.Group as={Col} sm="6" controlId="msisdn">
                        <Form.Label column sm="12">
                          Phone Number
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            type="number"
                            placeholder="Phone Number"
                            name="msisdn"
                            isValid={touched.msisdn && !errors.msisdn}
                            isInvalid={errors.msisdn && touched.msisdn}
                            value={values.msisdn}
                            onChange={handleChange}
                            disabled={priviledge === 1 || priviledge === 2}
                          />

                          {errors.msisdn && touched.msisdn && (
                            <Form.Control.Feedback type="invalid">
                              {errors.msisdn}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Col} sm="6" controlId="moq_id">
                        <Form.Label column sm="12">
                          Product
                        </Form.Label>

                        <Col sm="12">
                          {/* <Select isDisabled defaultValue={values.moq_id} options={moqs_data} onChange={async (value) => { 

                                                console.log(value);
                                                
                                                setFieldValue('moq_id', value); 

                                                this.setState({ loading: true });

                                                await this.setProductSizesAndColors(value.product_id);

                                                this.setState({ loading: false });
                                                
                                            }} onBlur={() => { setFieldTouched('moq_id', true); }} touched={touched.moq_id} /> */}

                          <Form.Control
                            as="select"
                            isValid={touched.moq_id && !errors.moq_id}
                            defaultValue={values.moq_id}
                            isInvalid={errors.moq_id && touched.moq_id}
                            name="moq_id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            readOnly
                          >
                            {/* <option value="">Choose ...</option> */}

                            {selected_bid !== null &&
                              selected_bid !== undefined && (
                                <option value={selected_bid.moq_id}>
                                  {selected_bid.product[0].name}
                                </option>
                              )}

                            {/* {moqs_data.map((moq, i) => <option key={i} value={moq} selected={selectedMoq.id === moq.value}>{moq.label}</option>)} */}
                          </Form.Control>

                          {/* {errors.moq_id && touched.moq_id && <Form.Control.Feedback type="invalid">{errors.moq_id}</Form.Control.Feedback>} */}

                          {errors.moq_id && touched.moq_id && (
                            <Form.Text style={{ color: "#dc3545" }}>
                              {errors.moq_id}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} sm="6" controlId="quantity">
                        <Form.Label column sm="12">
                          Quantity
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            type="number"
                            placeholder="Quantity"
                            name="quantity"
                            isValid={touched.quantity && !errors.quantity}
                            isInvalid={errors.quantity && touched.quantity}
                            value={values.quantity}
                            onChange={handleChange}
                            readOnly={values.status === 1}
                          />

                          {errors.quantity && touched.quantity && (
                            <Form.Control.Feedback type="invalid">
                              {errors.quantity}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Col} sm="6" controlId="shipping">
                        <Form.Label column sm="12">
                          Shipping
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            as="select"
                            isValid={touched.shipping && !errors.shipping}
                            defaultValue={values.shipping}
                            isInvalid={errors.shipping && touched.shipping}
                            name="shipping"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={moq.moq_order_status !== "not placed"}
                          >
                            <option value="">Choose ...</option>

                            <option value="Air">Air</option>

                            <option value="Sea">Sea</option>
                          </Form.Control>

                          {errors.shipping && touched.shipping && (
                            <Form.Control.Feedback type="invalid">
                              {errors.shipping}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>
                    </Row>
                    {(product.readable_colors !== null ||
                      product.readable_sizes !== null) && (
                      <Form.Group as={Row} controlId="shipping">
                        <Form.Label column sm="12">
                          Bid Attributes
                        </Form.Label>

                        <Col sm="12">
                          {bid_attributes !== undefined &&
                            bid_attributes.map((x, i) => {
                              return (
                                <Row style={{ marginBottom: "10px" }} key={i}>
                                  <Col sm="3">
                                    <Form.Control
                                      as="select"
                                      name="size_id"
                                      value={x.size_id}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                      readOnly={
                                        moq.moq_order_status !== "not placed"
                                      }
                                    >
                                      <option value="">Sizes ...</option>

                                      {readable_sizes !== null &&
                                        readable_sizes.map((size, i) => (
                                          <option
                                            key={i}
                                            value={size.id}
                                            selected={x.size_id === size.id}
                                          >
                                            {size.label}
                                          </option>
                                        ))}
                                    </Form.Control>
                                  </Col>

                                  <Col sm="3">
                                    <Form.Control
                                      as="select"
                                      name="color_id"
                                      value={x.color_id}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                      readOnly={
                                        moq.moq_order_status !== "not placed"
                                      }
                                    >
                                      <option value="">Colors ...</option>

                                      {readable_colors !== null &&
                                        readable_colors.map((color, i) => (
                                          <option
                                            key={i}
                                            value={color.id}
                                            selected={x.color_id === color.id}
                                          >
                                            {color.label}
                                          </option>
                                        ))}
                                    </Form.Control>
                                  </Col>

                                  <Col sm="3">
                                    <Form.Control
                                      type="number"
                                      placeholder="Quantity"
                                      name="quantity"
                                      value={x.quantity}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                      readOnly={
                                        moq.moq_order_status !== "not placed"
                                      }
                                    />
                                  </Col>

                                  <Col sm="3">
                                    {bid_attributes.length - 1 === i && (
                                      <Button
                                        variant="success"
                                        style={{ marginRight: "10px" }}
                                        onClick={this.handleAddClick}
                                        disabled={
                                          moq.moq_order_status !== "not placed"
                                        }
                                      >
                                        <FiPlus />
                                      </Button>
                                    )}

                                    {bid_attributes.length !== 1 && (
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          this.handleRemoveClick(i)
                                        }
                                        disabled={
                                          moq.moq_order_status !== "not placed"
                                        }
                                      >
                                        <FiTrash2 />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              );
                            })}
                        </Col>

                        {/* <Col sm="12"><div style={{ marginTop: 20 }}>{JSON.stringify(bid_attributes)}</div></Col> */}
                      </Form.Group>
                    )}

                    <Row>
                      {priviledge === 2 && (
                        <Form.Group as={Col} sm="6" controlId="total_shipping">
                          <Form.Label column sm="12">
                            Total Shipping
                          </Form.Label>

                          <Col sm="12">
                            <Form.Control
                              type="text"
                              placeholder="Total Shipping"
                              name="total_shipping"
                              isValid={
                                touched.total_shipping && !errors.total_shipping
                              }
                              isInvalid={
                                errors.total_shipping && touched.total_shipping
                              }
                              value={values.total_shipping}
                              onChange={handleChange}
                              disabled={
                                moq.moq_order_status !==
                                "Goods arrived at Litudian - Ready to collect"
                                  ? true
                                  : false
                              }
                            />

                            {errors.total_shipping &&
                              touched.total_shipping && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.total_shipping}
                                </Form.Control.Feedback>
                              )}
                          </Col>
                        </Form.Group>
                      )}

                      {priviledge === 2 && (
                        <Form.Group as={Col} sm="6" controlId="shipping_status">
                          <Form.Label column sm="12">
                            Shipping Status
                          </Form.Label>

                          <Col sm="12">
                            <Form.Control
                              as="select"
                              isValid={
                                touched.shipping_status &&
                                !errors.shipping_status
                              }
                              defaultValue={values.shipping_status}
                              isInvalid={
                                errors.shipping_status &&
                                touched.shipping_status
                              }
                              name="shipping_status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={
                                moq.moq_order_status !==
                                  "Goods arrived at Litudian - Ready to collect" ||
                                priviledge !== 2
                              }
                            >
                              <option>Choose ...</option>

                              <option
                                value={0}
                                selected={
                                  bid.shipping_status !== undefined &&
                                  bid.shipping_status === 0
                                    ? true
                                    : false
                                }
                              >
                                Shipping Fee Unpaid
                              </option>

                              <option
                                value={1}
                                selected={
                                  bid.shipping_status !== undefined &&
                                  bid.shipping_status === 1
                                    ? true
                                    : false
                                }
                              >
                                Shipping Fee Paid
                              </option>
                            </Form.Control>

                            {errors.shipping_status &&
                              touched.shipping_status && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.shipping_status}
                                </Form.Control.Feedback>
                              )}
                          </Col>
                        </Form.Group>
                      )}
                    </Row>
                    <Row>
                      <Form.Group as={Col} sm="6" controlId="status">
                        <Form.Label column sm="12">
                          Payment Status
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            as="select"
                            isValid={touched.status && !errors.status}
                            defaultValue={values.status}
                            isInvalid={errors.status && touched.status}
                            name="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={
                              priviledge !== 2 ||
                              moq.moq_order_status !== "not placed"
                            }
                          >
                            <option>Choose ...</option>

                            <option
                              value={0}
                              selected={
                                bid.status !== undefined && bid.status === 0
                                  ? true
                                  : false
                              }
                            >
                              Unpaid
                            </option>

                            <option
                              value={1}
                              selected={
                                bid.status !== undefined && bid.status === 1
                                  ? true
                                  : false
                              }
                            >
                              Paid
                            </option>

                            {/* <option value={2} selected={bid.status !== undefined && bid.status === 2 ? true : false}>Collected</option> */}
                          </Form.Control>

                          {errors.status && touched.status && (
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Col} sm="6" controlId="is_collected">
                        <Form.Label column sm="12">
                          Collection Status
                        </Form.Label>

                        <Col sm="12">
                          <Form.Control
                            as="select"
                            isValid={
                              touched.is_collected && !errors.is_collected
                            }
                            defaultValue={values.is_collected}
                            isInvalid={
                              errors.is_collected && touched.is_collected
                            }
                            name="is_collected"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={
                              moq.moq_order_status !==
                                "Goods arrived at Litudian - Ready to collect" ||
                              priviledge !== 2
                            }
                          >
                            <option>Choose ...</option>

                            <option
                              value={0}
                              selected={
                                bid.is_collected !== undefined &&
                                bid.is_collected === 0
                                  ? true
                                  : false
                              }
                            >
                              Not Collected
                            </option>

                            <option
                              value={1}
                              selected={
                                bid.is_collected !== undefined &&
                                bid.is_collected === 1
                                  ? true
                                  : false
                              }
                            >
                              Collected
                            </option>
                          </Form.Control>

                          {errors.is_collected && touched.is_collected && (
                            <Form.Control.Feedback type="invalid">
                              {errors.is_collected}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>
                    </Row>

                    <Form.Group as={Row} controlId="bid_remarks">
                      <Form.Label column sm="12">
                        Bid Remarks
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Bid Remarks"
                          name="bid_remarks"
                          isValid={touched.bid_remarks && !errors.bid_remarks}
                          isInvalid={errors.bid_remarks && touched.bid_remarks}
                          value={values.bid_remarks}
                          onChange={handleChange}
                        />

                        {errors.bid_remarks && touched.bid_remarks && (
                          <Form.Control.Feedback type="invalid">
                            {errors.bid_remarks}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        this.toggleEditModal(null);
                      }}
                    >
                      Close
                    </Button>

                    <Button variant="primary" onClick={handleSubmit}>
                      Edit Bid
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>

          {/* Edit Bit Shipping Status Update Modal */}
          <Modal
            show={this.state.showDeliveryStatusAlertModal}
            onHide={this.toggleDeliveryStatusAlertModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Bid</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm="12" style={{ margin: "1em 0" }}>
                  <p className="edit-bid-alert">
                    Updating shipping status topaid might initiate a delivery
                    for this particular customer. Do you want to continue ?
                  </p>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={this.toggleDeliveryStatusAlertModal}
              >
                Close
              </Button>

              <Button
                variant="primary"
                style={{ backgroundColor: "#FF4C00", border: 0 }}
                onClick={() => {
                  this.editBid(this.state.data);
                }}
              >
                Proceed
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Send Bulk SMS Modal */}
          <Modal
            show={send_sms_modal}
            onHide={this.toggleSendSMSModal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Send SMS</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm="9">
                  <Row>
                    <Col sm="12" style={{ margin: "1em 0" }}>
                      <Select
                        options={this.state.contacts}
                        isMulti
                        isClearable
                        value={this.state.selected_contacts}
                        onChange={(value) => {
                          // console.log(value);

                          this.setState({ selected_contacts: value });
                        }}
                      />
                    </Col>

                    <Col sm="12">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Write..."
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col sm="3" style={{ margin: "1em 0" }}>
                  <Form.Check
                    label="All"
                    type="radio"
                    name="contacts_filter"
                    value="all_contacts"
                    checked={this.state.contacts_filter === "all_contacts"}
                    onChange={this.handleRadioContactsFilterChange}
                  />

                  <Form.Check
                    label="Paid"
                    type="radio"
                    name="contacts_filter"
                    value="paid_contacts"
                    checked={this.state.contacts_filter === "paid_contacts"}
                    onChange={this.handleRadioContactsFilterChange}
                  />

                  <Form.Check
                    label="Unpaid"
                    type="radio"
                    name="contacts_filter"
                    value="unpaid_contacts"
                    checked={this.state.contacts_filter === "unpaid_contacts"}
                    onChange={this.handleRadioContactsFilterChange}
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={this.toggleSendSMSModal}>
                Close
              </Button>

              <Button variant="primary" onClick={this.sendBulkSMS}>
                Send
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delete Bid Modal */}
          <Modal
            show={this.state.deleteModal}
            onHide={this.toggleDeleteBidModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Bid</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm="12" style={{ margin: "1em 0" }}>
                  <h5>
                    Are you sure you want to delete{" "}
                    {selected_bid !== null
                      ? `${selected_bid.user[0].first_name.toLowerCase()}'s (${
                          selected_bid.user[0].msisdn
                        })`
                      : ""}{" "}
                    bid ?
                  </h5>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={this.toggleDeleteBidModal}>
                Close
              </Button>

              <Button
                variant="primary"
                onClick={() => {
                  this.deleteBid();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Bid Remarks Modal */}
          <Modal
            show={this.state.bidRemarksModal}
            onHide={this.toggleBidRemarksModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Bid Remarks</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm="12" style={{ margin: "1em 0" }}>
                  {selected_bid !== null &&
                    selected_bid.bid_remarks !== undefined && (
                      <ListGroup as="ol" numbered>
                        {selected_bid.bid_remarks.map((remark, i) => (
                          <ListGroup.Item key={i}>
                            {remark.remarks} -{" "}
                            <Moment format="YYYY-MM-DD">
                              {remark.date_created}
                            </Moment>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                </Col>

                <Col sm="12" style={{ margin: "1em 0" }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.addRemark();
                    }}
                  >
                    <Form.Group as={Row} controlId="msisdn">
                      <Form.Label column sm="12">
                        New Remark
                      </Form.Label>

                      <Col sm="10">
                        <Form.Control
                          type="text"
                          placeholder="Write..."
                          name="remark"
                          value={this.state.remark}
                          onChange={(e) => {
                            this.setState({ remark: e.target.value });
                          }}
                        />
                      </Col>

                      <Col sm="2">
                        <Button variant="success" type="submit">
                          Add
                        </Button>
                      </Col>
                    </Form.Group>
                  </form>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.toggleBidRemarksModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bids: state.bids,

    moqs: state.moqs,

    sizes: state.sizes,

    colors: state.colors,
  };
};

export default connect(mapStateToProps, {
  getMoqBids,
  getMoqs,
  createBid,
  getSizes,
  getColors,
  getProduct,
  showMoq,
  editBid,
  deleteBid,
  getMoreMoqBids,
})(Bids);
