import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { Formik } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import {
  editUser,
  showUser,
  clearUser,
} from "./../../store/actions/UserActions";
import { editSchema } from "./../../helpers/validation/user";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      priviledge: 0,
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const result = await this.props.showUser(params.id);

    if (result) {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount = async () => {
    await this.props.clearUser();
  };

  create = async (data) => {
    try {
      data.status = 1;

      if (data.email === "") {
        delete data.email;
      }

      this.setState({ loading: true });

      const {
        match: { params },
      } = this.props;

      // data.msisdn = (data.msisdn).toString();

      // console.log(data);

      const result = await this.props.editUser(params.id, data);

      if (result) {
        this.setState({ loading: false });

        toast.success("User Details Edited Successfully !", {
          position: "top-right",
        });

        return this.props.history.push(`/admin/users`);
      }
    } catch (error) {
      this.setState({ loading: false });

      if (
        error.response !== undefined &&
        error.response.data.message !== undefined
      ) {
        toast.warning(error.response.data.message, {
          position: "top-right",
        });
      } else {
        toast.warning("An Error Occured. Please try again later !", {
          position: "top-right",
        });
      }

      console.log(error);
    }
  };

  render() {
    const { loading, priviledge } = this.state;

    const {
      users: { user },
    } = this.props;

    return (
      <LoadingOverlay active={loading} spinner text="Wait ...">
        <Layout>
          <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
            <Container>
              <Row>
                {
                  <Col sm="12">
                    <Card>
                      <Card.Body>
                        <Formik
                          enableReinitialize
                          initialValues={{
                            first_name:
                              user.first_name !== undefined
                                ? user.first_name
                                : "",
                            last_name:
                              user.last_name !== undefined
                                ? user.last_name
                                : "",
                            username:
                              user.username !== undefined ? user.username : "",
                            msisdn:
                              user.msisdn !== undefined
                                ? user.msisdn.toString()
                                : "",
                            email: user.email !== undefined ? user.email : "",
                            status: user.status !== undefined ? user.status : 0,
                            privilege:
                              user.privilege !== undefined ? user.privilege : 0,
                          }}
                          validationSchema={editSchema}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            this.create(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <Form
                              noValidate
                              onSubmit={(e) => {
                                e.preventDefault();

                                handleSubmit();
                              }}
                            >
                              <Row>
                                <Form.Group
                                  as={Col}
                                  sm="6"
                                  controlId="first_name"
                                >
                                  <Form.Label column sm="12">
                                    Firstname
                                  </Form.Label>

                                  <Col sm="12">
                                    <Form.Control
                                      type="text"
                                      placeholder="Firstname"
                                      name="first_name"
                                      isValid={
                                        touched.first_name && !errors.first_name
                                      }
                                      isInvalid={
                                        errors.first_name && touched.first_name
                                      }
                                      value={values.first_name}
                                      onChange={handleChange}
                                    />

                                    {errors.first_name &&
                                      touched.first_name && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.first_name}
                                        </Form.Control.Feedback>
                                      )}
                                  </Col>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  sm="6"
                                  controlId="last_name"
                                >
                                  <Form.Label column sm="12">
                                    Lastname
                                  </Form.Label>

                                  <Col sm="12">
                                    <Form.Control
                                      type="text"
                                      placeholder="Lastname"
                                      name="last_name"
                                      isValid={
                                        touched.last_name && !errors.last_name
                                      }
                                      isInvalid={
                                        errors.last_name && touched.last_name
                                      }
                                      value={values.last_name}
                                      onChange={handleChange}
                                    />

                                    {errors.last_name && touched.last_name && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.last_name}
                                      </Form.Control.Feedback>
                                    )}
                                  </Col>
                                </Form.Group>
                              </Row>

                              <Form.Group as={Col} sm="12" controlId="username">
                                <Form.Label column sm="12">
                                  Username
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    name="username"
                                    isValid={
                                      touched.username && !errors.username
                                    }
                                    isInvalid={
                                      errors.username && touched.username
                                    }
                                    value={values.username}
                                    onChange={handleChange}
                                  />

                                  {errors.username && touched.username && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.username}
                                    </Form.Control.Feedback>
                                  )}
                                </Col>
                              </Form.Group>
                              <Row>
                                <Form.Group as={Col} sm="6" controlId="email">
                                  <Form.Label column sm="12">
                                    Email
                                  </Form.Label>

                                  <Col sm="12">
                                    <Form.Control
                                      type="text"
                                      placeholder="Email"
                                      name="email"
                                      isValid={touched.email && !errors.email}
                                      isInvalid={errors.email && touched.email}
                                      value={values.email}
                                      onChange={handleChange}
                                    />

                                    {errors.email && touched.email && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                      </Form.Control.Feedback>
                                    )}
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Col} sm="6" controlId="msisdn">
                                  <Form.Label column sm="12">
                                    Phone Number
                                  </Form.Label>

                                  <Col sm="12">
                                    <Form.Control
                                      type="text"
                                      placeholder="Phone Number"
                                      name="msisdn"
                                      isValid={touched.msisdn && !errors.msisdn}
                                      isInvalid={
                                        errors.msisdn && touched.msisdn
                                      }
                                      value={values.msisdn}
                                      onChange={handleChange}
                                    />

                                    {errors.msisdn && touched.msisdn && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.msisdn}
                                      </Form.Control.Feedback>
                                    )}
                                  </Col>
                                </Form.Group>
                              </Row>
                              <Row>
                                <Form.Group
                                  as={Col}
                                  sm="6"
                                  controlId="privilege"
                                >
                                  <Form.Label column sm="12">
                                    Privilege
                                  </Form.Label>

                                  <Col sm="12">
                                    <Form.Control
                                      as="select"
                                      isValid={
                                        touched.privilege && !errors.privilege
                                      }
                                      defaultValue={values.privilege}
                                      isInvalid={
                                        errors.privilege && touched.privilege
                                      }
                                      name="privilege"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled={priviledge === 1}
                                    >
                                      <option value="">Choose ...</option>

                                      <option
                                        value={0}
                                        selected={
                                          user.privilege !== undefined &&
                                          user.privilege === 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Non Admin
                                      </option>

                                      <option
                                        value={1}
                                        selected={
                                          user.privilege !== undefined &&
                                          user.privilege === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        Admin
                                      </option>
                                    </Form.Control>

                                    {errors.privilege && touched.privilege && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.privilege}
                                      </Form.Control.Feedback>
                                    )}
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Col} sm="6" controlId="status">
                                  <Form.Label column sm="12">
                                    Status
                                  </Form.Label>

                                  <Col sm="12">
                                    <Form.Control
                                      as="select"
                                      isValid={touched.status && !errors.status}
                                      defaultValue={values.status}
                                      isInvalid={
                                        errors.status && touched.status
                                      }
                                      name="status"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Choose ...</option>

                                      <option
                                        value={0}
                                        selected={
                                          user.status !== undefined &&
                                          user.status === 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Inactive
                                      </option>

                                      <option
                                        value={1}
                                        selected={
                                          user.status !== undefined &&
                                          user.status === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        Active
                                      </option>
                                    </Form.Control>

                                    {errors.status && touched.status && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.status}
                                      </Form.Control.Feedback>
                                    )}
                                  </Col>
                                </Form.Group>
                              </Row>

                              <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                                style={{ marginTop: "1em" }}
                              >
                                Edit User
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Card.Body>
                    </Card>
                  </Col>
                }
              </Row>
            </Container>
          </Col>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps, { editUser, showUser, clearUser })(
  withRouter(Create)
);
