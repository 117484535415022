import React, { useEffect, useState } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Row, Col, Container, Button, ListGroup } from "react-bootstrap";
import { withRouter, useParams } from "react-router-dom";
import { getCategories } from "./../../store/actions/CategoryActions";
import { searchMoq } from "./../../store/actions/MoqActions";
import { getSinglePromoCode } from "./../../store/actions/PromoCodeActions";
import BlockLoader from "./../contentloaders/BlockLoader";
import moment from "moment";

function Index(props) {
  // Props
  const { promo_codes } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Hooks
  const params = useParams();

  // Effects
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (params?.id) {
      fetchSinglePromoCode(params?.id);
    }
  }, [params]);

  // Functions
  const fetchCategories = async () => {
    try {
      await props.getCategories();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSinglePromoCode = async (id) => {
    try {
      setLoading(true);
      await props.getSinglePromoCode(id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
        <Container>
          <Row>
            <Col
              sm="12"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "1em",
              }}
            >
              <h3>Promo Code Details</h3>
              <Button
                variant="primary"
                size="sm"
                onClick={() => props.history.push(`/admin/promo-codes/${params?.id}/edit`)}
              >
                Edit Promo Code
              </Button>
            </Col>
            {loading && (
              <Col sm="12">
                <BlockLoader
                  width="100%"
                  height="400px"
                  innerWidth="100%"
                  innerHeight="100%"
                />
              </Col>
            )}
            {!loading && (
              <Col sm="12">
                <ListGroup>
                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>Name: </span>
                    <span>
                      {promo_codes?.promo_code?.promo_name
                        ? promo_codes?.promo_code?.promo_name
                        : "N/A"}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>Code: </span>
                    <span>
                      {promo_codes?.promo_code?.code
                        ? promo_codes?.promo_code?.code
                        : "N/A"}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>Discount (%): </span>{" "}
                    <span>
                      {promo_codes?.promo_code?.discount
                        ? promo_codes?.promo_code?.discount
                        : "N/A"}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>Categories: </span>
                    <span>
                      {promo_codes?.promo_code?.category_id_list
                        ?.map((category) => category?.name)
                        .join(", ") || "N/A"}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>Products: </span>
                    <span>
                      {promo_codes?.promo_code?.moq_list
                        ?.map((moq) => moq?.product_name)
                        .join(", ") || "N/A"}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>
                      Phone Number(s):{" "}
                    </span>
                    <span>
                      {promo_codes?.promo_code?.customer_msisdn_list
                        ?.map((phone_number) => phone_number)
                        .join(", ") || "N/A"}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <span style={{ fontWeight: "bold" }}>From: </span>
                    <span>
                      {moment
                        .utc(promo_codes?.promo_code?.end_date)
                        .utcOffset(0)
                        .format("Do MMM yyy, hh:mm A")}{" "}
                      To{" "}
                      {moment
                        .utc(promo_codes?.promo_code?.end_date)
                        .utcOffset(0)
                        .format("Do MMM yyy, hh:mm A")}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            )}
          </Row>
        </Container>
      </Col>
    </Layout>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories,
    moqs: state.moqs,
    promo_codes: state.promo_codes,
  };
};

export default connect(mapStateToProps, {
  getCategories,
  searchMoq,
  getSinglePromoCode,
})(withRouter(Index));
