import * as yup from 'yup';

export const createSchema = yup.object().shape({
  product_id: yup.object().required("Product is required"),

  quantity: yup.number().required("Supplier MOQ is required"),

  min_quantity: yup.number().required("MOQ Per Person is required"),

  supplier_price: yup.number(),

  price: yup.number(),

  start_date: yup.date().required(),

  end_date: yup.date().required(),

  supplier_currency: yup.string().required(),

  weight: yup.number(),
});

export const editSchema = yup.object().shape({

    product_id: yup.number().required('Product is required'),
    
    quantity: yup.number().required('Supplier MOQ is required'), 
    
    min_quantity: yup.number().required('MOQ Per Person is required'), 
    
    supplier_price: yup.number(), 

    price: yup.number(), 
    
    start_date: yup.date().required(), 
    
    end_date: yup.date().required(),

    supplier_currency: yup.string().required(),

    weight: yup.number()

});