import { combineReducers } from "redux";

// Listing Reducers
import authReducer from "./AuthReducer";
import userReducer from "./UserReducer";
import productReducer from "./ProductReducer";
import sizeReducer from "./SizeReducer";
import colorReducer from "./ColorReducer";
import orderReducer from "./OrderReducer";
import supplierReducer from "./SupplierReducer";
import bidReducer from "./BidReducer";
import moqReducer from "./MoqReducer";
import transactionReducer from "./TransactionReducer";
import categoryReducer from "./CategoryReducer";
import homePromotionReducer from "./HomePromotionReducer";
import categoryPromotionReducer from "./CategoryPromotionReducer";
import recommendationReducer from "./RecommendationReducer";
import StatisticsReducer from "./StatisticsReducer";
import PromoCodeReducer from "./PromoCodeReducer";

export default combineReducers({
  auth: authReducer,
  users: userReducer,
  products: productReducer,
  sizes: sizeReducer,
  colors: colorReducer,
  orders: orderReducer,
  suppliers: supplierReducer,
  bids: bidReducer,
  moqs: moqReducer,
  transactions: transactionReducer,
  categories: categoryReducer,
  home_promotions: homePromotionReducer,
  category_promotions: categoryPromotionReducer,
  recommendations: recommendationReducer,
  statistics: StatisticsReducer,
  promo_codes: PromoCodeReducer,
});
