import { CURRENT_USER } from './../types/index';

const initialState = {};

export default function(state = initialState, action) {

    switch(action.type) {

        case CURRENT_USER: 

            return action.payload;

        default:

            return state;

    }

};