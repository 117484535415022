import { instance, setAuthToken } from '../../helpers/instance';
import { BIDS, FETCH_MORE_BIDS, GET_BID, SEARCHED_BIDS } from '../types/index';

export const getBids = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('admin_bid');

        if(response.data !== "" && response.data.orders !== undefined && response.data.pages !== undefined) {

            let payload = {};

            payload.data = response.data.orders !== undefined ? response.data.orders : [];

            payload.pages = response.data.pages !== undefined ? response.data.pages : 1;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: BIDS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreBids = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`admin_bid?page=${next_page}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.orders !== undefined ? response.data.orders : [];

            payload.pages = response.data.pages !== undefined ? response.data.pages : 1;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_BIDS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoqBids = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`admin_moq_bid/${id}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.orders !== undefined ? response.data.orders : [];

            payload.pages = response.data.pages !== undefined ? response.data.pages : 1;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: BIDS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreMoqBids = (id, next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`admin_moq_bid/${id}?page=${next_page}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.orders !== undefined ? response.data.orders : [];

            payload.pages = response.data.pages !== undefined ? response.data.pages : 1;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_BIDS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const createBid = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post('admin_bid', data);

        // if(response.data !== "") {

        //     dispatch({ type: BIDS, payload: response.data });

        // }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editBid = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`admin_bid/${id}`, data);

        // if(response.data !== "") {

        //     dispatch({ type: BIDS, payload: response.data });

        // }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchBid = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/bids`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showBid = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`admin_bid/${id}`);

        if(response.data !== "" && response.data.length > 0) {

            // console.log(response.data[0]);

            dispatch({ type: GET_BID, payload: response.data[0] });

            return response.data[0];

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchedBids = (bids) => async dispatch => {

    try {

        let data = [];

        for(let i = 0; i < bids.length; i++) {

            setAuthToken(instance);

            const response = await instance.get(`admin_bid/${bids[i].id}`);

            if(response.data !== "" && response.data.length > 0) {

                data.push(response.data[0]);

            }

        }

        dispatch({ type: SEARCHED_BIDS, payload: data });

        return data;
        
    } catch (error) {

        throw(error);
        
    }

};

export const deleteBid = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`bid/${id}`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};