import { instance, setAuthToken } from '../../helpers/instance';
import { SIZES } from '../types/index';

export const getAllSizes = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('sizes?paginate=false');

        if(response.data !== "") {

            dispatch({ type: SIZES, payload: response.data.sizes });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getSizes = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('sizes');

        if(response.data !== "") {

            dispatch({ type: SIZES, payload: response.data.sizes });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const addSize = (data) => async dispatch => {

    try {

        data.status = 1;

        setAuthToken(instance);

        const response = await instance.post('sizes', data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editSize = (id, data) => async dispatch => {

    try {

        data.status = 1;

        setAuthToken(instance);

        const response = await instance.patch(`sizes/${id}`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};