import { instance, setAuthToken } from '../../helpers/instance';
import { CATEGORIES, GET_CATEGORY, FETCH_MORE_CATEGORIES } from '../types/index';

export const getCategories = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('categories');

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.categories;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: CATEGORIES, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreCategories = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`categories?page=${next_page}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.categories;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_CATEGORIES, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const addCategory = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post('categories', data);

        if(response.data !== "") {

            dispatch({ type: GET_CATEGORY, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showCategory = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`categories/${id}`);

        if(response.data !== "") {

            dispatch({ type: GET_CATEGORY, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editCategory = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`categories/${id}`, data);

        if(response.data !== "") {

            dispatch({ type: GET_CATEGORY, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchCategory = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/categories`, data);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};