import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  Form,
  FormControl,
  Tab,
  Tabs,
  Card,
} from "react-bootstrap";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Formik } from "formik";
import {
  getHomePromotions,
  createHomePromotion,
  showHomePromotion,
  updateHomePromotion,
} from "../../store/actions/HomePromotionActions";
import {
  getCategoryPromotions,
  createCategoryPromotion,
  showCategoryPromotion,
  updateCategoryPromotion,
} from "../../store/actions/CategoryPromotionActions";
import { getCategories } from "./../../store/actions/CategoryActions";
import { createSchema as createCategorySchema } from "../../helpers/validation/categoryPromotion";
import { createSchema } from "../../helpers/validation/homePromotion";
import { toast } from "react-toastify";
import { instance } from "../../helpers/instance";

function Index(props) {
  // Props
  const {
    getHomePromotions,
    createHomePromotion,
    home_promotions,
    showHomePromotion,
    updateHomePromotion,
    getCategoryPromotions,
    category_promotions,
    getCategories,
    categories,
    createCategoryPromotion,
    updateCategoryPromotion,
  } = props;

  // States
  const [showCreateHomePromoModal, setShowCreateHomePromoModal] =
    useState(false);
  const [showEditHomePromoModal, setShowEditHomePromoModal] = useState(false);
  const [showDeleteHomePromoModal, setShowDeleteHomePromoModal] =
    useState(false);
  const [selectedHomePromotion, setSelectedHomePromotion] = useState(null);
  const [selectedCategoryPromotion, setSelectedCategoryPromotion] =
    useState(null);
  const [showCreateCategoryPromoModal, setShowCreateCategoryPromoModal] =
    useState(false);
  const [showEditCategoryPromoModal, setShowEditCategoryPromoModal] =
    useState(false);
  const [showDeleteCategoryPromoModal, setShowDeleteCategoryPromoModal] =
    useState(false);

  // Effects
  useEffect(() => {
    async function fetchPromotions() {
      try {
        await getHomePromotions();
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchCategoryPromotions() {
      try {
        await getCategoryPromotions();
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchCategories() {
      try {
        await getCategories();
      } catch (error) {
        console.log(error);
      }
    }
    fetchPromotions();
    fetchCategoryPromotions();
    fetchCategories();
  }, [getCategories, getCategoryPromotions, getHomePromotions]);

  // Functions
  const toggleCreateHomePromoModal = () => {
    setShowCreateHomePromoModal(!showCreateHomePromoModal);
  };
  const toggleEditHomePromoModal = () => {
    setShowEditHomePromoModal(!showEditHomePromoModal);
  };
  const toggleDeleteHomePromoModal = () => {
    setShowDeleteHomePromoModal(!showDeleteHomePromoModal);
  };
  const toggleCreateCategoryPromoModal = () => {
    setShowCreateCategoryPromoModal(!showCreateCategoryPromoModal);
  };
  const toggleEditCategoryPromoModal = () => {
    setShowEditCategoryPromoModal(!showEditCategoryPromoModal);
  };
  const toggleDeleteCategoryPromoModal = () => {
    setShowDeleteCategoryPromoModal(!showDeleteCategoryPromoModal);
  };

  const { data: promotions_home } = home_promotions;
  const { data: promotions_category } = category_promotions;
  const { data: categories_data } = categories;
  return (
    <Layout>
      <Col sm="12" style={{ minHeight: "100vh" }}>
        <Container>
          <Row>
            <Col sm="12" style={{ marginTop: "3em" }}>
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Home Promotions">
                  <Row style={{ padding: "30px 0" }}>
                    <Col
                      sm="12"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "space-between",
                        alignItems: "center",
                        padding: "20px 10px",
                      }}
                    >
                      <Button
                        variant="success"
                        onClick={() => toggleCreateHomePromoModal()}
                      >
                        New Home Promotion
                      </Button>
                    </Col>
                    {promotions_home?.map((promo) => (
                      <Col key={promo?.id} sm="4">
                        <Card style={{ width: "100%", marginBottom: "1em" }}>
                          <Card.Img
                            variant="top"
                            src={promo?.image_url}
                            style={{
                              height: "150px",
                              maxHeight: "150px",
                              objectFit: "cover",
                            }}
                          />
                          <Card.Body>
                            <Card.Title>{promo?.title}</Card.Title>
                            <Card.Text>{promo?.description}</Card.Text>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setSelectedHomePromotion(promo?.id);
                                  toggleEditHomePromoModal();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  setSelectedHomePromotion(promo?.id);
                                  toggleDeleteHomePromoModal();
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Tab>
                <Tab eventKey="profile" title="Category Promotions">
                  <Row style={{ padding: "30px 0" }}>
                    <Col
                      sm="12"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "space-between",
                        alignItems: "center",
                        padding: "20px 10px",
                      }}
                    >
                      <Button
                        variant="success"
                        onClick={() => toggleCreateCategoryPromoModal()}
                      >
                        New Category Promotion
                      </Button>
                    </Col>
                    {promotions_category?.map((promo) => (
                      <Col key={promo?.id} sm="4">
                        <Card style={{ width: "100%", marginBottom: "1em" }}>
                          <Card.Img
                            variant="top"
                            src={promo?.image_url}
                            style={{
                              height: "150px",
                              maxHeight: "150px",
                              objectFit: "cover",
                            }}
                          />
                          <Card.Body>
                            <Card.Title>{promo?.title}</Card.Title>
                            <Card.Text>{promo?.description}</Card.Text>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setSelectedCategoryPromotion(promo?.id);
                                  toggleEditCategoryPromoModal();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  setSelectedCategoryPromotion(promo?.id);
                                  toggleDeleteCategoryPromoModal();
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Col>
      {/* Modals */}
      <CreateHomePromotionModal
        showCreateHomePromoModal={showCreateHomePromoModal}
        toggleCreateHomePromoModal={toggleCreateHomePromoModal}
        getHomePromotions={getHomePromotions}
        createHomePromotion={createHomePromotion}
      />
      <EditHomePromotionModal
        showEditHomePromoModal={showEditHomePromoModal}
        toggleEditHomePromoModal={toggleEditHomePromoModal}
        getHomePromotions={getHomePromotions}
        selectedHomePromotion={selectedHomePromotion}
        showHomePromotion={showHomePromotion}
        updateHomePromotion={updateHomePromotion}
        setSelectedHomePromotion={setSelectedHomePromotion}
      />
      <DeleteHomePromotionModal
        showDeleteHomePromoModal={showDeleteHomePromoModal}
        toggleDeleteHomePromoModal={toggleDeleteHomePromoModal}
        getHomePromotions={getHomePromotions}
        selectedHomePromotion={selectedHomePromotion}
        showHomePromotion={showHomePromotion}
        updateHomePromotion={updateHomePromotion}
        setSelectedHomePromotion={setSelectedHomePromotion}
      />
      <CreateCategoryPromotionModal
        showCreateCategoryPromoModal={showCreateCategoryPromoModal}
        toggleCreateCategoryPromoModal={toggleCreateCategoryPromoModal}
        getCategoryPromotions={getCategoryPromotions}
        createCategoryPromotion={createCategoryPromotion}
        categories={categories_data}
      />
      <EditCategoryPromotionModal
        showEditCategoryPromoModal={showEditCategoryPromoModal}
        toggleEditCategoryPromoModal={toggleEditCategoryPromoModal}
        getCategoryPromotions={getCategoryPromotions}
        updateCategoryPromotion={updateCategoryPromotion}
        categories={categories_data}
        promotions={promotions_category}
        selectedCategoryPromotion={selectedCategoryPromotion}
      />
      <DeleteCategoryPromotionModal
        showDeleteCategoryPromoModal={showDeleteCategoryPromoModal}
        toggleDeleteCategoryPromoModal={toggleDeleteCategoryPromoModal}
        getCategoryPromotions={getCategoryPromotions}
        updateCategoryPromotion={updateCategoryPromotion}
        categories={categories_data}
        promotions={promotions_category}
        selectedCategoryPromotion={selectedCategoryPromotion}
      />
    </Layout>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    home_promotions: state?.home_promotions,
    category_promotions: state?.category_promotions,
    categories: state?.categories,
  };
};

export default connect(mapStateToProps, {
  getHomePromotions,
  createHomePromotion,
  showHomePromotion,
  updateHomePromotion,
  getCategoryPromotions,
  getCategories,
  createCategoryPromotion,
  showCategoryPromotion,
  updateCategoryPromotion,
})(withRouter(Index));

function CreateHomePromotionModal(props) {
  // States
  const [loading, setLoading] = useState(false);

  // props
  const {
    showCreateHomePromoModal,
    toggleCreateHomePromoModal,
    getHomePromotions,
    createHomePromotion,
  } = props;

  // Functions
  const create = async (data) => {
    try {
      setLoading(true);
      const { title, description, file, item_url } = data;
      let obj = {};
      let formData = new FormData();
      formData.append("file", file);
      const response = await instance.post("upload", formData);
      if (response.data !== "") {
        obj.image_url = response.data.file;
      }
      obj.title = title;
      obj.item_url = item_url;
      obj.description = description;
      let result = await createHomePromotion(obj);
      if (result) {
        setLoading(false);
        toast.success(`Home Promotion Created Successfully !`, {
          position: "top-right",
        });
        await getHomePromotions();
        toggleCreateHomePromoModal();
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      } else {
        toast.error(`An error occurred. Please try again later !`, {
          position: "top-right",
        });
      }
    }
  };
  return (
    <Modal show={showCreateHomePromoModal} onHide={toggleCreateHomePromoModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create Home Promotion</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{ title: "", description: "", file: null, item_url: "" }}
        validationSchema={createSchema}
        validate={(values) => {
          const errors = {};
          if (!values.file) {
            errors.file = "Please select a file";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          create(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <Row style={{ padding: "0 18px" }}>
                <Form.Group controlId="title" style={{ width: "100%" }}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    isValid={touched.title && !errors.title}
                    isInvalid={errors.title && touched.title}
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  {errors.title && touched.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="description" style={{ width: "100%" }}>
                  <Form.Label>Description</Form.Label>
                  <FormControl
                    as="textarea"
                    aria-label="With textarea"
                    name="description"
                    isValid={touched.description && !errors.description}
                    isInvalid={errors.description && touched.description}
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="file" style={{ width: "100%" }}>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Image"
                    name="file"
                    isValid={touched.file && !errors.file}
                    isInvalid={errors.file && touched.file}
                    onChange={(e) => {
                      setFieldValue("file", e.target.files[0]);
                    }}
                  />
                  {errors.file && touched.file && (
                    <Form.Control.Feedback type="invalid">
                      {errors.file}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="item_url" style={{ width: "100%" }}>
                  <Form.Label>Item URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item URL"
                    name="item_url"
                    isValid={touched.item_url && !errors.item_url}
                    isInvalid={errors.item_url && touched.item_url}
                    value={values.item_url}
                    onChange={(e) => {
                      setFieldValue("item_url", e.target.value);
                    }}
                  />
                  {errors.item_url && touched.item_url && (
                    <Form.Control.Feedback type="invalid">
                      {errors.item_url}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={toggleCreateHomePromoModal}>
                Close
              </Button>
              <Button variant="success" type="submit" disabled={loading}>
                {loading ? "Wait..." : "Create"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function EditHomePromotionModal(props) {
  // States
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState(null);
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    file: null,
    item_url: "",
  });

  // props
  const {
    showEditHomePromoModal,
    toggleEditHomePromoModal,
    getHomePromotions,
    selectedHomePromotion,
    showHomePromotion,
    updateHomePromotion,
    setSelectedHomePromotion,
  } = props;

  // Effects
  useEffect(() => {
    async function fetchPromotion() {
      try {
        if (!selectedHomePromotion) return;
        let result = await showHomePromotion(selectedHomePromotion);
        if (result) {
          setEditData({
            title: result?.title ? result?.title : "",
            description: result?.description ? result?.description : "",
            file: null,
            item_url: result?.item_url ? result?.item_url : "",
          });
          setPromotion(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchPromotion();
    return () => {
      setPromotion(null);
    };
  }, [selectedHomePromotion, showHomePromotion]);

  // Functions
  const edit = async (data) => {
    try {
      setLoading(true);
      const { title, description, file, item_url } = data;
      let obj = {};
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        const response = await instance.post("upload", formData);
        if (response.data !== "") {
          obj.image_url = response.data.file;
        }
      } else {
        obj.image_url = promotion?.image_url;
      }
      obj.title = title;
      obj.item_url = item_url;
      obj.description = description;
      let result = await updateHomePromotion(selectedHomePromotion, obj);
      if (result) {
        setLoading(false);
        toast.success(`Home Promotion Updated Successfully !`, {
          position: "top-right",
        });
        await getHomePromotions();
        toggleEditHomePromoModal();
        setSelectedHomePromotion(null);
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      } else {
        toast.error(`An error occurred. Please try again later !`, {
          position: "top-right",
        });
      }
    }
  };
  return (
    <Modal show={showEditHomePromoModal} onHide={toggleEditHomePromoModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Home Promotion</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={editData}
        validationSchema={createSchema}
        validate={(values) => {
          const errors = {};
          // if (!values.file) {
          //   errors.file = 'Please select a file';
          // }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          edit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <Row style={{ padding: "0 18px" }}>
                <Form.Group controlId="title" style={{ width: "100%" }}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    isValid={touched.title && !errors.title}
                    isInvalid={errors.title && touched.title}
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  {errors.title && touched.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="description" style={{ width: "100%" }}>
                  <Form.Label>Description</Form.Label>
                  <FormControl
                    as="textarea"
                    aria-label="With textarea"
                    name="description"
                    isValid={touched.description && !errors.description}
                    isInvalid={errors.description && touched.description}
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="file" style={{ width: "100%" }}>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Image"
                    name="file"
                    isValid={touched.file && !errors.file}
                    isInvalid={errors.file && touched.file}
                    onChange={(e) => {
                      setFieldValue("file", e.target.files[0]);
                    }}
                  />
                  {errors.file && touched.file && (
                    <Form.Control.Feedback type="invalid">
                      {errors.file}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="item_url" style={{ width: "100%" }}>
                  <Form.Label>Item URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item URL"
                    name="item_url"
                    isValid={touched.item_url && !errors.item_url}
                    isInvalid={errors.item_url && touched.item_url}
                    value={values.item_url}
                    onChange={(e) => {
                      setFieldValue("item_url", e.target.value);
                    }}
                  />
                  {errors.item_url && touched.item_url && (
                    <Form.Control.Feedback type="invalid">
                      {errors.item_url}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={toggleEditHomePromoModal}>
                Close
              </Button>
              <Button variant="success" type="submit" disabled={loading}>
                {loading ? "Wait..." : "Update"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function DeleteHomePromotionModal(props) {
  // States
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState(null);
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    file: null,
    item_url: "",
  });

  // props
  const {
    showDeleteHomePromoModal,
    toggleDeleteHomePromoModal,
    getHomePromotions,
    selectedHomePromotion,
    showHomePromotion,
    updateHomePromotion,
    setSelectedHomePromotion,
  } = props;

  // Effects
  useEffect(() => {
    async function fetchPromotion() {
      try {
        if (!selectedHomePromotion) return;
        let result = await showHomePromotion(selectedHomePromotion);
        if (result) {
          setEditData({
            title: result?.title ? result?.title : "",
            description: result?.description ? result?.description : "",
            file: null,
            item_url: result?.item_url ? result?.item_url : "",
          });
          setPromotion(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchPromotion();
    return () => {
      setPromotion(null);
    };
  }, [selectedHomePromotion, showHomePromotion]);

  // Functions
  const edit = async (data) => {
    try {
      setLoading(true);
      const { title, description, file, item_url } = data;
      let obj = {};
      obj.title = title;
      obj.item_url = item_url;
      obj.description = description;
      obj.status = 0;
      let result = await updateHomePromotion(selectedHomePromotion, obj);
      if (result) {
        setLoading(false);
        toast.success(`Home Promotion Updated Successfully !`, {
          position: "top-right",
        });
        await getHomePromotions();
        toggleDeleteHomePromoModal();
        setSelectedHomePromotion(null);
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      } else {
        toast.error(`An error occurred. Please try again later !`, {
          position: "top-right",
        });
      }
    }
  };
  return (
    <Modal show={showDeleteHomePromoModal} onHide={toggleDeleteHomePromoModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Home Promotion</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={editData}
        validationSchema={createSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          edit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <Row style={{ padding: "0 18px" }}>
                <h4>Are you sure you want to delete {values?.title} ?</h4>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={toggleDeleteHomePromoModal}>
                Close
              </Button>
              <Button variant="danger" type="submit" disabled={loading}>
                {loading ? "Wait..." : "Delete"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function CreateCategoryPromotionModal(props) {
  // States
  const [loading, setLoading] = useState(false);

  // props
  const {
    showCreateCategoryPromoModal,
    toggleCreateCategoryPromoModal,
    getCategoryPromotions,
    createCategoryPromotion,
    categories,
  } = props;

  // Functions
  const create = async (data) => {
    try {
      setLoading(true);
      const { title, description, file, category_id, item_url } = data;
      let obj = {};
      let formData = new FormData();
      formData.append("file", file);
      const response = await instance.post("upload", formData);
      if (response.data !== "") {
        obj.image_url = response.data.file;
      }
      obj.category_id = category_id;
      obj.title = title;
      obj.item_url = item_url;
      obj.description = description;
      let result = await createCategoryPromotion(obj);
      if (result) {
        setLoading(false);
        toast.success(`Category Promotion Created Successfully !`, {
          position: "top-right",
        });
        await getCategoryPromotions();
        toggleCreateCategoryPromoModal();
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      } else {
        toast.error(`An error occurred. Please try again later !`, {
          position: "top-right",
        });
      }
    }
  };
  return (
    <Modal
      show={showCreateCategoryPromoModal}
      onHide={toggleCreateCategoryPromoModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Category Promotion</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          title: "",
          description: "",
          file: null,
          category_id: null,
          item_url: "",
        }}
        validationSchema={createCategorySchema}
        validate={(values) => {
          const errors = {};
          if (!values.file) {
            errors.file = "Please select a file";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          create(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <Row style={{ padding: "0 18px" }}>
                <Form.Group controlId="title" style={{ width: "100%" }}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    isValid={touched.title && !errors.title}
                    isInvalid={errors.title && touched.title}
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  {errors.title && touched.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="description" style={{ width: "100%" }}>
                  <Form.Label>Description</Form.Label>
                  <FormControl
                    as="textarea"
                    aria-label="With textarea"
                    name="description"
                    isValid={touched.description && !errors.description}
                    isInvalid={errors.description && touched.description}
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="file" style={{ width: "100%" }}>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Image"
                    name="file"
                    isValid={touched.file && !errors.file}
                    isInvalid={errors.file && touched.file}
                    onChange={(e) => {
                      setFieldValue("file", e.target.files[0]);
                    }}
                  />
                  {errors.file && touched.file && (
                    <Form.Control.Feedback type="invalid">
                      {errors.file}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="item_url" style={{ width: "100%" }}>
                  <Form.Label>Item URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item URL"
                    name="item_url"
                    isValid={touched.item_url && !errors.item_url}
                    isInvalid={errors.item_url && touched.item_url}
                    value={values.item_url}
                    onChange={(e) => {
                      setFieldValue("item_url", e.target.value);
                    }}
                  />
                  {errors.item_url && touched.item_url && (
                    <Form.Control.Feedback type="invalid">
                      {errors.item_url}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="category_id" style={{ width: "100%" }}>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    isValid={touched.category_id && !errors.category_id}
                    defaultValue={values.category_id}
                    isInvalid={errors.category_id && touched.category_id}
                    name="category_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Choose ...</option>
                    {categories.map((category, i) => (
                      <option key={i} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.category_id && touched.category_id && (
                    <Form.Control.Feedback type="invalid">
                      {errors.category_id}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={toggleCreateCategoryPromoModal}
              >
                Close
              </Button>
              <Button variant="success" type="submit" disabled={loading}>
                {loading ? "Wait..." : "Create"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function EditCategoryPromotionModal(props) {
  // States
  const [loading, setLoading] = useState(false);

  // props
  const {
    showEditCategoryPromoModal,
    toggleEditCategoryPromoModal,
    getCategoryPromotions,
    updateCategoryPromotion,
    categories,
    selectedCategoryPromotion,
    promotions,
  } = props;

  // Memo
  const promotion = useMemo(() => {
    let data =
      promotions?.find(
        (category) => category?.id === selectedCategoryPromotion
      ) || null;
    return data;
  }, [promotions, selectedCategoryPromotion]);

  // Functions
  const update = async (data) => {
    try {
      setLoading(true);
      const { title, description, file, category_id } = data;
      let obj = {};
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        const response = await instance.post("upload", formData);
        if (response.data !== "") {
          obj.image_url = response.data.file;
        }
      } else {
        obj.image_url = promotion?.image_url;
      }
      obj.category_id = category_id;
      obj.title = title;
      obj.description = description;
      let result = await updateCategoryPromotion(
        selectedCategoryPromotion,
        obj
      );
      if (result) {
        setLoading(false);
        toast.success(`Category Promotion Updated Successfully !`, {
          position: "top-right",
        });
        await getCategoryPromotions();
        toggleEditCategoryPromoModal();
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      } else {
        toast.error(`An error occurred. Please try again later !`, {
          position: "top-right",
        });
      }
    }
  };
  return (
    <Modal
      show={showEditCategoryPromoModal}
      onHide={toggleEditCategoryPromoModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Category Promotion</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          title: promotion && promotion?.title ? promotion?.title : "",
          description:
            promotion && promotion?.description ? promotion?.description : "",
          file: null,
          category_id:
            promotion && promotion?.category_id ? promotion?.category_id : null,
          item_url: promotion && promotion?.item_url ? promotion?.item_url : "",
        }}
        validationSchema={createCategorySchema}
        validate={(values) => {
          const errors = {};
          // if (!values.file) {
          //   errors.file = 'Please select a file';
          // }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          update(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <Row style={{ padding: "0 18px" }}>
                <Form.Group controlId="title" style={{ width: "100%" }}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    isValid={touched.title && !errors.title}
                    isInvalid={errors.title && touched.title}
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  {errors.title && touched.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="description" style={{ width: "100%" }}>
                  <Form.Label>Description</Form.Label>
                  <FormControl
                    as="textarea"
                    aria-label="With textarea"
                    name="description"
                    isValid={touched.description && !errors.description}
                    isInvalid={errors.description && touched.description}
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="file" style={{ width: "100%" }}>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Image"
                    name="file"
                    isValid={touched.file && !errors.file}
                    isInvalid={errors.file && touched.file}
                    onChange={(e) => {
                      setFieldValue("file", e.target.files[0]);
                    }}
                  />
                  {errors.file && touched.file && (
                    <Form.Control.Feedback type="invalid">
                      {errors.file}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="item_url" style={{ width: "100%" }}>
                  <Form.Label>Item URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item URL"
                    name="item_url"
                    isValid={touched.item_url && !errors.item_url}
                    isInvalid={errors.item_url && touched.item_url}
                    value={values.item_url}
                    onChange={(e) => {
                      setFieldValue("item_url", e.target.value);
                    }}
                  />
                  {errors.item_url && touched.item_url && (
                    <Form.Control.Feedback type="invalid">
                      {errors.item_url}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="category_id" style={{ width: "100%" }}>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    isValid={touched.category_id && !errors.category_id}
                    defaultValue={values.category_id}
                    isInvalid={errors.category_id && touched.category_id}
                    name="category_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    selectedvalue={values.category_id}
                  >
                    <option value="">Choose ...</option>
                    {categories.map((category, i) => (
                      <option key={i} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.category_id && touched.category_id && (
                    <Form.Control.Feedback type="invalid">
                      {errors.category_id}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={toggleEditCategoryPromoModal}
              >
                Close
              </Button>
              <Button variant="success" type="submit" disabled={loading}>
                {loading ? "Wait..." : "Update"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function DeleteCategoryPromotionModal(props) {
  // States
  const [loading, setLoading] = useState(false);

  // props
  const {
    showDeleteCategoryPromoModal,
    toggleDeleteCategoryPromoModal,
    getCategoryPromotions,
    updateCategoryPromotion,
    categories,
    selectedCategoryPromotion,
    promotions,
  } = props;

  // Memo
  const promotion = useMemo(() => {
    let data =
      promotions?.find(
        (category) => category?.id === selectedCategoryPromotion
      ) || null;
    return data;
  }, [promotions, selectedCategoryPromotion]);

  // Functions
  const update = async (data) => {
    try {
      setLoading(true);
      const { title, description, file, category_id } = data;
      let obj = {};
      obj.category_id = category_id;
      obj.title = title;
      obj.description = description;
      obj.status = 0;
      let result = await updateCategoryPromotion(
        selectedCategoryPromotion,
        obj
      );
      if (result) {
        setLoading(false);
        toast.success(`Category Promotion Updated Successfully !`, {
          position: "top-right",
        });
        await getCategoryPromotions();
        toggleDeleteCategoryPromoModal();
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      } else {
        toast.error(`An error occurred. Please try again later !`, {
          position: "top-right",
        });
      }
    }
  };
  return (
    <Modal
      show={showDeleteCategoryPromoModal}
      onHide={toggleDeleteCategoryPromoModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Category Promotion</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          title: promotion && promotion?.title ? promotion?.title : "",
          description:
            promotion && promotion?.description ? promotion?.description : "",
          file: null,
          category_id:
            promotion && promotion?.category_id ? promotion?.category_id : null,
          item_url: promotion && promotion?.item_url ? promotion?.item_url : "",
        }}
        validationSchema={createCategorySchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          update(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <Row style={{ padding: "0 18px" }}>
                <h4>Are you sure you want to delete {values?.title} ?</h4>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={toggleDeleteCategoryPromoModal}
              >
                Close
              </Button>
              <Button variant="danger" type="submit" disabled={loading}>
                {loading ? "Wait..." : "Delete"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
