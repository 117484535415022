import React, { useEffect, useState } from "react";
import Layout from "./../layouts/Index";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  getMoreRecommendations,
  getRecommendations,
  updateRecommendation,
} from "../../store/actions/RecommendationActions";
import BlockLoader from "./../contentloaders/BlockLoader";
import { Row, Col, Container, Table, Button } from "react-bootstrap";
import Switch from "react-switch";
import { instance, setAuthToken } from "../../helpers/instance";

function Index(props) {
  // Props
  const {
    getRecommendations,
    recommendations: { page, pages, data },
    getMoreRecommendations,
    updateRecommendation,
  } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    async function fetchRecommendations() {
      try {
        setLoading(true);
        await getRecommendations();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchRecommendations();
  }, []);

  // Functions
  const fetchMoreRecommendations = async () => {
    try {
      setLoading(true);
      const {
        recommendations: { next_page },
      } = props;
      const result = await getMoreRecommendations(next_page);
      if (result) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChange = async (id, checked) => {
    try {
      let status = checked === true ? 1 : 0;
      await updateRecommendation(id, { status });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
        <Container>
          <Row>
            <Col
              sm="12"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "1em",
              }}
            >
              {!loading && <h3>Recommendations</h3>}

              {loading && (
                <div style={{ width: "100px", height: "35px" }}>
                  <BlockLoader
                    width="150px"
                    height="35"
                    innerHeight="100%"
                    innerWidth="100%"
                    style={{ margin: "0", padding: "0" }}
                  />
                </div>
              )}
            </Col>
            <Col sm="12">
              {!loading && (
                <Table
                  striped
                  bordered
                  hover
                  size="lg"
                  variant="light"
                  responsive="sm"
                >
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Images</th>
                      <th>Created By</th>
                      <th>Date Created</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.map((r, i) => (
                      <tr key={i}>
                        <td>
                          <a
                            href={r.product_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {r.product_name}
                          </a>
                        </td>
                        <td>{r.description}</td>
                        <td>{r.quantity}</td>
                        <td>
                          {r.images.length > 0 && (
                            <div
                              style={{
                                postition: "relative",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                margin: "15px 0",
                              }}
                            >
                              {r.images.map((image, i) => (
                                <a key={i} href={image} target="_blank" rel="noreferrer">
                                  <img
                                    src={image}
                                    alt={image}
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                    }}
                                  />
                                </a>
                              ))}
                            </div>
                          )}
                        </td>
                        <td>
                          {r?.user?.first_name} {r?.user?.last_name},{" "}
                          {r?.user?.msisdn}
                        </td>
                        <td>
                          <Moment format="DD/MM/YYYY">{r.date_created}</Moment>
                        </td>
                        <td>{r?.status === 0 ? "not acted on" : "acted on"}</td>
                        <td>
                          <Switch
                            onChange={(checked) => handleChange(r?.id, checked)}
                            checked={r?.status === 1 ? true : false}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>

            <Col sm="12">
              {loading && (
                <BlockLoader
                  width="100%"
                  height="400px"
                  innerWidth="100%"
                  innerHeight="100%"
                />
              )}
            </Col>
            {page < pages && (
              <Col
                sm="12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2em",
                }}
              >
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => fetchMoreRecommendations()}
                  disabled={loading}
                >
                  {loading === true ? "Loading ..." : "Load More"}
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </Col>
    </Layout>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    recommendations: state.recommendations,
  };
};

export default connect(mapStateToProps, {
  getMoreRecommendations,
  getRecommendations,
  updateRecommendation,
})(Index);
