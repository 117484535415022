import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import Layout from "./../layouts/Index";
import BlockLoader from "./../contentloaders/BlockLoader";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Table,
  Form,
  Button,
  Modal,
  FormControl,
} from "react-bootstrap";
import {
  getCategories,
  getMoreCategories,
  addCategory,
  editCategory,
  searchCategory,
} from "./../../store/actions/CategoryActions";
import { getSuppliers } from "./../../store/actions/SupplierActions";
import { createSchema } from "./../../helpers/validation/category";
import { toast } from "react-toastify";
import { Formik } from "formik";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      editModal: false,

      moq_id: null,

      loading: false,

      category: {},

      search: "",
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    const res = await this.props.getCategories();

    if (res) {
      this.setState({ loading: false });
    }

    await this.props.getSuppliers();
  };

  toggleModal = (id = null) => {
    // console.log(id);

    this.setState({ showModal: !this.state.showModal, moq_id: id });
  };

  toggleEditModal = (category = null) => {
    this.setState({
      editModal: !this.state.editModal,
      category: category === null ? {} : category,
    });
  };

  createCategory = async (values) => {
    try {
      this.setState({ loading: true });

      const result = await this.props.addCategory(values);

      if (result) {
        toast.success("New Category Created Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, loading: false });

        return this.props.history.push("/admin/categories");
      }
    } catch (error) {
      this.setState({ loading: false });

      console.log(error);
    }
  };

  editCategory = async (values) => {
    try {
      this.setState({ loading: true });

      const { category } = this.state;

      const result = await this.props.editCategory(category.id, values);

      if (result) {
        toast.success("New Category Updated Successfully !", {
          position: "top-right",
        });

        this.setState({ editModal: false, loading: false });

        await this.props.getCategories();
      }
    } catch (error) {
      this.setState({ loading: false });

      console.log(error);
    }
  };

  fetchMoreCategories = async () => {
    try {
      this.setState({ loading: true });

      const {
        categories: { next_page },
      } = this.props;

      const result = await this.props.getMoreCategories(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchCategory = async () => {
    try {
      let obj = { name: this.state.search };

      this.setState({ loading: true });

      const result = await this.props.searchCategory(obj);

      if (result) {
        this.setState({ loading: false });

        toast.success("Category Found !", {
          position: "top-right",
        });

        return this.props.history.push(`/admin/categories/${result[0].id}`);
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("Category Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13) {
        await this.searchCategory();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  render() {
    const { loading, showModal, editModal, category, search } = this.state;

    const {
      categories,
      categories: { page, pages },
    } = this.props;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await this.searchCategory();
                  }}
                >
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Name"
                        value={search}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Button variant="outline-success" type="submit">
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Categories</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="100px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                <Button
                  variant="success"
                  size="sm"
                  onClick={() => this.toggleModal()}
                >
                  New Category Name
                </Button>
              </Col>

              <Col sm="12">
                {!loading && (
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>Product Name</th>

                        <th>Created At</th>

                        <th>Status</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {categories.data.map((category, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>{category.name}</td>

                          <td>
                            <Moment format="DD/MM/YYYY">
                              {category.date_created}
                            </Moment>
                          </td>

                          <td>
                            {category.status === 1 ? "Active" : "Inactive"}
                          </td>

                          <td>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => this.toggleEditModal(category)}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                  />
                )}
              </Col>

              {page < pages && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => this.fetchMoreCategories()}
                    disabled={loading}
                  >
                    {loading === true ? "Loading ..." : "Load More"}
                  </Button>
                </Col>
              )}

              {/* New Category Modal */}
              <Modal show={showModal} onHide={this.toggleModal}>
                <Modal.Header closeButton>
                  <Modal.Title>New Category</Modal.Title>
                </Modal.Header>

                <Formik
                  initialValues={{ name: "" }}
                  validationSchema={createSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    values.status = 1;

                    this.createCategory(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <div>
                      <Modal.Body>
                        <Form.Group as={Row} controlId="name">
                          <Form.Label column sm="3">
                            Category Name
                          </Form.Label>

                          <Col sm="9">
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              name="name"
                              isValid={touched.name && !errors.name}
                              isInvalid={errors.name && touched.name}
                              value={values.name}
                              onChange={handleChange}
                            />

                            {errors.name && touched.name && (
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            )}
                          </Col>
                        </Form.Group>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.toggleModal}>
                          Close
                        </Button>

                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          Create Category Name
                        </Button>
                      </Modal.Footer>
                    </div>
                  )}
                </Formik>
              </Modal>

              {/* Edit Color Modal */}
              <Modal show={editModal} onHide={this.toggleEditModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Category Name</Modal.Title>
                </Modal.Header>

                <Formik
                  initialValues={{
                    name:
                      category.name !== undefined || category.name !== null
                        ? category.name
                        : "",
                    status:
                      category.status !== undefined || category.status !== null
                        ? category.status
                        : 1,
                  }}
                  validationSchema={createSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    this.editCategory(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <div>
                      <Modal.Body>
                        <Form.Group as={Row} controlId="label">
                          <Form.Label column sm="3">
                            Category Name
                          </Form.Label>

                          <Col sm="9">
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              name="name"
                              isValid={touched.name && !errors.name}
                              isInvalid={errors.name && touched.name}
                              value={values.name}
                              onChange={handleChange}
                            />

                            {errors.name && touched.name && (
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            )}
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="status">
                          <Form.Label column sm="3">
                            Status
                          </Form.Label>

                          <Col sm="9">
                            <Form.Control
                              as="select"
                              isValid={touched.status && !errors.status}
                              defaultValue={values.status}
                              isInvalid={errors.status && touched.status}
                              name="status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Choose ...</option>

                              <option value={0}>Inactive</option>

                              <option value={1}>Active</option>
                            </Form.Control>

                            {errors.status && touched.status && (
                              <Form.Control.Feedback type="invalid">
                                {errors.status}
                              </Form.Control.Feedback>
                            )}
                          </Col>
                        </Form.Group>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={this.toggleEditModal}
                        >
                          Close
                        </Button>

                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          Edit Category Name
                        </Button>
                      </Modal.Footer>
                    </div>
                  )}
                </Formik>
              </Modal>
            </Row>
          </Container>
        </Col>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories,

    suppliers: state.suppliers,
  };
};

export default connect(mapStateToProps, {
  getCategories,
  addCategory,
  getSuppliers,
  getMoreCategories,
  editCategory,
  searchCategory,
})(withRouter(Index));
