import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import BlockLoader from "./../contentloaders/BlockLoader";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import {
  getSuppliers,
  addSupplier,
  editSupplier,
} from "./../../store/actions/SupplierActions";
import { getCategories } from "./../../store/actions/CategoryActions";
import { createSchema } from "./../../helpers/validation/supplier";
import { toast } from "react-toastify";
import { Formik } from "formik";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      editModal: false,

      supplier: {},

      loading: false,

      priviledge: 0,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const res = await this.props.getSuppliers();

    if (res) {
      this.setState({ loading: false });
    }

    await this.props.getCategories();
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleEditModal = (supplier = null) => {
    this.setState({
      editModal: !this.state.editModal,
      supplier: supplier === null ? {} : supplier,
    });
  };

  createSupplier = async (values) => {
    try {
      this.setState({ loading: true });

      const result = await this.props.addSupplier(values);

      if (result) {
        toast.success("New Supplier Details Created Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, loading: false });

        await this.props.getSuppliers();
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  };

  editSupplier = async (values) => {
    try {
      this.setState({ loading: true });

      const { supplier } = this.state;

      const result = await this.props.editSupplier(supplier.id, values);

      if (result) {
        toast.success("New Supplier Updated Successfully !", {
          position: "top-right",
        });

        this.setState({ editModal: false, loading: false });

        await this.props.getSuppliers();
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  };

  render() {
    const { supplier, loading, priviledge } = this.state;

    const { suppliers, categories } = this.props;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Suppliers</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="150px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                {priviledge === 2 && (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => this.toggleModal()}
                  >
                    New Supplier
                  </Button>
                )}
              </Col>

              <Col sm="12">
                {!loading && (
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>Name</th>

                        {priviledge === 2 && <th>Email</th>}

                        {priviledge === 2 && <th>Location</th>}

                        {priviledge === 2 && <th>Action</th>}
                      </tr>
                    </thead>

                    <tbody>
                      {suppliers.map((supplier, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>{supplier.name}</td>

                          {priviledge === 2 && <td>{supplier.email}</td>}

                          {priviledge === 2 && <td>{supplier.location}</td>}

                          {priviledge === 2 && (
                            <td>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => this.toggleEditModal(supplier)}
                              >
                                Edit
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Col>

        {/* New Supplier Modal */}
        <Modal show={this.state.showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Supplier</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={{ name: "", location: "", category: "" }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.createSupplier(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="name"
                        isValid={touched.name && !errors.name}
                        isInvalid={errors.name && touched.name}
                        value={values.name}
                        onChange={handleChange}
                      />

                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="email">
                    <Form.Label column sm="3">
                      Email
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        name="email"
                        isValid={touched.email && !errors.email}
                        isInvalid={errors.email && touched.email}
                        value={values.email}
                        onChange={handleChange}
                      />

                      {errors.email && touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="location">
                    <Form.Label column sm="3">
                      Location
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Location"
                        name="location"
                        isValid={touched.location && !errors.location}
                        isInvalid={errors.location && touched.location}
                        value={values.location}
                        onChange={handleChange}
                      />

                      {errors.location && touched.location && (
                        <Form.Control.Feedback type="invalid">
                          {errors.location}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="category">
                    <Form.Label column sm="3">
                      Category
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        as="select"
                        isValid={touched.category && !errors.category}
                        defaultValue={values.category}
                        isInvalid={errors.category && touched.category}
                        name="category"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose</option>

                        {categories.data.map((category, i) => (
                          <option key={i} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>

                      {/* <Form.Control type="text" placeholder="Category" name="category" isValid={touched.category && !errors.category} isInvalid={errors.category && touched.category} value={values.category} onChange={handleChange} /> */}

                      {errors.category && touched.category && (
                        <Form.Control.Feedback type="invalid">
                          {errors.category}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleModal}>
                    Close
                  </Button>

                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Create Supplier
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>

        {/* Edit Supplier Modal */}
        <Modal show={this.state.editModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Supplier</Modal.Title>
          </Modal.Header>

          <Formik
            enableReinitialize
            initialValues={{
              name: supplier.name !== undefined ? supplier.name : "",
              location:
                supplier.location !== undefined ? supplier.location : "",
              category:
                supplier.category !== undefined ? supplier.category : "",
              email: supplier.email !== undefined ? supplier.email : "",
            }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.editSupplier(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="name"
                        isValid={touched.name && !errors.name}
                        isInvalid={errors.name && touched.name}
                        value={values.name}
                        onChange={handleChange}
                      />

                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="email">
                    <Form.Label column sm="3">
                      Email
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        name="email"
                        isValid={touched.email && !errors.email}
                        isInvalid={errors.email && touched.email}
                        value={values.email}
                        onChange={handleChange}
                      />

                      {errors.email && touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="location">
                    <Form.Label column sm="3">
                      Location
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Location"
                        name="location"
                        isValid={touched.location && !errors.location}
                        isInvalid={errors.location && touched.location}
                        value={values.location}
                        onChange={handleChange}
                      />

                      {errors.location && touched.location && (
                        <Form.Control.Feedback type="invalid">
                          {errors.location}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="category">
                    <Form.Label column sm="3">
                      Category
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        as="select"
                        isValid={touched.category && !errors.category}
                        defaultValue={values.category}
                        isInvalid={errors.category && touched.category}
                        name="category"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose</option>

                        {categories.data.map((category, i) => (
                          <option
                            key={i}
                            value={category.id}
                            selected={
                              category.id === values.category ? true : false
                            }
                          >
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>

                      {/* <Form.Control type="text" placeholder="Category" name="category" isValid={touched.category && !errors.category} isInvalid={errors.category && touched.category} value={values.category} onChange={handleChange} /> */}

                      {errors.category && touched.category && (
                        <Form.Control.Feedback type="invalid">
                          {errors.category}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleEditModal}>
                    Close
                  </Button>

                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Edit Supplier
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    suppliers: state.suppliers,

    categories: state.categories,
  };
};

export default connect(mapStateToProps, {
  getSuppliers,
  addSupplier,
  editSupplier,
  getCategories,
})(Index);
