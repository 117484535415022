import React, { useEffect, useState } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import BlockLoader from "./../contentloaders/BlockLoader";
import {
  Row,
  Col,
  Container,
  Button,
  Table,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getPromoCodes } from "./../../store/actions/PromoCodeActions";
import moment from "moment";

function Index(props) {
  // Props
  const { promo_codes } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    fetchPromoCodes();
  }, []);

  // Functions
  const fetchPromoCodes = async () => {
    try {
      setLoading(true);
      await props.getPromoCodes();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
        <Container>
          <Row>
            <Col
              sm="12"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "1em",
              }}
            >
              {!loading && <h3>Promo Codes</h3>}
              {loading && (
                <div style={{ width: "100px", height: "35px" }}>
                  <BlockLoader
                    width="150px"
                    height="35"
                    innerHeight="100%"
                    innerWidth="100%"
                    style={{ margin: "0", padding: "0" }}
                  />
                </div>
              )}
              <Button
                variant="success"
                size="sm"
                onClick={() => props.history.push(`/admin/promo-codes/create`)}
              >
                Create
              </Button>
            </Col>
            <Col sm="12">
              {!loading && (
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  variant="light"
                  responsive="sm"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Discount</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {promo_codes?.data?.map((promo, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{promo.promo_name}</td>
                        <td>{promo.code}</td>
                        <td>{promo.discount}</td>
                        <td>
                          {moment
                            .utc(promo.start_date)
                            .utcOffset(0)
                            .format("Do MMM yyy, hh:mm A")}
                        </td>
                        <td>
                          {moment
                            .utc(promo.end_date)
                            .utcOffset(0)
                            .format("Do MMM yyy, hh:mm A")}
                        </td>
                        <td>{promo.status === 1 ? "Active" : "Inactive"}</td>
                        <td>
                          <Button
                          style={{marginRight: '1em'}}
                            variant="primary"
                            size="sm"
                            onClick={() =>
                              props.history.push(
                                `/admin/promo-codes/${promo?.id}/edit`
                              )
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() =>
                              props.history.push(
                                `/admin/promo-codes/${promo?.id}`
                              )
                            }
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>

            <Col sm="12">
              {loading && (
                <BlockLoader
                  width="100%"
                  height="400px"
                  innerWidth="100%"
                  innerHeight="100%"
                />
              )}
            </Col>
          </Row>
        </Container>
      </Col>
    </Layout>
  );
}

const mapStateToProps = (state, ownProps) => {
  return { promo_codes: state.promo_codes };
};

export default connect(mapStateToProps, { getPromoCodes })(withRouter(Index));
