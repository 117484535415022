import * as yup from 'yup';

export const createSchema = yup.object().shape({

    name: yup.string().required(),

    description: yup.string().required(),

    // source: yup.string().required()

    // colors: yup.array().min(3, 'Pick at least 3 tags').of(
    //     yup.object().shape({
    //       label: yup.string().required(),
    //       value: yup.string().required(),
    //     })
    // ).required(),

    // colors: yup.array().min(3, 'Pick at least 3 tags').required()

});