import React from "react";
import {
  ListGroup,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import { titleCase } from "./../../utils/index";

const ProductAccordionItem = ({ product, priviledge }) => {
  return (
    <div style={{ marginBottom: "5px" }}>
      <Collapsible
        trigger={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1em 2em",
              cursor: "pointer",
              backgroundColor: "#eee",
              borderRadius: "5px",
            }}
          >
            <div>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                {titleCase(product.name)}
              </h6>
            </div>

            <DropdownButton
              as={ButtonGroup}
              title="Action"
              id="bg-vertical-dropdown-1"
            >
              <Dropdown.Item
                eventKey="1"
                as={Link}
                to={`/admin/products/${product.id}`}
              >
                View Product
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="2"
                as={Link}
                to={`/admin/products/${product.id}/edit`}
              >
                Edit Product
              </Dropdown.Item>
            </DropdownButton>
          </div>
        }
      >
        <div style={{ padding: "30px", backgroundColor: "#f9f9f9" }}>
          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
            <span>
              <Link to={`/admin/products/${product.id}`}>
                {titleCase(product.name)}
              </Link>
            </span>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Description: </span> <br />
            <div
              style={{ marginTop: "10px" }}
              dangerouslySetInnerHTML={{
                __html: product.description ? product.description : "",
              }}
            />
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Image(s): </span>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                maxWidth: "auto",
              }}
            >
              {product.images.length > 0 &&
                product.images.map((image, i) => (
                  <div
                    key={i}
                    style={{
                      height: "60px",
                      width: "60px",
                      backgroundImage: `url("${image}")`,
                      backgroundSize: "contain",
                      margin: "0 3px",
                      border: "1px solid #000",
                    }}
                  ></div>
                ))}
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Colors: </span>{" "}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {product.readable_colors !== null &&
                product.readable_colors.map((color, i) => (
                  <div
                    key={i}
                    style={{
                      height: "40px",
                      width: "40px",
                      backgroundColor: color.value,
                      margin: "0 3px",
                      border: "1px solid #000",
                    }}
                  ></div>
                ))}
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Sizes: </span>{" "}
            {product.readable_sizes !== null &&
              product.readable_sizes.map((size, i) => (
                <span key={i}>{size.label}, </span>
              ))}
          </ListGroup.Item>

          {/* <ListGroup.Item>
                    <span style={{ fontWeight: 'bold' }}>Source: </span> <span>{product.source}</span>
                </ListGroup.Item> */}
        </div>
      </Collapsible>
    </div>
  );
};

export default ProductAccordionItem;
