import React from "react";
import { ListGroup, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";

function BidItem({
  bid,
  moq,
  toggleEditModal,
  toggleBidRemarksModal,
  toggleDeleteBidModal,
}) {
  return (
    <Card style={{ marginBottom: "1em" }}>
      {/* <Card.Header>Bids for <Link to={`/admin/products/${bid.product[0].id}`}>{bid.product[0].name}</Link> - MOQ <Link to={`/admin/moqs/${bid.moq_id}`}>{moq.moq_number}</Link></Card.Header> */}

      <Card.Body>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Bidder: </span>{" "}
            <span>
              {bid.user !== undefined
                ? `${bid.user[0].first_name} ${bid.user[0].last_name}`
                : "N/A"}
            </span>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Contact: </span>{" "}
            <span>{bid.user !== undefined ? bid.user[0].msisdn : "N/A"}</span>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Bid No: </span>{" "}
            <span>
              <Link to={`/admin/bids/${bid.id}`}>{bid.bid_number}</Link>
            </span>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ fontWeight: "bold" }}>Attributes: </span>

            <ul>
              {bid.bid_attributes.map((attribute, i) => {
                if (
                  attribute !== undefined ||
                  attribute.colors !== undefined ||
                  attribute.sizes !== undefined
                ) {
                  return (
                    <li key={i}>
                      {attribute.quantity}{" "}
                      {attribute.colors !== undefined
                        ? attribute.colors.label
                        : ""}{" "}
                      {attribute.sizes !== undefined
                        ? `size ${attribute.sizes.label}`
                        : ""}
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Quanity: </span>{" "}
            <span>{bid.quantity}</span>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Amount: </span>{" "}
            <span>{bid.amount}</span>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Payment Status: </span>

            {bid.status === 0 && (
              <span
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "3px 10px",
                  borderRadius: "5px",
                }}
              >
                Unpaid
              </span>
            )}

            {bid.status === 1 && (
              <span
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "3px 10px",
                  borderRadius: "5px",
                }}
              >
                Paid
              </span>
            )}
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>Total S/F: </span>{" "}
            <span>{bid.total_shipping === null ? 0 : bid.total_shipping}</span>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "bold" }}>S/F Status: </span>

            {bid.shipping_status === 0 && (
              <span
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "3px 10px",
                  borderRadius: "5px",
                }}
              >
                Unpaid
              </span>
            )}

            {bid.shipping_status === 1 && (
              <span
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "3px 10px",
                  borderRadius: "5px",
                }}
              >
                Paid
              </span>
            )}
          </ListGroup.Item>

          {
            <ListGroup.Item style={{ display: "inline-block" }}>
              <span style={{ fontWeight: "bold" }}>Delivery Status: </span>{" "}
              <span>
                {bid && bid?.delivery_status === 0
                  ? "Undelivered"
                  : "Delivered"}
              </span>
            </ListGroup.Item>
          }

          {bid && bid?.delivery_status === 1 && bid?.date_delivered && (
            <ListGroup.Item style={{ display: "inline-block" }}>
              <span style={{ fontWeight: "bold" }}>Delivery Date: </span>{" "}
              <span>
                {bid &&
                  bid?.date_delivered &&
                  moment(bid?.date_delivered).format("Do MMM YYYY")}
              </span>
            </ListGroup.Item>
          )}

          {!(bid?.delivery_status === 1 && bid?.date_delivered !== null) && (
            <ListGroup.Item style={{ display: "inline-block" }}>
              <span style={{ fontWeight: "bold" }}>Collection Status: </span>{" "}
              <span>
                {bid.is_collected === 0 ? "Not Collected" : "Collected"}
              </span>
            </ListGroup.Item>
          )}

          {!(bid?.delivery_status === 1 && bid?.date_delivered !== null) &&
            bid.date_collected !== null && (
              <ListGroup.Item style={{ display: "inline-block" }}>
                <span style={{ fontWeight: "bold" }}>Date Collected: </span>{" "}
                <span>
                  {bid.is_collected === 1 && bid.date_collected !== null ? (
                    <Moment format="DD/MM/YYYY">{bid.date_collected}</Moment>
                  ) : (
                    "N/A"
                  )}
                </span>
              </ListGroup.Item>
            )}
        </ListGroup>
      </Card.Body>

      <Card.Footer
        className="text-muted"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="primary"
          size="sm"
          onClick={() => toggleBidRemarksModal(bid)}
        >
          View Remarks
        </Button>

        <div>
          <Button
            variant="primary"
            size="sm"
            onClick={() => toggleEditModal(bid)}
            style={{ marginRight: "1em" }}
          >
            Edit Bid
          </Button>

          {moq.moq_order_status === "not placed" && (
            <Button
              variant="danger"
              size="sm"
              onClick={() => toggleDeleteBidModal(bid)}
            >
              Delete Bid
            </Button>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
}

export default BidItem;
