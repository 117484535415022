import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Card,
  FormControl,
} from "react-bootstrap";
import {
  getProduct,
  createProduct,
} from "./../../store/actions/ProductActions";
import { getAllSizes, addSize } from "./../../store/actions/SizeActions";
import { getAllColors } from "./../../store/actions/ColorActions";
import { createSchema } from "./../../helpers/validation/product";
import { instance, setAuthToken } from "./../../helpers/instance";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Dropzone from "react-dropzone";
import LoadingOverlay from "react-loading-overlay-ts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      colors: [],

      selectedColors: [],

      categories: [],

      files: [],

      images: [],

      sizes: [],

      previews: [],

      checked: false,

      charLimit: 30,
    };
  }

  componentDidMount = async () => {
    await this.props.getAllColors();

    // const { match: { params } } = this.props;

    this.setState({ loading: false });

    await this.getCategories();

    await this.getAllColors();

    await this.getAllSizes();

    // console.log(params);

    // const result = await this.props.getProduct(params.id);

    // if(result) {

    //     // console.log(result);

    //     this.getSelectedColors(result.colors);

    //     this.setState({ loading: false });

    // }

    this.setState({ loading: false });
  };

  getCategories = async () => {
    try {
      setAuthToken(instance);

      const response = await instance.get("categories");

      if (response.data !== "") {
        // console.log(response.data);

        this.setState({ categories: response.data.categories });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // getSelectedColors = (colors) => {

  //     const { selectedColors } = this.state;

  //     let existing = [];

  //     // console.log(colors);

  //     colors.map((color) => {

  //         // console.log(parseInt(color));

  //         let i = selectedColors.find((s) => { return s.value === parseInt(color); });

  //         if(i) {

  //             existing.push(i);

  //         }

  //         // console.log(i);

  //         return color;

  //     });

  //     this.setState({ colors: existing });

  //     // console.log(existing);

  // }

  getAllColors = async () => {
    try {
      const result = await this.props.getAllColors();

      if (result) {
        let colours = result.colors,
          selectedColors = [];

        for (let i = 0; i < colours.length; i++) {
          selectedColors.push({
            value: colours[i].id,

            label: colours[i].label,
          });
        }

        this.setState({ selectedColors });

        // console.log(selectedColors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getAllSizes = async () => {
    try {
      const result = await this.props.getAllSizes();

      if (result) {
        let sizes = result.sizes,
          selectedSizes = [];

        for (let i = 0; i < sizes.length; i++) {
          selectedSizes.push({
            value: sizes[i].id,

            label: sizes[i].label,
          });
        }

        this.setState({ sizes: selectedSizes });
      }
    } catch (error) {
      console.log(error);
    }
  };

  deleteImage = (file) => {
    const { previews, files } = this.state;

    let image = previews.find((img) => img.uri === file);

    let result = files.filter((img) => img !== image.file);

    let images = previews.filter((img) => {
      return img.uri !== file;
    });

    this.setState({ previews: images, files: result });
  };

  previewFiles = (files) => {
    let data = [];

    for (let i = 0; i < files.length; i++) {
      // console.log(files[i]);

      let uri = URL.createObjectURL(files[i]);

      // console.log(uri);

      data.push({
        uri,

        file: files[i],
      });

      //   this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    }

    this.setState({ previews: data });
  };

  create = async (data) => {
    try {
      const { files, checked } = this.state;

      this.setState({ loading: true });

      let images = [];

      // console.log(files.length);

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();

          formData.append("file", files[i]);

          const response = await instance.post("upload", formData);

          if (response.data !== "") {
            // console.log(response.data);

            images.push(response.data.file);
          }
        }

        data.images = images;
      } else {
        data.images = [];
      }

      let selectedColors = data.colors,
        colors = [];

      if (selectedColors.length > 0) {
        // console.log(selectedColors);

        selectedColors.forEach((c) => {
          // console.log(c);

          colors.push(c.value.toString());
        });

        data.colors = colors;
      } else {
        this.setState({ loading: false });

        return toast.error("Colors Is Required !", {
          position: "top-right",
        });
      }

      let selectedSizes = data.sizes,
        sizes = [];

      if (selectedSizes.length > 0) {
        // console.log(selectedSizes);

        selectedSizes.forEach(async (c) => {
          if (c.label === c.value) {
            const response = await this.props.addSize(c);

            sizes.push(response.id.toString());
          } else {
            sizes.push(c.value.toString());
          }
        });

        data.sizes = sizes;
      } else {
        this.setState({ loading: false });

        return toast.error("Sizes is required !", {
          position: "top-right",
        });
      }

      if (checked === true) {
        data.varying_sizes = 1;
      } else {
        data.varying_sizes = 0;
      }

      data.category_id = parseInt(data.category_id);

      // data.sizes = [];

      data.price = 0;

      // console.log(data);

      // console.log(data);

      const { history } = this.props;

      const result = await this.props.createProduct(data);

      if (result) {
        this.setState({ loading: false });

        toast.success("Product Created Successfully !", {
          position: "top-right",
        });

        return history.push(`/admin/products`);
      }
    } catch (error) {
      this.setState({ loading: false });

      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data !== undefined
      ) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      }

      console.log(error);
    }
  };

  render() {
    // const { products: { product } } = this.props;

    // console.log(product);

    const {
      loading,
      selectedColors,
      categories,
      sizes,
      previews,
      files,
      charLimit,
    } = this.state;

    return (
      <LoadingOverlay active={loading} spinner text="Please Wait ...">
        <Layout>
          <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
            <Container>
              <Row>
                {
                  <Col sm="12">
                    <h4>Create Product</h4>
                  </Col>
                }

                {
                  <Col sm="12">
                    <Card>
                      <Card.Body>
                        <Formik
                          initialValues={{
                            name: "",
                            description: "",
                            status: 1,
                            colors: [],
                            category_id: "",
                            sizes: [],
                          }}
                          validationSchema={createSchema}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            this.create(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched,
                          }) => (
                            <Form
                              noValidate
                              onSubmit={(e) => {
                                e.preventDefault();

                                // console.log('Form Submitted !');

                                handleSubmit();
                              }}
                            >
                              <Row>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Name</Form.Label>

                                    <Form.Control
                                      type="text"
                                      placeholder="Product Name"
                                      name="name"
                                      isValid={touched.name && !errors.name}
                                      isInvalid={errors.name && touched.name}
                                      value={values.name}
                                      onChange={(e) => {
                                        let txt = e.target.value;

                                        if (txt.length <= charLimit) {
                                          setFieldValue("name", txt);
                                        }
                                      }}
                                    />

                                    {errors.name && touched.name && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                      </Form.Control.Feedback>
                                    )}

                                    <span
                                      style={{
                                        paddingTop: "5px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {charLimit - values.name.length}{" "}
                                      characters remaining.
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="category_id"
                                  >
                                    <Form.Label>Category</Form.Label>

                                    <Form.Control
                                      as="select"
                                      isValid={
                                        touched.category_id &&
                                        !errors.category_id
                                      }
                                      defaultValue={values.category_id}
                                      isInvalid={
                                        errors.category_id &&
                                        touched.category_id
                                      }
                                      name="category_id"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Choose ...</option>

                                      {categories.map((category, i) => (
                                        <option key={i} value={category.id}>
                                          {category.name}
                                        </option>
                                      ))}
                                    </Form.Control>

                                    {errors.category_id &&
                                      touched.category_id && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.status}
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Form.Group
                                style={{ marginTop: "1em" }}
                                controlId="formBasicPassword"
                              >
                                <Form.Label>Description</Form.Label>

                                {/* <FormControl as="textarea" aria-label="With textarea" name="description" isValid={touched.description && !errors.description} isInvalid={errors.description && touched.description} value={values.description} onChange={handleChange} /> */}

                                <ReactQuill
                                  theme="snow"
                                  value={values.description}
                                  onChange={(data) =>
                                    setFieldValue("description", data)
                                  }
                                />

                                {errors.description && touched.description && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>

                              <Form.Group
                                style={{ marginTop: "1em" }}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Photo(s)</Form.Label>

                                {/* <Form.Control type="file" name="file" accept='image/*' multiple onChange={async (e) => { 
                                                            
                                                            // console.log(e.target.files);

                                                            this.previewFiles(e.target.files);

                                                            this.setState({ files: e.target.files });
                                                            
                                                        }} /> */}

                                <Dropzone
                                  accept="image/*"
                                  onDrop={(acceptedFiles) => {
                                    // console.log(acceptedFiles);

                                    let data = files.concat(acceptedFiles);

                                    this.previewFiles(data);

                                    this.setState({ files: data });
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        {...getRootProps()}
                                        style={{
                                          flex: 1,
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "20px",
                                          borderWidth: 2,
                                          borderRadius: 2,
                                          borderColor: "#eee",
                                          borderStyle: "dashed",
                                          backgroundColor: "#fafafa",
                                          color: "#bdbdbd",
                                          outline: "none",
                                          transition: "border .24s ease-in-out",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <input {...getInputProps()} />

                                        <p
                                          style={{
                                            textAlign: "center",
                                            marginTop: "1em",
                                          }}
                                        >
                                          Drag 'n' drop some files here, or
                                          click to select files
                                        </p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>

                                {previews.length > 0 && (
                                  <div
                                    style={{
                                      postition: "relative",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      margin: "15px 0",
                                    }}
                                  >
                                    {previews.map((image, i) => (
                                      <div
                                        key={i}
                                        style={{
                                          height: "80px",
                                          width: "80px",
                                          backgroundImage: `url("${image.uri}")`,
                                          backgroundSize: "cover",
                                          margin: "0 6px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          this.deleteImage(image.uri);
                                        }}
                                      >
                                        <Button
                                          variant="danger"
                                          type="button"
                                          size="sm"
                                          style={{
                                            postition: "absolute",
                                            right: "0",
                                            margin: "5px",
                                          }}
                                        >
                                          x
                                        </Button>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Form.Group>
                              <Row>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="colors"
                                  >
                                    <Form.Label>Colors</Form.Label>

                                    <Select
                                      defaultValue={values.colors}
                                      options={selectedColors}
                                      isMulti
                                      onChange={(value) => {
                                        setFieldValue("colors", value);
                                      }}
                                      onBlur={() => {
                                        setFieldTouched("colors", true);
                                      }}
                                      touched={touched.colors}
                                    />

                                    {/* {errors.colors && touched.colors && <Form.Control.Feedback type="invalid">{errors.colors}</Form.Control.Feedback>} */}
                                  </Form.Group>
                                </Col>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="sizes"
                                  >
                                    <Form.Label>Sizes</Form.Label>

                                    <CreatableSelect
                                      defaultValue={values.sizes}
                                      options={sizes}
                                      isMulti
                                      onChange={(value) => {
                                        setFieldValue("sizes", value);
                                      }}
                                      onBlur={() => {
                                        setFieldTouched("sizes", true);
                                      }}
                                      touched={touched.sizes}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Form.Group
                                style={{ marginTop: "1em" }}
                                controlId="status"
                              >
                                <Form.Label>Status</Form.Label>

                                <Form.Control
                                  as="select"
                                  defaultValue={values.status}
                                  isValid={touched.status && !errors.status}
                                  isInvalid={errors.status && touched.status}
                                  name="status"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value={1}>Activated</option>

                                  <option value={0}>Deactivated</option>
                                </Form.Control>

                                {errors.status && touched.status && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.status}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>

                              <Form.Group
                                style={{ marginTop: "1em" }}
                                controlId="varying_sizes"
                              >
                                <Form.Check
                                  checked={this.state.checked}
                                  onChange={(e) => {
                                    this.setState({
                                      checked: e.target.checked,
                                    });
                                  }}
                                  type="checkbox"
                                  label="Varying Sizes"
                                />
                              </Form.Group>

                              <Button
                                style={{ marginTop: "1em" }}
                                variant="primary"
                                type="submit"
                              >
                                Create Product
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Card.Body>
                    </Card>
                  </Col>
                }
              </Row>
            </Container>
          </Col>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products,
  };
};

export default connect(mapStateToProps, {
  getProduct,
  createProduct,
  getAllColors,
  getAllSizes,
  addSize,
})(withRouter(Create));
