import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import BlockLoader from "./../contentloaders/BlockLoader";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import {
  getColors,
  addColor,
  editColor,
} from "./../../store/actions/ColorActions";
import { titleCase } from "./../../utils/index";
import { createSchema } from "./../../helpers/validation/color";
import { toast } from "react-toastify";
import { Formik } from "formik";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      editModal: false,

      color: {},

      loading: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    const res = await this.props.getColors();

    if (res) {
      this.setState({ loading: false });
    }
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleEditModal = (color = null) => {
    this.setState({
      editModal: !this.state.editModal,
      color: color === null ? {} : color,
    });
  };

  createColor = async (values) => {
    try {
      this.setState({ loading: true });

      const result = await this.props.addColor(values);

      if (result) {
        toast.success("New Color Created Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, loading: false });

        await this.props.getColors();
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  };

  editColor = async (values) => {
    try {
      this.setState({ loading: true });

      const { color } = this.state;

      const result = await this.props.editColor(color.id, values);

      if (result) {
        toast.success("New Color Updated Successfully !", {
          position: "top-right",
        });

        this.setState({ editModal: false, loading: false });

        await this.props.getColors();
      }
    } catch (error) {
      this.setState({ loading: false });

      console.log(error);
    }
  };

  render() {
    const { showModal, editModal, color, loading } = this.state;

    const { colors } = this.props;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Colors</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="150px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                <Button
                  variant="success"
                  size="sm"
                  onClick={() => this.toggleModal()}
                >
                  New Color
                </Button>
              </Col>

              <Col sm="12">
                {!loading && (
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>Label</th>

                        <th>Color</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {colors.map((color, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>{titleCase(color.label)}</td>

                          <td>
                            <div
                              style={{
                                height: "30px",
                                width: "30px",
                                backgroundColor: color.value,
                              }}
                            ></div>
                          </td>

                          <td>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => this.toggleEditModal(color)}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Col>

        {/* New Color Modal */}
        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Color</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={{ label: "", value: "" }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.createColor(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="label">
                    <Form.Label column sm="3">
                      Label
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Label"
                        name="label"
                        isValid={touched.label && !errors.label}
                        isInvalid={errors.label && touched.label}
                        value={values.label}
                        onChange={handleChange}
                      />

                      {errors.label && touched.label && (
                        <Form.Control.Feedback type="invalid">
                          {errors.label}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="value">
                    <Form.Label column sm="3">
                      Value
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="color"
                        placeholder="Value"
                        name="value"
                        isValid={touched.value && !errors.value}
                        isInvalid={errors.value && touched.value}
                        value={values.value}
                        onChange={handleChange}
                      />

                      {errors.value && touched.value && (
                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleModal}>
                    Close
                  </Button>

                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Create Color
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>

        {/* Edit Color Modal */}
        <Modal show={editModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Color</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={{
              label:
                color.label !== undefined || color.label !== null
                  ? color.label
                  : "",
              value:
                color.value !== undefined || color.value !== null
                  ? color.value
                  : "",
            }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.editColor(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="label">
                    <Form.Label column sm="3">
                      Label
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Label"
                        name="label"
                        isValid={touched.label && !errors.label}
                        isInvalid={errors.label && touched.label}
                        value={values.label}
                        onChange={handleChange}
                      />

                      {errors.label && touched.label && (
                        <Form.Control.Feedback type="invalid">
                          {errors.label}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="value">
                    <Form.Label column sm="3">
                      Value
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="color"
                        placeholder="Value"
                        name="value"
                        isValid={touched.value && !errors.value}
                        isInvalid={errors.value && touched.value}
                        value={values.value}
                        onChange={handleChange}
                      />

                      {errors.value && touched.value && (
                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleEditModal}>
                    Close
                  </Button>

                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Edit Color
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    colors: state.colors,
  };
};

export default connect(mapStateToProps, { getColors, addColor, editColor })(
  Index
);
