import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import LoadingOverlay from "react-loading-overlay-ts";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import { editMoq, showMoq, clearMoq } from "./../../store/actions/MoqActions";
import {
  getAllProducts,
  getProduct,
  clearProduct,
} from "./../../store/actions/ProductActions";
import { editSchema } from "./../../helpers/validation/moq";
import CurrencyCodes from "currency-codes/data";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      varying_sizes: 0,

      moq_attributes: [
        {
          size_id: 0,
          supplier_price: "",
          price: "",
          shipping_fee_air: "",
          shipping_fee_sea: "",
          weight: "",
          below_moq_price: "",
        },
      ],

      sizes: [],

      shipping_fee_air: "",

      shipping_fee_sea: "",

      weight: "",
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    await this.props.getAllProducts();

    const {
      match: { params },
    } = this.props;

    const result = await this.props.showMoq(params.id);

    if (result) {
      await this.getProduct(result.product_id);

      if (result.moq_attributes.length > 0) {
        this.setState({ moq_attributes: result.moq_attributes });
      }

      if (
        result.product &&
        result.product.length === 1 &&
        result.product[0].varying_sizes === 0
      ) {
        if (result.moq_attributes.length > 0) {
          this.setState({
            shipping_fee_air: result.moq_attributes[0].shipping_fee_air,
            shipping_fee_sea: result.moq_attributes[0].shipping_fee_sea,
            weight: result.moq_attributes[0].weight,
            below_moq_price: result.moq_attributes[0]?.below_moq_price || 0,
          });
        }
      }

      this.setState({ loading: false });
    }
  };

  componentWillUnmount = async () => {
    await this.props.clearMoq();

    await this.props.clearProduct();
  };

  create = async (data) => {
    try {
      if (this.state.varying_sizes === 1) {
        delete data.shipping_fee_air;

        delete data.shipping_fee_sea;

        delete data.weight;

        let attributes = this.state.moq_attributes;

        let hasSupplierPrice = attributes.every(
          (attr) => attr?.supplier_price !== "" && attr?.supplier_price !== 0
        );
        if (!hasSupplierPrice) {
          return toast.error(
            "Supplier price(s) in MOQ attributes is required and should not have zero values !",
            {
              position: "top-right",
            }
          );
        }

        let hasPrice = attributes.every(
          (attr) => attr?.price !== "" && attr?.price !== 0
        );
        if (!hasPrice) {
          return toast.error(
            "Price(s) in MOQ attributes is required and should not have zero values !",
            {
              position: "top-right",
            }
          );
        }

        let hasBelowMOQPrice = attributes.every(
          (attr) => attr?.below_moq_price !== "" && attr?.below_moq_price !== 0
        );
        if (!hasBelowMOQPrice) {
          return toast.error(
            "Below MOQ Price(s) in MOQ attributes is required and should not have zero values !",
            {
              position: "top-right",
            }
          );
        }

        let hasWeight = attributes.every((attr) => attr?.weight !== "");
        if (!hasWeight) {
          return toast.error("Weight(s) in MOQ attributes is required !", {
            position: "top-right",
          });
        }

        let hasSize = attributes.every(
          (attr) => attr?.size_id !== "" && attr?.size_id !== 0
        );
        if (!hasSize) {
          return toast.error(
            "Please select appropriate size(s) in MOQ attributes !",
            {
              position: "top-right",
            }
          );
        }

        data.moq_attributes = this.state.moq_attributes;
      } else {
        if (data.supplier_price === "" || data.supplier_price === 0) {
          return toast.error(
            "Supplier Price is required and should not have zero value !",
            {
              position: "top-right",
            }
          );
        }

        if (data.price === "" || data.price === 0) {
          return toast.error("Price is required and must not have zero value !", {
            position: "top-right",
          });
        }

        if (data.weight === "" || data.weight === 0) {
          return toast.error("Weight is required !", {
            position: "top-right",
          });
        }

        if (data.below_moq_price === "") {
          return toast.error("Below MOQ Price is required !", {
            position: "top-right",
          });
        }

        data.moq_attributes = [
          {
            shipping_fee_air: data.shipping_fee_air,
            shipping_fee_sea: data.shipping_fee_sea,
            weight: data.weight,
          },
        ];

        delete data.shipping_fee_air;

        delete data.shipping_fee_sea;

        delete data.weight;
      }

      const {
        match: { params },
      } = this.props;

      this.setState({ loading: true });

      const result = await this.props.editMoq(params.id, data);

      if (result) {
        this.setState({ loading: false });

        toast.success("MOQ Edited Successfully !", {
          position: "top-right",
        });

        return this.props.history.push(`/admin/moqs`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getProduct = async (id) => {
    try {
      const result = await this.props.getProduct(id);

      this.setState({
        varying_sizes: result.varying_sizes,
        sizes: result.readable_sizes,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleInputChange = (e, index) => {
    const { moq_attributes } = this.state;

    const { name, value } = e.target;

    let attributes = [...moq_attributes];

    attributes[index][name] = parseInt(value);

    this.setState({ moq_attributes: attributes });
  };

  handleRemoveClick = (index) => {
    const { moq_attributes } = this.state;

    let list = [...moq_attributes];

    list.splice(index, 1);

    this.setState({ moq_attributes: list });
  };

  handleAddClick = () => {
    let { moq_attributes } = this.state;

    this.setState({
      moq_attributes: [
        ...moq_attributes,
        {
          size_id: 0,
          supplier_price: "",
          price: "",
          shipping_fee_air: "",
          shipping_fee_sea: "",
          weight: "",
          below_moq_price: "",
        },
      ],
    });
  };

  render() {
    const {
      loading,
      varying_sizes,
      moq_attributes,
      shipping_fee_air,
      shipping_fee_sea,
      weight,
    } = this.state;

    const {
      products,
      moqs: { moq },
    } = this.props;

    return (
      <LoadingOverlay active={loading} spinner text="Please Wait ...">
        <Layout>
          <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
            <Container>
              <Row>
                {!loading && (
                  <Col sm="12">
                    <h4>Edit Campaign</h4>
                  </Col>
                )}

                {
                  <Col sm="12">
                    <Card>
                      <Card.Body>
                        <Formik
                          enableReinitialize
                          initialValues={{
                            product_id:
                              moq.product_id !== undefined
                                ? moq.product_id
                                : "",
                            quantity:
                              moq.quantity !== undefined ? moq.quantity : "",
                            source: moq.source !== undefined ? moq.source : "",
                            min_quantity:
                              moq.min_quantity !== undefined
                                ? moq.min_quantity
                                : "",
                            supplier_price:
                              moq.supplier_price !== undefined
                                ? moq.supplier_price
                                : "",
                            price: moq.price !== undefined ? moq.price : 0,
                            start_date:
                              moq.start_date !== undefined
                                ? moq.start_date
                                : "",
                            end_date:
                              moq.end_date !== undefined ? moq.end_date : "",
                            status: moq.status !== undefined ? moq.status : 0,
                            moq_status: "",
                            shipping_fee_air:
                              shipping_fee_air !== "" ? shipping_fee_air : "",
                            shipping_fee_sea:
                              shipping_fee_sea !== "" ? shipping_fee_sea : "",
                            weight: weight !== "" ? weight : "",
                            supplier_currency:
                              moq.supplier_currency !== undefined
                                ? moq.supplier_currency
                                : "",
                            below_moq_price:
                              moq.below_moq_price !== undefined
                                ? moq.below_moq_price
                                : 0,
                          }}
                          validationSchema={editSchema}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            // console.log(values);

                            this.create(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <Form
                              noValidate
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            >
                              <Row>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="product_id"
                                  >
                                    <Form.Label>Product</Form.Label>

                                    <Form.Control
                                      as="select"
                                      disabled
                                      isValid={
                                        touched.product_id && !errors.product_id
                                      }
                                      defaultValue={values.product_id}
                                      isInvalid={
                                        errors.product_id && touched.product_id
                                      }
                                      name="product_id"
                                      onChange={(e) => {
                                        // console.log(e.target.value);

                                        this.getProduct(e.target.value);

                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Choose ...</option>

                                      {products.data.map((product, i) => (
                                        <option
                                          key={i}
                                          value={product.id}
                                          selected={
                                            product.id === values.product_id
                                              ? true
                                              : false
                                          }
                                        >
                                          {product.name}
                                        </option>
                                      ))}
                                    </Form.Control>

                                    {errors.product_id &&
                                      touched.product_id && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.product_id}
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="source"
                                  >
                                    <Form.Label>Source</Form.Label>

                                    <Form.Control
                                      type="text"
                                      placeholder="Source"
                                      name="source"
                                      isValid={touched.source && !errors.source}
                                      isInvalid={
                                        errors.source && touched.source
                                      }
                                      value={values.source}
                                      onChange={handleChange}
                                    />

                                    {errors.source && touched.source && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.source}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Quantity</Form.Label>

                                    <Form.Control
                                      type="number"
                                      placeholder="Quantity"
                                      name="quantity"
                                      isValid={
                                        touched.quantity && !errors.quantity
                                      }
                                      isInvalid={
                                        errors.quantity && touched.quantity
                                      }
                                      value={values.quantity}
                                      onChange={handleChange}
                                    />

                                    {errors.quantity && touched.quantity && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.quantity}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Minimum Quantity</Form.Label>

                                    <Form.Control
                                      type="number"
                                      placeholder="Minimun Quantity"
                                      name="min_quantity"
                                      isValid={
                                        touched.min_quantity &&
                                        !errors.min_quantity
                                      }
                                      isInvalid={
                                        errors.min_quantity &&
                                        touched.min_quantity
                                      }
                                      value={values.min_quantity}
                                      onChange={handleChange}
                                    />

                                    {errors.min_quantity &&
                                      touched.min_quantity && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.min_quantity}
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                {varying_sizes !== 1 && (
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Shipping Fee Air</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="Shipping Fee Air"
                                        name="shipping_fee_air"
                                        isValid={
                                          touched.shipping_fee_air &&
                                          !errors.shipping_fee_air
                                        }
                                        isInvalid={
                                          errors.shipping_fee_air &&
                                          touched.shipping_fee_air
                                        }
                                        value={values.shipping_fee_air}
                                        onChange={handleChange}
                                      />

                                      {errors.shipping_fee_air &&
                                        touched.shipping_fee_air && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.shipping_fee_air}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                )}

                                {varying_sizes !== 1 && (
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Shipping Fee Sea</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="Shipping Fee Sea"
                                        name="shipping_fee_sea"
                                        isValid={
                                          touched.shipping_fee_sea &&
                                          !errors.shipping_fee_sea
                                        }
                                        isInvalid={
                                          errors.shipping_fee_sea &&
                                          touched.shipping_fee_sea
                                        }
                                        value={values.shipping_fee_sea}
                                        onChange={handleChange}
                                      />

                                      {errors.shipping_fee_sea &&
                                        touched.shipping_fee_sea && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.shipping_fee_sea}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                )}
                              </Row>

                              {varying_sizes === 1 && (
                                <Form.Group
                                  style={{ marginTop: "1em" }}
                                  as={Row}
                                  controlId="moq_attributes"
                                >
                                  <Form.Label column sm="12">
                                    MOQ Attributes
                                  </Form.Label>

                                  <Col sm="12">
                                    {moq_attributes.map((x, i) => {
                                      return (
                                        <Row
                                          key={i}
                                          style={{
                                            borderBottom: "1px solid #eee",
                                            paddingBottom: "1em",
                                          }}
                                        >
                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              as="select"
                                              name="size_id"
                                              value={x.size_id}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            >
                                              <option value="">
                                                Sizes ...
                                              </option>

                                              {this.state.sizes !== null &&
                                                this.state.sizes.map(
                                                  (size, i) => (
                                                    <option
                                                      key={i}
                                                      value={size.id}
                                                    >
                                                      {size.label}
                                                    </option>
                                                  )
                                                )}
                                            </Form.Control>
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Supplier Price"
                                              name="supplier_price"
                                              value={x.supplier_price}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            />
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Price"
                                              name="price"
                                              value={x.price}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            />
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Below MOQ Price"
                                              name="below_moq_price"
                                              value={x.below_moq_price}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            />
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Weight (in grams)"
                                              name="weight"
                                              value={x.weight}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            />
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Approximate Shipping Fee Air"
                                              name="shipping_fee_air"
                                              value={x.shipping_fee_air}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            />
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Approximate Shipping Fee Sea"
                                              name="shipping_fee_sea"
                                              value={x.shipping_fee_sea}
                                              onChange={(e) =>
                                                this.handleInputChange(e, i)
                                              }
                                              disabled={loading}
                                            />
                                          </Col>

                                          <Col
                                            sm="3"
                                            style={{ marginTop: "1em" }}
                                          >
                                            {moq_attributes.length - 1 ===
                                              i && (
                                              <Button
                                                variant="success"
                                                style={{ marginRight: "10px" }}
                                                onClick={this.handleAddClick}
                                              >
                                                <FiPlus />
                                              </Button>
                                            )}

                                            {moq_attributes.length !== 1 && (
                                              <Button
                                                variant="danger"
                                                onClick={() =>
                                                  this.handleRemoveClick(i)
                                                }
                                              >
                                                <FiTrash2 />
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                  </Col>
                                </Form.Group>
                              )}

                              <Row>
                                {varying_sizes === 0 && (
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Supplier Price</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="Supplier Price"
                                        name="supplier_price"
                                        isValid={
                                          touched.supplier_price &&
                                          !errors.supplier_price
                                        }
                                        isInvalid={
                                          errors.supplier_price &&
                                          touched.supplier_price
                                        }
                                        value={values.supplier_price}
                                        onChange={handleChange}
                                      />

                                      {errors.supplier_price &&
                                        touched.supplier_price && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.supplier_price}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                )}

                                {varying_sizes === 0 && (
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="supplier_currency"
                                    >
                                      <Form.Label>Supplier Currency</Form.Label>

                                      <Form.Control
                                        as="select"
                                        isValid={
                                          touched.supplier_currency &&
                                          !errors.supplier_currency
                                        }
                                        isInvalid={
                                          errors.supplier_currency &&
                                          touched.supplier_currency
                                        }
                                        name="supplier_currency"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={values.supplier_currency}
                                      >
                                        <option value="">Choose ...</option>

                                        {CurrencyCodes.map((currency, i) => (
                                          <option key={i} value={currency.code}>
                                            {currency.code} -{" "}
                                            {currency.currency}
                                          </option>
                                        ))}
                                      </Form.Control>

                                      {errors.supplier_currency &&
                                        touched.supplier_currency && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.supplier_currency}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                {varying_sizes === 0 && (
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Price</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="Price"
                                        name="price"
                                        isValid={touched.price && !errors.price}
                                        isInvalid={
                                          errors.price && touched.price
                                        }
                                        value={values.price}
                                        onChange={handleChange}
                                      />

                                      {errors.price && touched.price && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.price}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>
                                )}

                                {varying_sizes === 0 && (
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="price"
                                    >
                                      <Form.Label>Below MOQ Price</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="Price"
                                        name="below_moq_price"
                                        isValid={
                                          touched.below_moq_price &&
                                          !errors.below_moq_price
                                        }
                                        isInvalid={
                                          errors.below_moq_price &&
                                          touched.below_moq_price
                                        }
                                        value={values.below_moq_price}
                                        onChange={handleChange}
                                      />

                                      {errors.below_moq_price &&
                                        touched.below_moq_price && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.below_moq_price}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Start Date</Form.Label>

                                    <Form.Control
                                      type="date"
                                      placeholder="Start Date"
                                      name="start_date"
                                      isValid={
                                        touched.start_date && !errors.start_date
                                      }
                                      isInvalid={
                                        errors.start_date && touched.start_date
                                      }
                                      value={values.start_date}
                                      onChange={handleChange}
                                    />

                                    {errors.start_date &&
                                      touched.start_date && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.start_date}
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>End Date</Form.Label>

                                    <Form.Control
                                      type="date"
                                      placeholder="End Date"
                                      name="end_date"
                                      isValid={
                                        touched.end_date && !errors.end_date
                                      }
                                      isInvalid={
                                        errors.end_date && touched.end_date
                                      }
                                      value={values.end_date}
                                      onChange={handleChange}
                                    />

                                    {errors.end_date && touched.end_date && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.end_date}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>

                              {varying_sizes !== 1 && (
                                <Form.Group
                                  style={{ marginTop: "1em" }}
                                  controlId=""
                                >
                                  <Form.Label>Weight (gms)</Form.Label>

                                  <Form.Control
                                    type="number"
                                    placeholder="Weight"
                                    name="weight"
                                    isValid={touched.weight && !errors.weight}
                                    isInvalid={errors.weight && touched.weight}
                                    value={values.weight}
                                    onChange={handleChange}
                                  />

                                  {errors.weight && touched.weight && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.weight}
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Group>
                              )}
                              <Row>
                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="status"
                                  >
                                    <Form.Label>Status</Form.Label>

                                    <Form.Control
                                      as="select"
                                      isValid={touched.status && !errors.status}
                                      defaultValue={values.status}
                                      isInvalid={
                                        errors.status && touched.status
                                      }
                                      name="status"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option
                                        value={1}
                                        selected={
                                          1 === values.status ? true : false
                                        }
                                      >
                                        Activated
                                      </option>

                                      <option
                                        value={0}
                                        selected={
                                          0 === values.status ? true : false
                                        }
                                      >
                                        Deactivated
                                      </option>
                                    </Form.Control>

                                    {errors.status && touched.status && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.status}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col sm="6">
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId="status"
                                  >
                                    <Form.Label>MOQ Status</Form.Label>

                                    <Form.Control
                                      as="select"
                                      isValid={
                                        touched.moq_status && !errors.moq_status
                                      }
                                      defaultValue={values.moq_status}
                                      isInvalid={
                                        errors.moq_status && touched.moq_status
                                      }
                                      name="moq_status"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="">Choose</option>

                                      <option value="placed">Placed</option>

                                      <option value="cancelled">
                                        Cancelled
                                      </option>
                                    </Form.Control>

                                    {errors.moq_status &&
                                      touched.moq_status && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.moq_status}
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                                style={{ marginTop: "12px" }}
                              >
                                Edit MOQ
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Card.Body>
                    </Card>
                  </Col>
                }
              </Row>
            </Container>
          </Col>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    moqs: state.moqs,

    products: state.products,
  };
};

export default connect(mapStateToProps, {
  editMoq,
  getAllProducts,
  showMoq,
  getProduct,
  clearMoq,
  clearProduct,
})(withRouter(Edit));
