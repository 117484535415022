import * as yup from 'yup';

export const createSchema = yup.object().shape({

    first_name: yup.string().required(),

    last_name: yup.string(),

    username: yup.string().required(),

    msisdn: yup.string().required(),

    email: yup.string().email().required(),

    privilege: yup.number().required(),

    status: yup.number().required(),

    password: yup.string().required(),

    confirm_password: yup.string().required()

});

export const editSchema = yup.object().shape({

    first_name: yup.string().required(),

    last_name: yup.string(),

    username: yup.string().nullable(),

    msisdn: yup.string().required(),

    email: yup.string().email().required(),

    privilege: yup.number().required(),

    status: yup.number().required()

});