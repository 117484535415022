import { COLORS } from '../types/index';

const initialState = [];

export default function(state = initialState, action) {

    switch(action.type) {

        case COLORS: 

            return action.payload;

        default:

            return state;

    }

};