import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { instance } from "./../helpers/instance";
import { connect } from "react-redux";
import { signin } from "./../store/actions/AuthActions";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Button,
  Form,
  Carousel,
  Tab,
  Tabs,
  Container,
} from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import AlertComponent from "./common/Alert";
import Layout from "./layouts/Index";
import { FiPlus } from "react-icons/fi";

const TabHeading = (props) => (
  <div id="tab-heading">
    <h6>{props.title}</h6>{" "}
    <p>
      <FiPlus />
    </p>
  </div>
);

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      phone_number: "",

      activeStep: 0,

      validation_code: "",

      email: "",

      showAlert: false,

      variant: "success",

      message: "",

      hideform: false,
    };
  }

  componentDidMount = async () => {};

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async () => {
    const { phone_number } = this.state;

    try {
      this.setState({ loading: true });

      const response = await instance.get(`users/search/${phone_number}`);

      const data = response.data;

      if (data.name !== undefined) {
        // toast.success(`Hello ${data.name}, your points are ${data.points}`, {

        //     position: "top-right",

        //     autoClose: 9000

        // });

        // this.setState({ phone_number: '' });

        this.setState({
          showAlert: true,
          variant: "success",
          message: `Hello ${data.name}, we have sent a verification code to your phone.`,
          activeStep: 1,
          email: data.email !== null ? data.email : "",
        });
      } else if (data.message !== undefined && data.message === "valid user") {
        this.setState({ activeStep: 1 });

        toast.warning(
          "Validation Code Has Been Sent On Your Phone. Fill in the code and email address to check points",
          {
            position: "top-right",
          }
        );
      } else {
        // toast.warning('Phone Number Not Registered !', {

        //     position: "top-right"

        // });

        this.setState({
          showAlert: true,
          variant: "danger",
          message: `Thank you for visiting our LituPoints loyalty points platform. Join the Litudian Importing Club in link below and make your purchases to increase your LituPoints. <a href="https://chat.whatsapp.com/6txgLpvTMt8B0sQ8qoSWrr" target="_blank">Join Litudian</a>`,
        });
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });

      console.log(error);
    }
  };

  onValidateUser = async () => {
    const { phone_number, validation_code, email } = this.state;

    try {
      this.setState({ loading: true });

      const response = await instance.post(`users/points`, {
        msisdn: phone_number,

        validation_code,

        email,
      });

      const data = response.data;

      if (data.name !== undefined) {
        // toast.success(`Hello ${data.name}, your points are ${data.points}`, {

        //     position: "top-right",

        //     autoClose: 9000

        // });

        this.setState({
          showAlert: true,
          variant: "success",
          message: `Hello ${data.name}, your points are ${data.points}. Boost your LituPoints by placing more orders with Litudian. Running orders can be viewed <a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODUxNzI4NDM0MzA5ODI3?igshid=nfx9ly34msm7" target="_blank">here</a>`,
          activeStep: 1,
          email: data.email !== null ? data.email : "",
          hideform: true,
        });

        this.setState({ phone_number: "", validation_code: "", email: "" });
      } else {
        // toast.warning('Validation Failed !', {

        //     position: "top-right"

        // });

        this.setState({ phone_number: "", validation_code: "", email: "" });
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });

      if (error.response.data.message !== undefined) {
        // console.log(error.response.data.message);

        // toast.warning(error.response.data.message, {

        //     position: "top-right"

        // });

        this.setState({
          showAlert: true,
          variant: "warning",
          message: error.response.data.message,
        });
      }

      console.log(error);
    }
  };

  toggleAlert = () => {
    this.setState({ showAlert: !this.state.showAlert, hideform: false });
  };

  render() {
    const {
      phone_number,
      loading,
      activeStep,
      email,
      validation_code,
      showAlert,
      variant,
      message,
      hideform,
    } = this.state;

    return (
      <Layout>
        <Row>
          <Col sm="12" className="landing-section">
            <Carousel>
              <Carousel.Item className="carousel-item">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  ></div>
                  <img
                    className="carousel-item-img d-block w-100"
                    src={require("./common/img/1.jpg")}
                    alt="First slide"
                  />
                </div>
                <Carousel.Caption>
                  <h3>Customer profitablity is our satisfaction</h3>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item className="carousel-item">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  ></div>
                  <img
                    className="carousel-item-img d-block w-100"
                    src={require("./common/img/2.jpg")}
                    alt="Third slide"
                  />
                </div>
                <Carousel.Caption>
                  <h3>Customer profitablity is our satisfaction</h3>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item className="carousel-item">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  ></div>
                  <img
                    className="carousel-item-img d-block w-100"
                    src={require("./common/img/3.jpg")}
                    alt="Third slide"
                  />
                </div>
                <Carousel.Caption>
                  <h3>Customer profitablity is our satisfaction</h3>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item className="carousel-item">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  ></div>
                  <img
                    className="carousel-item-img d-block w-100"
                    src={require("./common/img/4.jpg")}
                    alt="Third slide"
                  />
                </div>
                <Carousel.Caption>
                  <h3>Customer profitablity is our satisfaction</h3>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item className="carousel-item">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  ></div>
                  <img
                    className="carousel-item-img d-block w-100"
                    src={require("./common/img/5.jpg")}
                    alt="Third slide"
                  />
                </div>
                <Carousel.Caption>
                  <h3>Customer profitablity is our satisfaction</h3>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item className="carousel-item">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      zIndex: 2,
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    }}
                  ></div>
                  <img
                    className="carousel-item-img d-block w-100"
                    src={require("./common/img/6.jpg")}
                    alt="Third slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>

          <Col>
            <Container
              style={{
                minHeight: "600px",
                padding: "4em 2em",
              }}
            >
              <Tabs defaultActiveKey="sourcing" id="uncontrolled-tab-example">
                <Tab
                  eventKey="sourcing"
                  title={<TabHeading title="Sourcing" />}
                >
                  <Row style={{ padding: "3em 0" }}>
                    <Col sm="3">
                      <img
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "contain",
                          marginBottom: "2em",
                        }}
                        src={require("./layouts/img/1-min.jpg")}
                        alt="litudian"
                      />
                    </Col>

                    <Col sm="9">
                      <p>
                        To take advantage of low price that is attached to high
                        quantity purchase, we offer a platform where:
                      </p>

                      <ol>
                        <li>
                          Importers who want low quantity & price can be able to
                          group their resources to achieve 'high' supplier MOQ -
                          Minimum Order Quantity. (JAZA MOQ)
                        </li>

                        <li>
                          Importers who need to import high value or high
                          quantity items can transact. (SELF IMPORT)
                        </li>
                      </ol>

                      <p>
                        <a
                          href="https://www.instagram.com/stories/highlights/17851728434309827/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click HERE
                        </a>{" "}
                        to see the current running orders.
                      </p>

                      <p>
                        <a
                          href="https://www.instagram.com/stories/highlights/17903684236464311/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click HERE
                        </a>{" "}
                        to see past imported items.
                      </p>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="retail" title={<TabHeading title="Retail" />}>
                  <Row style={{ padding: "3em 0" }}>
                    <Col sm="3">
                      <img
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "contain",
                          marginBottom: "2em",
                        }}
                        src={require("./layouts/img/2-min.jpg")}
                        alt="litudian"
                      />
                    </Col>

                    <Col sm="9">
                      <p>
                        We have surplus items from our import activities that we
                        auction as retail items. We offer these to both
                        retailers and end users.
                      </p>

                      <p>
                        <a
                          href="https://www.instagram.com/stories/highlights/17867643859778158/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click HERE
                        </a>{" "}
                        to see what we have in stock.
                      </p>
                    </Col>
                  </Row>
                </Tab>

                <Tab
                  eventKey="ikea_imports"
                  title={<TabHeading title="IKEA Imports" />}
                >
                  <Row style={{ padding: "3em 0" }}>
                    <Col sm="3">
                      <img
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "contain",
                          marginBottom: "2em",
                        }}
                        src={require("./layouts/img/3-min.jpg")}
                        alt="litudian"
                      />
                    </Col>

                    <Col sm="9">
                      <p>
                        Litudian is your personal shopper and importer for IKEA
                        products from various IKEA stores in the world for you.
                        IKEA is a Swedish conglomerate that designs and sells
                        ready-to-assemble furniture, kitchen appliances and home
                        accessories, among other useful goods and occasionally
                        home services. This will ensure we add another quality
                        supplier for home and office items at cost effective
                        prices.
                      </p>

                      <p>
                        <a
                          href="https://www.instagram.com/ikea.kenya/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click HERE
                        </a>{" "}
                        to see what we have in stock.
                      </p>
                    </Col>
                  </Row>
                </Tab>

                <Tab
                  eventKey="waya_footwear"
                  title={<TabHeading title="Wayo Footwear" />}
                >
                  <Row style={{ padding: "3em 0" }}>
                    <Col sm="3">
                      <img
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "contain",
                          marginBottom: "2em",
                        }}
                        src={require("./layouts/img/4-min.jpg")}
                        alt="litudian"
                      />
                    </Col>

                    <Col sm="9">
                      <p>
                        Wayo, Swahili for footprint, is Litudian's latest
                        footwear brand. We're bridging the gap between you and
                        some of your favorite Global shoe brands; Nike, Adidas,
                        Converse, Fila, etc. All these shoes are available in
                        stock at our outlet for collection or immediate
                        delivery.
                      </p>

                      <p>
                        <a
                          href="https://www.instagram.com/wayo.footwear/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click HERE
                        </a>{" "}
                        to view and select your shoes of interest.
                      </p>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="about" title={<TabHeading title="About Us" />}>
                  <Row style={{ padding: "3em 0" }}>
                    <Col sm="12">
                      <p>
                        Litudian is Sub-Sahara Africa's top sourcing and
                        importing platform where we source products, curate
                        suppliers, check quality and manage the logistics from
                        the world to you. (For your personal, business or side
                        hustle needs)
                      </p>

                      <ul>
                        <li>BABY, KIDS & GAMES</li>

                        <li>CLOTHES & APPAREL</li>

                        <li>CLOTHES & APPAREL</li>

                        <li>HOME & CONSTRUCTION</li>

                        <li>SPORTS & ENTERTAINMENT</li>

                        <li>MACHINERY & INDUSTRIALS</li>

                        <li>BAGS & FOOTWEAR</li>

                        <li>FASHION ACCESSORIES</li>

                        <li>GIFTS & CRAFTS</li>
                      </ul>
                    </Col>
                  </Row>
                </Tab>

                <Tab
                  eventKey="check_points"
                  title={<TabHeading title="Check Points" />}
                >
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "1em 0",
                    }}
                  >
                    <Stepper
                      activeStep={activeStep}
                      styleConfig={{ activeBgColor: "#f1592a" }}
                    >
                      <Step label=""></Step>

                      <Step label="" />
                    </Stepper>

                    {activeStep === 0 && (
                      <Row>
                        <Col sm="12">
                          <Form as={Row}>
                            <Form.Group
                              controlId="title"
                              as={Col}
                              md={{ span: 10, offset: 1 }}
                            >
                              <h1 style={{ padding: 0, margin: 0 }}>
                                Check Points
                              </h1>
                            </Form.Group>

                            <Form.Group
                              controlId="phonenumber"
                              as={Col}
                              md={{ span: 10, offset: 1 }}
                            >
                              <Form.Label>Provide Phone Number</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="Enter Phone Number"
                                name="phone_number"
                                value={phone_number}
                                onChange={this.onChange}
                              />
                            </Form.Group>

                            <Form.Group
                              controlId="submit"
                              as={Col}
                              md={{ span: 10, offset: 1 }}
                            >
                              <Button
                                variant="primary"
                                onClick={() => {
                                  this.onSubmit();
                                }}
                                disabled={loading}
                              >
                                {loading === false ? "Submit" : "Wait ..."}
                              </Button>
                            </Form.Group>
                          </Form>
                        </Col>

                        <Col md={{ span: 10, offset: 1 }}>
                          <AlertComponent
                            variant={variant}
                            show={showAlert}
                            onClose={this.toggleAlert}
                            message={message}
                          />
                        </Col>
                      </Row>
                    )}

                    {activeStep === 1 && (
                      <Row>
                        {hideform === false && (
                          <Col sm="12">
                            <Form as={Row}>
                              <Form.Group
                                controlId="title"
                                as={Col}
                                md={{ span: 10, offset: 1 }}
                              >
                                <h1 style={{ padding: 0, margin: 0 }}>
                                  Check Points
                                </h1>
                              </Form.Group>

                              <Form.Group
                                controlId="validationcode"
                                as={Col}
                                md={{ span: 10, offset: 1 }}
                              >
                                <Form.Label>Enter Validation Code</Form.Label>

                                <Form.Control
                                  type="text"
                                  placeholder="Validation Code"
                                  name="validation_code"
                                  value={validation_code}
                                  onChange={this.onChange}
                                />
                              </Form.Group>

                              <Form.Group
                                controlId="email"
                                as={Col}
                                md={{ span: 10, offset: 1 }}
                              >
                                <Form.Label>Provide Email Address</Form.Label>

                                <Form.Control
                                  type="email"
                                  placeholder="Email Address"
                                  name="email"
                                  value={email}
                                  onChange={this.onChange}
                                />
                              </Form.Group>

                              <Form.Group
                                controlId="submit"
                                as={Col}
                                md={{ span: 10, offset: 1 }}
                              >
                                <Button
                                  variant="info"
                                  onClick={() => {
                                    this.setState({ activeStep: 0 });

                                    this.setState({
                                      phone_number: "",
                                      validation_code: "",
                                      email: "",
                                    });
                                  }}
                                  disabled={loading}
                                  style={{ marginRight: "1em" }}
                                >
                                  Back
                                </Button>

                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    this.onValidateUser();
                                  }}
                                  disabled={loading}
                                >
                                  {loading === false ? "Submit" : "Wait ..."}
                                </Button>
                              </Form.Group>
                            </Form>
                          </Col>
                        )}

                        <Col md={{ span: 10, offset: 1 }}>
                          <AlertComponent
                            variant={variant}
                            show={showAlert}
                            onClose={this.toggleAlert}
                            message={message}
                          />
                        </Col>

                        {hideform === true && (
                          <Col md={{ span: 10, offset: 1 }}>
                            <Button
                              variant="info"
                              onClick={() => {
                                this.setState({ activeStep: 0 });

                                this.setState({
                                  phone_number: "",
                                  validation_code: "",
                                  email: "",
                                  hideform: false,
                                  showAlert: false,
                                });
                              }}
                              disabled={loading}
                              style={{ marginRight: "1em" }}
                            >
                              Back
                            </Button>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Col>
                </Tab>
              </Tabs>
            </Container>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { signin })(withRouter(Index));
