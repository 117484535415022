import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Container,
  Modal,
  Button,
  Form,
  NavDropdown,
} from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import { Formik } from "formik";
import { connect } from "react-redux";
import { signin } from "./../../store/actions/AuthActions";
import { toast } from "react-toastify";
import { signinSchema } from "./../../helpers/validation/signin";
import { withRouter, Link } from "react-router-dom";
import { withCookies } from "react-cookie";
import "./css/Index.css";

const expiry = localStorage.getItem("ltdn_exp");
const currentTime = Date.now() / 1000;
const authenticated = expiry > currentTime;

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,

      showModal: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isAuthenticated: authenticated });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { isAuthenticated, showModal } = this.state;
    const { cookies } = this.props;

    return (
      <Col sm="12">
        <Row>
          <Col className="navbar-col">
            <Navbar bg="light" expand="lg" className="navbar navbar-scrolled">
              <Container>
                <Navbar.Brand className="navbar-brand" as={Link} to="/">
                  <img
                    src={require("./../common/img/logo.png")}
                    alt="litudian"
                  />
                </Navbar.Brand>

                <Navbar.Toggle />

                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className="justify-content-end"
                >
                  {isAuthenticated === true && (
                    <Nav.Link className="navbar-link" as={Link} to="/admin">
                      HOME
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/users"
                    >
                      USERS
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/products"
                    >
                      PRODUCTS
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/moqs"
                    >
                      CAMPAIGNS
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/orders"
                    >
                      POs
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/suppliers"
                    >
                      SUPPLIERS
                    </Nav.Link>
                  )}

                  {/* {isAuthenticated === true && <Nav.Link className="navbar-link" as={Link} to="/admin/bids">BIDS</Nav.Link>} */}

                  {/* {isAuthenticated === true && <Nav.Link className="navbar-link" as={Link} to="/admin/colors">COLORS</Nav.Link>}

                                    {isAuthenticated === true && <Nav.Link className="navbar-link" as={Link} to="/admin/sizes">SIZES</Nav.Link>} */}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/transactions"
                    >
                      TRANSACTIONS
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/recommendations"
                    >
                      RECOMMENDATIONS
                    </Nav.Link>
                  )}

                  {isAuthenticated === true && (
                    <Nav.Link
                      className="navbar-link"
                      as={Link}
                      to="/admin/promo-codes"
                    >
                      PROMO-CODES
                    </Nav.Link>
                  )}

                  {/* {isAuthenticated === true && <Nav.Link className="navbar-link" onClick={() => {

                                        cookies.remove("ltdn");

                                        localStorage.removeItem('ltdn_exp');

                                        return window.location.href = "/signin";

                                    }}>LOG OUT</Nav.Link>} */}

                  {isAuthenticated === true && (
                    <NavDropdown
                      title="MORE"
                      id="basic-nav-dropdown"
                      style={{ padding: "0 10px" }}
                    >
                      <NavDropdown.Item as={Link} to="/admin/categories">
                        Categories
                      </NavDropdown.Item>

                      <NavDropdown.Item as={Link} to="/admin/colors">
                        Colors
                      </NavDropdown.Item>

                      <NavDropdown.Item as={Link} to="/admin/sizes">
                        Sizes
                      </NavDropdown.Item>

                      <NavDropdown.Item as={Link} to="/admin/send-sms">
                        Send SMS
                      </NavDropdown.Item>

                      <NavDropdown.Item as={Link} to="/admin/settings">
                        Settings
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        onClick={() => {
                          cookies.remove("ltdn");

                          localStorage.removeItem("ltdn_exp");

                          return (window.location.href = "/signin");
                        }}
                      >
                        Log Out
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}

                  {/* {isAuthenticated === false && <Nav.Link className="navbar-link" onClick={() => {this.toggleModal()}}>SIGN IN</Nav.Link>} */}

                  {isAuthenticated === false && (
                    <Nav.Link className="navbar-link" as={Link} to="/signin">
                      LOG IN
                    </Nav.Link>
                  )}
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>

          <Col sm="12">{this.props.children}</Col>

          <Col sm="12" className="footer">
            <Container>
              <Row>
                <Col sm="4" className="footer-item">
                  <h6>Email</h6>

                  <p>sales@litudian.com</p>
                </Col>

                <Col sm="4" className="footer-item">
                  <h6>Address</h6>

                  <p>
                    Highway Mall, off Bunyala Road, 3rd floor Room 12, Nairobi,
                    Kenya.
                  </p>
                </Col>

                <Col sm="4" className="footer-item">
                  <h6>Contacts</h6>

                  <p>0792819290, 0780620888 </p>
                </Col>

                <Col
                  sm="12"
                  style={{
                    marginTop: "20px",
                    padding: "0 10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Litudian &copy; {new Date().getFullYear()}
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>

          {/* SignIn Modal */}
          <Modal show={showModal} onHide={this.toggleModal}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{ msisdn: "", password: "" }}
              validationSchema={signinSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const data = await this.props.signin(values);

                if (data === "") {
                  return toast.warning("Incorrect Credentials !", {
                    position: "top-right",
                  });
                }

                localStorage.setItem("ltdn", data.token);

                localStorage.setItem(
                  "ltdn_exp",
                  Math.floor(Date.now() / 1000) + 60 * 60 * 1
                );

                toast.success("You have successfully signed in !", {
                  position: "top-right",
                });

                return (window.location.href = "/admin");
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <Modal.Body>
                    <Form.Group as={Row} controlId="phone_number">
                      <Form.Label column sm="3">
                        Phone Number
                      </Form.Label>

                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          name="msisdn"
                          isValid={touched.msisdn && !errors.msisdn}
                          isInvalid={errors.msisdn && touched.msisdn}
                          value={values.msisdn}
                          onChange={handleChange}
                        />

                        {errors.msisdn && touched.msisdn && (
                          <Form.Control.Feedback type="invalid">
                            {errors.msisdn}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="password">
                      <Form.Label column sm="3">
                        Password
                      </Form.Label>

                      <Col sm="9">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          name="password"
                          isValid={touched.password && !errors.password}
                          isInvalid={errors.password && touched.password}
                          value={values.password}
                          onChange={handleChange}
                        />

                        {errors.password && touched.password && (
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.toggleModal}>
                      Close
                    </Button>

                    <Button variant="primary" onClick={handleSubmit}>
                      Sign In
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>

          <CookieConsent
            buttonStyle={{ backgroundColor: "#FF4C00", color: "#fff" }}
            style={{
              backgroundColor: "#fff",
              color: "#000",
              boxShadow: "inset 0px 8px 19px -3px rgba(0,0,0,0.1)",
            }}
          >
            Litudian uses cookies to enhance your experience on our platform,
            analyze site usage, personalize content and assist in our marketing
            efforts. By continuing to use our website, you agree to our use of
            cookies. For more information, please review our{" "}
            <b>
              <a
                href="https://litudian.com/cookie-policy"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
            </b>
            .
          </CookieConsent>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { signin })(
  withRouter(withCookies(Index))
);
