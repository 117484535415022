import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  ListGroup,
  Button,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import { showBid, editBid, deleteBid } from "./../../store/actions/BidActions";
import Moment from "react-moment";
// import { instance, setAuthToken } from './../../helpers/instance';
import { Formik } from "formik";
import * as yup from "yup";
// import { createSchema } from './../../helpers/validation/bid';
import { toast } from "react-toastify";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { getSizes } from "./../../store/actions/SizeActions";
import { getColors } from "./../../store/actions/ColorActions";
import { titleCase } from "./../../utils/index";
import { showMoq } from "./../../store/actions/MoqActions";
import { getProduct } from "./../../store/actions/ProductActions";

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      bidRemarksModal: false,

      remark: "",

      bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],

      readable_colors: [],

      readable_sizes: [],

      product: {},

      editModal: false,

      priviledge: 0,

      deleteModal: false,
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    // console.log(params);

    const result = await this.props.showBid(params.id);

    if (result) {
      // console.log(result);

      if (
        result.bid_attributes &&
        result.bid_attributes !== undefined &&
        result.bid_attributes.length > 0
      ) {
        let data = [];

        result.bid_attributes.forEach((attribute) => {
          // console.log(attribute);

          data.push({
            color_id: attribute.color_id,
            size_id: attribute.size_id,
            quantity: attribute.quantity,
          });
        });

        // console.log(data);

        this.setState({ bid_attributes: data });
      }

      if (result.product && result.product[0].id) {
        this.setProductSizesAndColors(result.product[0].id);
      }

      await this.props.showMoq(result.moq_id);

      this.setState({ loading: false });
    }

    await this.props.getSizes();

    await this.props.getColors();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInputChange = (e, index) => {
    const { bid_attributes } = this.state;

    const { name, value } = e.target;

    let attributes = [...bid_attributes];

    attributes[index][name] = parseInt(value);

    this.setState({ bid_attributes: attributes });
  };

  handleRemoveClick = (index) => {
    const { bid_attributes } = this.state;

    let list = [...bid_attributes];

    list.splice(index, 1);

    this.setState({ bid_attributes: list });
  };

  handleAddClick = () => {
    // console.log('you clicked me');

    let { bid_attributes } = this.state;

    this.setState({
      bid_attributes: [
        ...bid_attributes,
        { size_id: 0, quantity: 0, color_id: 0 },
      ],
    });
  };

  toggleBidRemarksModal = () => {
    this.setState({ bidRemarksModal: !this.state.bidRemarksModal });
  };

  addRemark = async () => {
    try {
      const {
        bids: { bid: selected_bid },
      } = this.props;

      const { remark } = this.state;

      if (remark !== "") {
        let values = {
          bid_remarks: remark,
        };

        this.setState({ loading: true });

        const result = await this.props.editBid(selected_bid.id, values);

        if (result) {
          // console.log(result);

          toast.success("Bid Remark Added Successfully !", {
            position: "top-right",
          });

          this.setState({ loading: false, bidRemarksModal: false, remark: "" });

          const {
            match: { params },
          } = this.props;

          await this.props.showBid(params.id);
        }
      } else {
        return toast.error("Remark field in required !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      toast.error("An Error Occured. Please try again later !", {
        position: "top-right",
      });
    }
  };

  setProductSizesAndColors = async (id) => {
    try {
      const product = await this.props.getProduct(id);

      if (product) {
        this.setState({
          readable_colors: product.readable_colors,
          readable_sizes: product.readable_sizes,
          product,
        });
      }

      return product;
    } catch (error) {
      console.log(error);
    }
  };

  toggleEditModal = () => {
    this.setState({ editModal: !this.state.editModal });
  };

  editBid = async (values) => {
    try {
      const {
        bids: { bid },
      } = this.props;

      let data = [],
        quantity = 0;

      const { bid_attributes } = this.state;

      if (
        bid_attributes.length > 0 &&
        bid_attributes[0].size_id !== 0 &&
        bid_attributes[0].quantity !== 0 &&
        bid_attributes[0].color_id !== 0
      ) {
        data = bid_attributes;

        bid_attributes.forEach((attribute) => {
          quantity = quantity + attribute.quantity;
        });

        if (parseInt(values.quantity) !== quantity) {
          toast.warning(
            "Sum Of Quantity On Bid Attrbutes Must Be Same As Quantity Provided !",
            {
              position: "top-right",
            }
          );

          return null;
        }
      }

      if (values.shipping_status === 1 && values.total_shipping === 0) {
        return toast.error(
          "Total Shipping Fee is Required And Must Be Greater Than 0 !",
          {
            position: "top-right",
          }
        );
      }

      values.bid_attributes = data;

      const result = await this.props.editBid(bid.id, values);

      if (result) {
        // console.log(result);

        toast.success("Bid Updated Successfully !", {
          position: "top-right",
        });

        this.setState({
          editModal: false,
          bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],
        });

        const {
          match: { params },
        } = this.props;

        let response = await this.props.showBid(params.id);

        if (
          response.bid_attributes &&
          response.bid_attributes !== undefined &&
          response.bid_attributes.length > 0
        ) {
          let data = [];

          response.bid_attributes.forEach((attribute) => {
            // console.log(attribute);

            data.push({
              color_id: attribute.color_id,
              size_id: attribute.size_id,
              quantity: attribute.quantity,
            });
          });

          // console.log(data);

          this.setState({ bid_attributes: data });
        }
      }
    } catch (error) {
      // console.log(error.response);

      if (
        error.response !== undefined &&
        error.response.status === 400 &&
        error.response.data
      ) {
        // console.log(error.response.data.message);

        return toast.warning(error.response.data.message, {
          position: "top-right",
        });
      } else {
        toast.warning(
          "We could not finish your request. Please try again later !",
          {
            position: "top-right",
          }
        );
      }
    }
  };

  toggleDeleteBidModal = () => {
    this.setState({ deleteModal: !this.state.deleteModal });
  };

  deleteBid = async () => {
    try {
      const {
        bids: { bid: selected_bid },
      } = this.props;

      this.setState({ loading: true });

      let obj = { active: 0 };

      const result = await this.props.deleteBid(selected_bid.id, obj);

      if (result) {
        this.setState({ loading: false, deleteModal: false });

        return this.props.history.goBack();
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  };

  render() {
    const {
      bids: { bid },
      moqs: { moq },
    } = this.props;

    // console.log(bid);

    const {
      loading,
      editModal,
      priviledge,
      product,
      readable_colors,
      readable_sizes,
      bid_attributes,
    } = this.state;

    // console.log(bid_attributes);

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              {!loading && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  <h3>
                    Bid{" "}
                    {bid.product !== undefined &&
                      `for ${titleCase(bid.product[0].name)} `}{" "}
                    {bid.moq !== undefined && `- MOQ ${bid.moq[0].moq_number}`}
                  </h3>
                </Col>
              )}

              {!loading && (
                <Col sm="12">
                  <Card style={{ marginBottom: "1em" }}>
                    <Card.Body>
                      <ListGroup>
                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>Bidder: </span>{" "}
                          <span>
                            {bid.user !== undefined
                              ? `${bid.user[0].first_name} ${bid.user[0].last_name}`
                              : "N/A"}
                          </span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>Contact: </span>{" "}
                          <span>
                            {bid.user !== undefined
                              ? bid.user[0].msisdn
                              : "N/A"}
                          </span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>Bid No: </span>{" "}
                          <span>{bid.bid_number}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>MOQ No: </span>{" "}
                          <span>
                            {bid.moq !== undefined ? (
                              <Link to={`/admin/moqs/${bid.moq[0].id}`}>
                                {bid.moq[0].moq_number}
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </ListGroup.Item>

                        {bid.bid_attributes !== undefined && (
                          <ListGroup.Item
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Attributes:{" "}
                            </span>

                            <ul>
                              {bid.bid_attributes.map((attribute, i) => {
                                if (
                                  attribute !== undefined ||
                                  attribute.colors !== undefined ||
                                  attribute.sizes !== undefined
                                ) {
                                  return (
                                    <li key={i}>
                                      {attribute.quantity}{" "}
                                      {attribute.colors !== undefined
                                        ? attribute.colors.label
                                        : ""}{" "}
                                      {attribute.sizes !== undefined
                                        ? `size ${attribute.sizes.label}`
                                        : ""}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </ul>
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>Quanity: </span>{" "}
                          <span>{bid.quantity}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>Amount: </span>{" "}
                          <span>{bid.amount}</span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Payment Status:{" "}
                          </span>

                          {bid.status === 0 && (
                            <span
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "3px 10px",
                                borderRadius: "5px",
                              }}
                            >
                              Unpaid
                            </span>
                          )}

                          {bid.status === 1 && (
                            <span
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                padding: "3px 10px",
                                borderRadius: "5px",
                              }}
                            >
                              Paid
                            </span>
                          )}
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Total S/F:{" "}
                          </span>{" "}
                          <span>
                            {bid.total_shipping === null
                              ? 0
                              : bid.total_shipping}
                          </span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>
                            S/F Status:{" "}
                          </span>

                          {bid.shipping_status === 0 && (
                            <span
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "3px 10px",
                                borderRadius: "5px",
                              }}
                            >
                              Unpaid
                            </span>
                          )}

                          {bid.shipping_status === 1 && (
                            <span
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                padding: "3px 10px",
                                borderRadius: "5px",
                              }}
                            >
                              Paid
                            </span>
                          )}
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Collection Status:{" "}
                          </span>{" "}
                          <span>
                            {bid.is_collected === 0
                              ? "Not Collected"
                              : "Collected"}
                          </span>
                        </ListGroup.Item>

                        <ListGroup.Item style={{ display: "inline-block" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Date Collected:{" "}
                          </span>{" "}
                          <span>
                            {bid.is_collected === 1 &&
                            bid.date_collected !== null ? (
                              <Moment format="DD/MM/YYYY">
                                {bid.date_collected}
                              </Moment>
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>

                    <Card.Footer
                      className="text-muted"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => this.toggleBidRemarksModal()}
                      >
                        View Remarks
                      </Button>

                      <div>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => this.toggleEditModal()}
                          style={{ marginRight: "1em" }}
                        >
                          Edit Bid
                        </Button>

                        {moq.moq_order_status === "not placed" && (
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => this.toggleDeleteBidModal(bid)}
                          >
                            Delete Bid
                          </Button>
                        )}
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </Col>

        {/* Bid Remarks Modal */}
        <Modal
          show={this.state.bidRemarksModal}
          onHide={this.toggleBidRemarksModal}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Bid Remarks</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col sm="12" style={{ margin: "1em 0" }}>
                {bid !== null && bid.bid_remarks !== undefined && (
                  <ListGroup as="ol" numbered>
                    {bid.bid_remarks.map((remark, i) => (
                      <ListGroup.Item key={i}>
                        {remark.remarks} -{" "}
                        <Moment format="YYYY-MM-DD">
                          {remark.date_created}
                        </Moment>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Col>

              <Col sm="12" style={{ margin: "1em 0" }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.addRemark();
                  }}
                >
                  <Form.Group as={Row} controlId="msisdn">
                    <Form.Label column sm="12">
                      New Remark
                    </Form.Label>

                    <Col sm="10">
                      <Form.Control
                        type="text"
                        placeholder="Write..."
                        name="remark"
                        value={this.state.remark}
                        onChange={(e) => {
                          this.setState({ remark: e.target.value });
                        }}
                      />
                    </Col>

                    <Col sm="2">
                      <Button variant="success" type="submit">
                        Add
                      </Button>
                    </Col>
                  </Form.Group>
                </form>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.toggleBidRemarksModal()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Bid Modal */}
        <Modal show={editModal} onHide={this.toggleEditModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Bid</Modal.Title>
          </Modal.Header>

          <Formik
            enableReinitialize
            initialValues={{
              status: bid.status !== undefined ? bid.status : 0,
              quantity: bid.quantity !== undefined ? bid.quantity : "",
              moq_id: bid !== undefined && bid !== null ? bid.moq_id : "",
              msisdn:
                bid.user !== undefined ? bid.user[0].msisdn.toString() : "",
              shipping: bid.shipping !== undefined ? bid.shipping : "",
              is_collected:
                bid.is_collected !== undefined ? bid.is_collected : "",
              total_shipping:
                bid.total_shipping !== undefined && bid.total_shipping !== null
                  ? bid.total_shipping
                  : 0,
              shipping_status:
                bid.shipping_status !== undefined ? bid.shipping_status : "",
              bid_remarks: "",
            }}
            validationSchema={yup.object().shape({
              status: yup.number().required(),

              total_shipping:
                moq.moq_order_status ===
                "Goods arrived at Litudian - Ready to collect"
                  ? yup.number()
                  : "",
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              values.quantity = values.quantity.toString();

              values.total_shipping =
                values.total_shipping !== null
                  ? parseInt(values.total_shipping)
                  : null;

              this.editBid(values);

              // console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="msisdn">
                    <Form.Label column sm="12">
                      Phone Number
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        type="number"
                        placeholder="Phone Number"
                        name="msisdn"
                        isValid={touched.msisdn && !errors.msisdn}
                        isInvalid={errors.msisdn && touched.msisdn}
                        value={values.msisdn}
                        onChange={handleChange}
                        disabled={priviledge === 1 || priviledge === 2}
                      />

                      {errors.msisdn && touched.msisdn && (
                        <Form.Control.Feedback type="invalid">
                          {errors.msisdn}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="moq_id">
                    <Form.Label column sm="12">
                      Product
                    </Form.Label>

                    <Col sm="12">
                      {/* <Select isDisabled defaultValue={values.moq_id} options={moqs_data} onChange={async (value) => { 

                                                console.log(value);
                                                
                                                setFieldValue('moq_id', value); 

                                                this.setState({ loading: true });

                                                await this.setProductSizesAndColors(value.product_id);

                                                this.setState({ loading: false });
                                                
                                            }} onBlur={() => { setFieldTouched('moq_id', true); }} touched={touched.moq_id} /> */}

                      <Form.Control
                        as="select"
                        isValid={touched.moq_id && !errors.moq_id}
                        defaultValue={values.moq_id}
                        isInvalid={errors.moq_id && touched.moq_id}
                        name="moq_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly
                      >
                        {/* <option value="">Choose ...</option> */}

                        {bid !== null && bid !== undefined && (
                          <option value={bid.moq_id}>
                            {bid.product[0].name}
                          </option>
                        )}

                        {/* {moqs_data.map((moq, i) => <option key={i} value={moq} selected={selectedMoq.id === moq.value}>{moq.label}</option>)} */}
                      </Form.Control>

                      {/* {errors.moq_id && touched.moq_id && <Form.Control.Feedback type="invalid">{errors.moq_id}</Form.Control.Feedback>} */}

                      {errors.moq_id && touched.moq_id && (
                        <Form.Text style={{ color: "#dc3545" }}>
                          {errors.moq_id}
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="quantity">
                    <Form.Label column sm="12">
                      Quantity
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        type="number"
                        placeholder="Quantity"
                        name="quantity"
                        isValid={touched.quantity && !errors.quantity}
                        isInvalid={errors.quantity && touched.quantity}
                        value={values.quantity}
                        onChange={handleChange}
                        readOnly={values.status === 1}
                      />

                      {errors.quantity && touched.quantity && (
                        <Form.Control.Feedback type="invalid">
                          {errors.quantity}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="shipping">
                    <Form.Label column sm="12">
                      Shipping
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        as="select"
                        isValid={touched.shipping && !errors.shipping}
                        defaultValue={values.shipping}
                        isInvalid={errors.shipping && touched.shipping}
                        name="shipping"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={moq.moq_order_status !== "not placed"}
                      >
                        <option value="">Choose ...</option>

                        <option value="Air">Air</option>

                        <option value="Sea">Sea</option>
                      </Form.Control>

                      {errors.shipping && touched.shipping && (
                        <Form.Control.Feedback type="invalid">
                          {errors.shipping}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  {(product.readable_colors !== null ||
                    product.readable_sizes !== null) && (
                    <Form.Group as={Row} controlId="shipping">
                      <Form.Label column sm="12">
                        Bid Attributes
                      </Form.Label>

                      <Col sm="12">
                        {bid_attributes !== undefined &&
                          bid_attributes.map((x, i) => {
                            return (
                              <Row style={{ marginBottom: "10px" }} key={i}>
                                <Col sm="3">
                                  <Form.Control
                                    as="select"
                                    name="size_id"
                                    value={x.size_id}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                    disabled={loading}
                                    readOnly={
                                      moq.moq_order_status !== "not placed"
                                    }
                                  >
                                    <option value="">Sizes ...</option>

                                    {readable_sizes !== null &&
                                      readable_sizes.map((size, i) => (
                                        <option
                                          key={i}
                                          value={size.id}
                                          selected={x.size_id === size.id}
                                        >
                                          {size.label}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </Col>

                                <Col sm="3">
                                  <Form.Control
                                    as="select"
                                    name="color_id"
                                    value={x.color_id}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                    disabled={loading}
                                    readOnly={
                                      moq.moq_order_status !== "not placed"
                                    }
                                  >
                                    <option value="">Colors ...</option>

                                    {readable_colors !== null &&
                                      readable_colors.map((color, i) => (
                                        <option
                                          key={i}
                                          value={color.id}
                                          selected={x.color_id === color.id}
                                        >
                                          {color.label}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </Col>

                                <Col sm="3">
                                  <Form.Control
                                    type="number"
                                    placeholder="Quantity"
                                    name="quantity"
                                    value={x.quantity}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                    disabled={loading}
                                    readOnly={
                                      moq.moq_order_status !== "not placed"
                                    }
                                  />
                                </Col>

                                <Col sm="3">
                                  {bid_attributes.length - 1 === i && (
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "10px" }}
                                      onClick={this.handleAddClick}
                                      disabled={
                                        moq.moq_order_status !== "not placed"
                                      }
                                    >
                                      <FiPlus />
                                    </Button>
                                  )}

                                  {bid_attributes.length !== 1 && (
                                    <Button
                                      variant="danger"
                                      onClick={() => this.handleRemoveClick(i)}
                                      disabled={
                                        moq.moq_order_status !== "not placed"
                                      }
                                    >
                                      <FiTrash2 />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                      </Col>

                      {/* <Col sm="12"><div style={{ marginTop: 20 }}>{JSON.stringify(bid_attributes)}</div></Col> */}
                    </Form.Group>
                  )}

                  {priviledge === 2 && (
                    <Form.Group as={Row} controlId="total_shipping">
                      <Form.Label column sm="12">
                        Total Shipping
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Total Shipping"
                          name="total_shipping"
                          isValid={
                            touched.total_shipping && !errors.total_shipping
                          }
                          isInvalid={
                            errors.total_shipping && touched.total_shipping
                          }
                          value={values.total_shipping}
                          onChange={handleChange}
                          disabled={
                            moq.moq_order_status !==
                            "Goods arrived at Litudian - Ready to collect"
                              ? true
                              : false
                          }
                        />

                        {errors.total_shipping && touched.total_shipping && (
                          <Form.Control.Feedback type="invalid">
                            {errors.total_shipping}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  )}

                  {priviledge === 2 && (
                    <Form.Group as={Row} controlId="shipping_status">
                      <Form.Label column sm="12">
                        Shipping Status
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          as="select"
                          isValid={
                            touched.shipping_status && !errors.shipping_status
                          }
                          defaultValue={values.shipping_status}
                          isInvalid={
                            errors.shipping_status && touched.shipping_status
                          }
                          name="shipping_status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={
                            moq.moq_order_status !==
                              "Goods arrived at Litudian - Ready to collect" ||
                            priviledge !== 2
                          }
                        >
                          <option>Choose ...</option>

                          <option
                            value={0}
                            selected={
                              bid.shipping_status !== undefined &&
                              bid.shipping_status === 0
                                ? true
                                : false
                            }
                          >
                            Shipping Fee Unpaid
                          </option>

                          <option
                            value={1}
                            selected={
                              bid.shipping_status !== undefined &&
                              bid.shipping_status === 1
                                ? true
                                : false
                            }
                          >
                            Shipping Fee Paid
                          </option>
                        </Form.Control>

                        {errors.shipping_status && touched.shipping_status && (
                          <Form.Control.Feedback type="invalid">
                            {errors.shipping_status}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  )}

                  <Form.Group as={Row} controlId="status">
                    <Form.Label column sm="12">
                      Payment Status
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        as="select"
                        isValid={touched.status && !errors.status}
                        defaultValue={values.status}
                        isInvalid={errors.status && touched.status}
                        name="status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          priviledge !== 2 ||
                          moq.moq_order_status !== "not placed"
                        }
                      >
                        <option>Choose ...</option>

                        <option
                          value={0}
                          selected={
                            bid.status !== undefined && bid.status === 0
                              ? true
                              : false
                          }
                        >
                          Unpaid
                        </option>

                        <option
                          value={1}
                          selected={
                            bid.status !== undefined && bid.status === 1
                              ? true
                              : false
                          }
                        >
                          Paid
                        </option>

                        {/* <option value={2} selected={bid.status !== undefined && bid.status === 2 ? true : false}>Collected</option> */}
                      </Form.Control>

                      {errors.status && touched.status && (
                        <Form.Control.Feedback type="invalid">
                          {errors.status}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="is_collected">
                    <Form.Label column sm="12">
                      Collection Status
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        as="select"
                        isValid={touched.is_collected && !errors.is_collected}
                        defaultValue={values.is_collected}
                        isInvalid={errors.is_collected && touched.is_collected}
                        name="is_collected"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          moq.moq_order_status !==
                            "Goods arrived at Litudian - Ready to collect" ||
                          priviledge !== 2
                        }
                      >
                        <option>Choose ...</option>

                        <option
                          value={0}
                          selected={
                            bid.is_collected !== undefined &&
                            bid.is_collected === 0
                              ? true
                              : false
                          }
                        >
                          Not Collected
                        </option>

                        <option
                          value={1}
                          selected={
                            bid.is_collected !== undefined &&
                            bid.is_collected === 1
                              ? true
                              : false
                          }
                        >
                          Collected
                        </option>
                      </Form.Control>

                      {errors.is_collected && touched.is_collected && (
                        <Form.Control.Feedback type="invalid">
                          {errors.is_collected}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="bid_remarks">
                    <Form.Label column sm="12">
                      Bid Remarks
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="Bid Remarks"
                        name="bid_remarks"
                        isValid={touched.bid_remarks && !errors.bid_remarks}
                        isInvalid={errors.bid_remarks && touched.bid_remarks}
                        value={values.bid_remarks}
                        onChange={handleChange}
                      />

                      {errors.bid_remarks && touched.bid_remarks && (
                        <Form.Control.Feedback type="invalid">
                          {errors.bid_remarks}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.toggleEditModal(null);
                    }}
                  >
                    Close
                  </Button>

                  <Button variant="primary" onClick={handleSubmit}>
                    Edit Bid
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>

        {/* Delete Bid Modal */}
        <Modal
          show={this.state.deleteModal}
          onHide={this.toggleDeleteBidModal}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Bid</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col sm="12" style={{ margin: "1em 0" }}>
                <h5>
                  Are you sure you want to delete{" "}
                  {bid !== null && bid.user !== undefined
                    ? `${bid.user[0].first_name.toLowerCase()}'s (${
                        bid.user[0].msisdn
                      })`
                    : ""}{" "}
                  bid ?
                </h5>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleDeleteBidModal}>
              Close
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                this.deleteBid();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bids: state.bids,

    sizes: state.sizes,

    colors: state.colors,

    moqs: state.moqs,
  };
};

export default connect(mapStateToProps, {
  showBid,
  editBid,
  deleteBid,
  getSizes,
  getColors,
  showMoq,
  getProduct,
})(withRouter(Show));
