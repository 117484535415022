import { instance, setAuthToken } from "./../../helpers/instance";
import { STATISTICS } from "./../types/index";

// Fetching All User's
export const getStatistics = () => async (dispatch) => {
  try {
    setAuthToken(instance);

    const response = await instance.get("stats");

    if (response.data !== "") {
      dispatch({ type: STATISTICS, payload: response?.data });
    }

    return response.data;
  } catch (error) {
    // console.log(error);

    if (error.response !== undefined && error.response.status === 401) {
      return (window.location.href = "/signin");
    }

    throw error;
  }
};
