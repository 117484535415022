import React, { Component } from 'react';
import Layout from './../layouts/Index';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import { showCategory } from './../../store/actions/CategoryActions';
import Moment from 'react-moment';
// import truncate from 'truncate-txt';
// import { titleCase } from './../../utils/index';

class Show extends Component {

    constructor(props) {

        super(props);

        this.state = {

            loading: false
            
        }

    }

    componentDidMount = async () => {

        const { match: { params } } = this.props;

        this.setState({ loading: true });

        // console.log(params);

        const result = await this.props.showCategory(params.id);

        if(result) {

            this.setState({ loading: false });

        }

    }

    render() {

        const { categories: { category } } = this.props;

        const { loading } = this.state;

        return (

            <Layout>

                <Col sm="12" style={{ minHeight: '80vh', padding: '1.5em 0' }}>

                    <Container>

                        <Row>

                            {!loading && <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: '1em' }}>

                                <h3>Category Details</h3>

                            </Col>}

                            {!loading && <Col sm="12">

                                <ListGroup>

                                    <ListGroup.Item style={{ display: 'inline-block' }}>

                                        <span style={{ fontWeight: 'bold' }}>Category Name: </span> <span>{category.name}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Status: </span> <span>{category.status !== undefined && category.status === 1 ? 'Active' : 'Inactive'}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Date Created: </span> <span><Moment format="DD/MM/YYYY">{category.date_created}</Moment></span>

                                    </ListGroup.Item>

                                </ListGroup>

                            </Col>}

                        </Row>

                    </Container>

                </Col>
                
            </Layout>

        )

    }

};

const mapStateToProps = (state, ownProps) => {

    return {

        categories: state.categories,

    }

};

export default connect(mapStateToProps, { showCategory })(Show);