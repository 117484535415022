import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import BlockLoader from "./../contentloaders/BlockLoader";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Form,
  Modal,
  FormControl,
} from "react-bootstrap";
import {
  getTransactions,
  createPoints,
  getMoreTransactions,
  searchTransactions,
  searchedTransactions,
} from "./../../store/actions/TransactionActions";
import { createSchema } from "./../../helpers/validation/transaction";
import { toast } from "react-toastify";
import { Formik } from "formik";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      editModal: false,

      supplier: {},

      loading: false,

      reference: "",

      transaction_id: "",

      searched: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    const res = await this.props.getTransactions();

    if (res) {
      this.setState({ loading: false });
    }
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  createPoints = async (values) => {
    try {
      // console.log(values);

      this.setState({ loading: true });

      const result = await this.props.createPoints(values);

      if (result) {
        // console.log(result);

        toast.success("Points Created Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, loading: false });

        await this.props.getTransactions();
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });

      if (error.response.data !== undefined) {
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
    }
  };

  fetchMoreTransactions = async () => {
    try {
      this.setState({ loading: true });

      const {
        transactions: { next_page },
      } = this.props;

      const result = await this.props.getMoreTransactions(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchTransactions = async () => {
    try {
      const { reference, transaction_id } = this.state;

      let obj = {};

      if (reference !== "") {
        obj.reference = reference;
      }

      if (transaction_id !== "") {
        obj.transaction_id = transaction_id;
      }

      this.setState({ loading: true });

      const result = await this.props.searchTransactions(obj);

      if (result) {
        await this.props.searchedTransactions(result);

        this.setState({ loading: false, searched: true });

        toast.success("Transaction Found !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("Transaction Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13) {
        await this.searchTransactions();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  refresh = async () => {
    this.setState({ loading: true });

    const result = await this.props.getTransactions();

    if (result) {
      this.setState({
        loading: false,
        name: "",
        searched: false,
        reference: "",
        transaction_id: "",
      });
    }
  };

  render() {
    const {
      transactions,
      transactions: { page, pages },
    } = this.props;

    const { loading, reference, transaction_id, searched } = this.state;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await this.searchTransactions();
                  }}
                >
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Transaction ID"
                        name="transaction_id"
                        value={transaction_id}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Reference"
                        name="reference"
                        value={reference}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Button variant="outline-success" type="submit">
                        Search
                      </Button>
                    </Col>
                    {searched === true && (
                      <Col>
                        <Button
                          variant="outline-success"
                          type="button"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            this.refresh();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>

              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Transactions</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="150px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                <Button
                  variant="success"
                  size="sm"
                  onClick={() => this.toggleModal()}
                >
                  Redeem Points
                </Button>
              </Col>

              <Col sm="12">
                {!loading && (
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>Account Number</th>

                        <th>Amount</th>

                        <th>Reference</th>

                        <th>Type</th>

                        <th>Sender Name(s)</th>

                        <th>Sender Phone number</th>

                        <th>Transaction Date</th>

                        <th>Status</th>

                        <th>#</th>
                      </tr>
                    </thead>

                    <tbody>
                      {transactions.data.map((transaction, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>{transaction.account_number}</td>

                          <td>{transaction.amount}</td>

                          <td>{transaction.reference}</td>

                          <td>{transaction.type}</td>

                          <td>{transaction.user}</td>

                          <td>{transaction.sender}</td>

                          <td>
                            <Moment format="DD/MM/YYYY">
                              {transaction.date_created}
                            </Moment>
                          </td>

                          <td>
                            {transaction.status === 1
                              ? "Received"
                              : "Not Received"}
                          </td>

                          <td>
                            <Button
                              variant="info"
                              style={{ color: "#fff" }}
                              type="submit"
                              as={Link}
                              to={`/admin/transactions/${transaction.id}`}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                  />
                )}
              </Col>

              {page < pages && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => this.fetchMoreTransactions()}
                    disabled={loading}
                  >
                    {loading === true ? "Loading ..." : "Load More"}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </Col>

        {/* New Points */}
        <Modal show={this.state.showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Redeem Points</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={{ points: "", amount: "", msisdn: "" }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.createPoints(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="points">
                    <Form.Label column sm="3">
                      Points
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="number"
                        placeholder="Points"
                        name="points"
                        isValid={touched.points && !errors.points}
                        isInvalid={errors.points && touched.points}
                        value={values.points}
                        onChange={handleChange}
                      />

                      {errors.points && touched.points && (
                        <Form.Control.Feedback type="invalid">
                          {errors.points}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="amount">
                    <Form.Label column sm="3">
                      Transaction Amount
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="number"
                        placeholder="Amount"
                        name="amount"
                        isValid={touched.amount && !errors.amount}
                        isInvalid={errors.amount && touched.amount}
                        value={values.amount}
                        onChange={handleChange}
                      />

                      {errors.amount && touched.amount && (
                        <Form.Control.Feedback type="invalid">
                          {errors.amount}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="msisdn">
                    <Form.Label column sm="3">
                      Phone Number
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        name="msisdn"
                        isValid={touched.msisdn && !errors.msisdn}
                        isInvalid={errors.msisdn && touched.msisdn}
                        value={values.msisdn}
                        onChange={handleChange}
                      />

                      {errors.msisdn && touched.msisdn && (
                        <Form.Control.Feedback type="invalid">
                          {errors.msisdn}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleModal}>
                    Close
                  </Button>

                  <Button
                    variant="success"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Redeem Points
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    transactions: state.transactions,
  };
};

export default connect(mapStateToProps, {
  getTransactions,
  createPoints,
  getMoreTransactions,
  searchTransactions,
  searchedTransactions,
})(Index);
