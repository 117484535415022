import * as yup from 'yup';

export const createSchema = yup.object().shape({

    name: yup.string().required(),

    email: yup.string(),

    location: yup.string().required(),

    category: yup.string().required()

});