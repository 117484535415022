import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { signin } from "./../../store/actions/AuthActions";
import { toast } from "react-toastify";
import { Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import { signinSchema } from "./../../helpers/validation/signin";
import { withCookies } from "react-cookie";

const expiry = localStorage.getItem("ltdn_exp");
const currentTime = Date.now() / 1000;
const authenticated = expiry > currentTime;

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (authenticated) return (window.location.href = "/admin");
  }

  render() {
    const { cookies } = this.props;
    return (
      <Layout>
        <Row style={{ minHeight: "100vh", padding: "3em 0" }}>
          <Col md={{ span: 6, offset: 3 }}>
            <Card style={{ padding: "18px" }}>
              <Formik
                initialValues={{ username: "", password: "" }}
                validationSchema={signinSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const data = await this.props.signin(values);

                    if (data === "") {
                      return toast.warning("Incorrect Credentials !", {
                        position: "top-right",
                      });
                    }

                    localStorage.setItem("ltdn_priv", data.privilege);

                    cookies.set("ltdn", data.token, {
                      maxAge: 3600,
                    });

                    localStorage.setItem(
                      "ltdn_exp",
                      Math.floor(Date.now() / 1000) + 60 * 60 * 1
                    );

                    toast.success("You have successfully signed in !", {
                      position: "top-right",
                    });

                    return (window.location.href = "/admin");
                  } catch (error) {
                    toast.error("Invalid Credentails. Please try again !", {
                      position: "top-right",
                    });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      handleSubmit();
                    }}
                  >
                    <Modal.Dialog>
                      <Modal.Header className="mb-4">
                        <Modal.Title>Sign In</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <Form.Group
                          as={Row}
                          controlId="phone_number"
                          className="mb-3"
                        >
                          <Form.Label column sm="3">
                            Email / Phone Number
                          </Form.Label>

                          <Col sm="9">
                            <Form.Control
                              type="text"
                              placeholder="Email/Phone Number"
                              name="username"
                              isValid={touched.username && !errors.username}
                              isInvalid={errors.username && touched.username}
                              value={values.username}
                              onChange={handleChange}
                            />

                            {errors.username && touched.username && (
                              <Form.Control.Feedback type="invalid">
                                {errors.username}
                              </Form.Control.Feedback>
                            )}
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          controlId="password"
                          className="mb-3"
                        >
                          <Form.Label column sm="3">
                            Password
                          </Form.Label>

                          <Col sm="9">
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              name="password"
                              isValid={touched.password && !errors.password}
                              isInvalid={errors.password && touched.password}
                              value={values.password}
                              onChange={handleChange}
                            />

                            {errors.password && touched.password && (
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            )}
                          </Col>
                        </Form.Group>
                      </Modal.Body>

                      <Modal.Footer className="mb-3">
                        <Button
                          variant="secondary"
                          onClick={this.toggleModal}
                          style={{ marginRight: "12px" }}
                        >
                          Close
                        </Button>

                        <Button variant="primary" type="submit">
                          Sign In
                        </Button>
                      </Modal.Footer>
                    </Modal.Dialog>
                  </form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { signin })(
  withRouter(withCookies(SignUp))
);
