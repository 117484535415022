import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertComponent = ({ variant, show, onClose, message }) => {

    if(show === true) {

        return (

            <Alert variant={variant} onClose={() => onClose()} dismissible={false}>

                <div dangerouslySetInnerHTML={{__html: message}} />
    
            </Alert>
        )

    } else {

        return <></>;

    }

};

export default AlertComponent;
