import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "./../layouts/Index";
import BlockLoader from "./../contentloaders/BlockLoader";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Table,
  Modal,
  Form,
  Button,
  FormControl,
} from "react-bootstrap";
import {
  getUsers,
  addUser,
  getMoreUsers,
  searchUser,
  searchedUsers,
} from "./../../store/actions/UserActions";
import { titleCase } from "./../../utils/index";
import { createSchema } from "./../../helpers/validation/user";
import { toast } from "react-toastify";
import { Formik } from "formik";

class Index extends Component {
  constructor(props) {
    super(props);

    this.user = React.createRef();

    this.state = {
      showModal: false,

      count: 0,

      loading: false,

      msisdn: "",

      email: "",

      searched: false,

      priviledge: 0,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const result = await this.props.getUsers();

    if (result) {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount = () => {};

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  createUser = async (values) => {
    try {
      this.setState({ loading: true });

      if (values.email === "") {
        delete values.email;
      }

      const result = await this.props.addUser(values);

      if (result) {
        toast.success("New User Details Created Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, loading: false });

        await this.props.getUsers();
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined || error.response !== null) {
        if (error.response.status === 409) {
          toast.warning(
            "Specific Field In Your Form Exists In The Database !",
            {
              position: "top-right",
            }
          );
        } else {
          toast.warning(
            "We could not finish your request. Please try again later !",
            {
              position: "top-right",
            }
          );
        }
      }
    }
  };

  fetchMoreUsers = async () => {
    try {
      this.setState({ loading: true });

      const {
        users: { next_page },
      } = this.props;

      const result = await this.props.getMoreUsers(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchUser = async () => {
    try {
      const { msisdn, email } = this.state;

      let obj = {};

      if (msisdn !== "") {
        obj.msisdn = msisdn;
      }

      if (email !== "") {
        obj.email = email;
      }

      this.setState({ loading: true });

      const result = await this.props.searchUser(obj);

      if (result) {
        await this.props.searchedUsers(result);

        this.setState({ loading: false, searched: true });

        toast.success("User Found !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("User Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13) {
        await this.searchUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  refresh = async () => {
    this.setState({ loading: true });

    const result = await this.props.getUsers();

    if (result) {
      this.setState({ loading: false, msisdn: "", email: "", searched: false });
    }
  };

  render() {
    const {
      users,
      users: { page, pages },
    } = this.props;

    const { loading, msisdn, email, searched, priviledge } = this.state;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await this.searchUser();
                  }}
                >
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Phone Number"
                        name="msisdn"
                        value={msisdn}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        value={email}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Button variant="primary" type="submit">
                        Search
                      </Button>
                    </Col>

                    {searched === true && (
                      <Col>
                        <Button
                          variant="primary"
                          type="button"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            this.refresh();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>

              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>User</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="100px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                <Button
                  variant="success"
                  size="sm"
                  onClick={() => this.toggleModal()}
                  disabled={priviledge === 2 || priviledge === 1 ? false : true}
                >
                  New User
                </Button>
              </Col>

              <Col sm="12">
                {!loading && (
                  <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>First Name</th>

                        <th>Last Name</th>

                        <th>Username</th>

                        <th>Email Address</th>

                        <th>Phone Number</th>

                        <th>#</th>
                      </tr>
                    </thead>

                    <tbody>
                      {users.data.map((user, i) => (
                        <tr key={i} ref={this.user}>
                          <td>{i + 1}</td>

                          <td>
                            {user.first_name !== null
                              ? titleCase(user.first_name)
                              : "N/A"}
                          </td>

                          <td>
                            {user.last_name !== null
                              ? titleCase(user.last_name)
                              : "N/A"}
                          </td>

                          <td>{user.username}</td>

                          <td>{user.email}</td>

                          <td>{user.msisdn}</td>

                          <td
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="info"
                              size="sm"
                              as={Link}
                              to={`/admin/users/${user.id}`}
                              style={{ marginRight: "10px", color: "#fff" }}
                            >
                              View
                            </Button>

                            {(priviledge === 2 || priviledge === 1) && (
                              <Button
                                variant="primary"
                                size="sm"
                                as={Link}
                                to={`/admin/users/${user.id}/edit`}
                              >
                                Edit
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                    style={{ marginBottom: "2em" }}
                  />
                )}
              </Col>

              {page < pages && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => this.fetchMoreUsers()}
                    disabled={loading}
                  >
                    {loading === true ? "Loading ..." : "Load More"}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </Col>

        {/* New User Modal */}
        <Modal show={this.state.showModal} onHide={this.toggleModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>New User</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              username: "",
              msisdn: "",
              email: "",
              status: "",
              privilege: "",
              password: "",
              confirm_password: "",
            }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.createUser(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <Modal.Body>
                  <Row>
                    <Form.Group as={Col} sm="6" controlId="first_name">
                      <Form.Label column sm="12">
                        Firstname
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Firstname"
                          name="first_name"
                          isValid={touched.first_name && !errors.first_name}
                          isInvalid={errors.first_name && touched.first_name}
                          value={values.first_name}
                          onChange={handleChange}
                        />

                        {errors.first_name && touched.first_name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.first_name}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Col} sm="6" controlId="last_name">
                      <Form.Label column sm="12">
                        Lastname
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Lastname"
                          name="last_name"
                          isValid={touched.last_name && !errors.last_name}
                          isInvalid={errors.last_name && touched.last_name}
                          value={values.last_name}
                          onChange={handleChange}
                        />

                        {errors.last_name && touched.last_name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.last_name}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </Row>

                  <Form.Group as={Col} sm="12" controlId="username">
                    <Form.Label column sm="12">
                      Username
                    </Form.Label>

                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        isValid={touched.username && !errors.username}
                        isInvalid={errors.username && touched.username}
                        value={values.username}
                        onChange={handleChange}
                      />

                      {errors.username && touched.username && (
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} sm="6" controlId="email">
                      <Form.Label column sm="12">
                        Email
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          name="email"
                          isValid={touched.email && !errors.email}
                          isInvalid={errors.email && touched.email}
                          value={values.email}
                          onChange={handleChange}
                        />

                        {errors.email && touched.email && (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Col} sm="6" controlId="msisdn">
                      <Form.Label column sm="12">
                        Phone Number
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          name="msisdn"
                          isValid={touched.msisdn && !errors.msisdn}
                          isInvalid={errors.msisdn && touched.msisdn}
                          value={values.msisdn}
                          onChange={handleChange}
                        />

                        {errors.msisdn && touched.msisdn && (
                          <Form.Control.Feedback type="invalid">
                            {errors.msisdn}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} sm="6" controlId="privilege">
                      <Form.Label column sm="12">
                        Privilege
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          as="select"
                          isValid={touched.privilege && !errors.privilege}
                          defaultValue={values.privilege}
                          isInvalid={errors.privilege && touched.privilege}
                          name="privilege"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Choose ...</option>

                          <option value={0}>Non Admin</option>

                          {priviledge === 2 && <option value={1}>Admin</option>}
                        </Form.Control>

                        {errors.privilege && touched.privilege && (
                          <Form.Control.Feedback type="invalid">
                            {errors.privilege}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Col} sm="6" controlId="status">
                      <Form.Label column sm="12">
                        Status
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          as="select"
                          isValid={touched.status && !errors.status}
                          defaultValue={values.status}
                          isInvalid={errors.status && touched.status}
                          name="status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Choose ...</option>

                          <option value={0}>Inactive</option>

                          <option value={1}>Active</option>
                        </Form.Control>

                        {errors.status && touched.status && (
                          <Form.Control.Feedback type="invalid">
                            {errors.status}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} sm="6" controlId="password">
                      <Form.Label column sm="12">
                        Password
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Password"
                          name="password"
                          isValid={touched.password && !errors.password}
                          isInvalid={errors.password && touched.password}
                          value={values.password}
                          onChange={handleChange}
                        />

                        {errors.password && touched.password && (
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Col} sm="6" controlId="confirm_password">
                      <Form.Label column sm="12">
                        Confirm Password
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          isValid={
                            touched.confirm_password && !errors.confirm_password
                          }
                          isInvalid={
                            errors.confirm_password && touched.confirm_password
                          }
                          value={values.confirm_password}
                          onChange={handleChange}
                        />

                        {errors.confirm_password &&
                          touched.confirm_password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.confirm_password}
                            </Form.Control.Feedback>
                          )}
                      </Col>
                    </Form.Group>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleModal}>
                    Close
                  </Button>

                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Create User
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  getUsers,
  addUser,
  getMoreUsers,
  searchUser,
  searchedUsers,
})(Index);
