import { instance, setAuthToken } from './../../helpers/instance';
import { PRODUCTS, GET_PRODUCT, FETCH_MORE_PRODUCTS, SEARCHED_PRODUCTS } from './../types/index';

export const getAllProducts = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('products?paginate=false');

        if(response.data !== "" && response.data.products !== undefined && response.data.pages !== undefined) {

            let payload = {};

            payload.data = response.data.products;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = null;

            dispatch({ type: PRODUCTS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getProducts = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('products');

        if(response.data !== "" && response.data.products !== undefined && response.data.pages !== undefined) {

            // console.log(response.data.slice(0, 10));

            let payload = {};

            payload.data = response.data.products;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: PRODUCTS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreProducts = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`products?page=${next_page}`);

        if(!response.data.hasOwnProperty('products')) {

            return response.data;

        };

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.products;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_PRODUCTS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getProduct = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`products/${id}`);

        if(response.data !== "") {

            // console.log(response.data);

            dispatch({ type: GET_PRODUCT, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const clearProduct = (id) => async dispatch => dispatch({ type: GET_PRODUCT, payload: {} })

export const createProduct = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`products`, data);

        if(response.data !== "") {

            // console.log(response.data);

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editProduct = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`products/${id}`, data);

        if(response.data !== "") {

            // console.log(response.data);

            // dispatch({ type: GET_PRODUCT, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const deleteProduct = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.delete(`products/${id}`);

        if(response.data !== "") {

            // console.log(response.data);

            // dispatch({ type: GET_PRODUCT, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchProduct = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/products`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchedProducts = (products) => async dispatch => {

    try {

        // console.log(products);

        let data = [];

        for(let i = 0; i < products.length; i++) {

            setAuthToken(instance);

            const response = await instance.get(`products/${products[i].id}`);

            if(response.data !== "" && response.data.name !== undefined) {

                data.push(response.data);

            }

        }

        if(data.length > 0) {

            dispatch({ type: SEARCHED_PRODUCTS, payload: data });

        }

        return data;
        
    } catch (error) {

        throw(error);
        
    }

};


