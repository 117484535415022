import { Redirect, Route } from 'react-router-dom';
import React from 'react';

const expiry = localStorage.getItem('ltdn_exp');

const currentTime = Date.now() / 1000;

const isAuthenticated = expiry > currentTime;

const PrivateRoute = ({ component: Component, ...rest }) => {
  
    return(

        <Route {...rest} render = {props => {

            // Check If User Is Authenticated
            if(expiry === null || isAuthenticated === false) {

                return <Redirect to="/" />

            }

            // Rendering The Required Component
            return <Component {...props} />

        }}/>

    )

};

export default PrivateRoute;