import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import { addOrder } from "./../../store/actions/OrderActions";
import { getMoqs } from "./../../store/actions/MoqActions";
import { getSuppliers } from "./../../store/actions/SupplierActions";
import { createSchema } from "./../../helpers/validation/order";
import { toast } from "react-toastify";
import { Formik } from "formik";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moq_id: 0,
    };
  }

  componentDidMount = async () => {
    let moq_id =
      localStorage.getItem("moq_id") !== null
        ? parseInt(localStorage.getItem("moq_id"))
        : 0;

    this.setState({ moq_id });

    await this.props.getMoqs();

    await this.props.getSuppliers();
  };

  componentWillUnmount = async () => {
    localStorage.removeItem("moq_id");
  };

  createOrder = async (values) => {
    try {
      const result = await this.props.addOrder(values);

      if (result) {
        toast.success("New Order Details Created Successfully !", {
          position: "top-right",
        });

        // this.setState({ showModal: false });

        // await this.props.getOrders();

        return this.props.history.push("/admin/orders");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { suppliers, moqs } = this.props;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col sm="12">
                <h1>Make Order</h1>
              </Col>

              <Col sm="12">
                <Card>
                  <Card.Body>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        moq_id: "",
                        supplier_id: "",
                        status: "",
                      }}
                      validationSchema={createSchema}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        this.createOrder(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form
                          noValidate
                          onSubmit={(e) => {
                            e.preventDefault();

                            // console.log('Form Submitted !');

                            handleSubmit();
                          }}
                        >
                          <Form.Group as={Row} controlId="moq_id">
                            <Form.Label column sm="12">
                              Moq
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={touched.moq_id && !errors.moq_id}
                                isInvalid={errors.moq_id && touched.moq_id}
                                name="moq_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.moq_id}
                              >
                                <option value="">Choose ...</option>

                                {moqs.data.map((moq, i) => (
                                  <option
                                    key={i}
                                    value={moq.id}
                                    selected={moq.id === this.state.moq_id}
                                  >
                                    {moq.moq_number}
                                  </option>
                                ))}
                              </Form.Control>

                              {errors.moq_id && touched.moq_id && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.moq_id}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="supplier">
                            <Form.Label column sm="12">
                              Supplier
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={
                                  touched.supplier_id && !errors.supplier_id
                                }
                                isInvalid={
                                  errors.supplier_id && touched.supplier_id
                                }
                                name="supplier_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.supplier_id}
                              >
                                <option value="">Choose ...</option>

                                {suppliers.map((supplier, i) => (
                                  <option key={i} value={supplier.id}>
                                    {supplier.name}
                                  </option>
                                ))}
                              </Form.Control>

                              {errors.supplier_id && touched.supplier_id && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.supplier_id}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="status">
                            <Form.Label column sm="12">
                              Status
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={touched.status && !errors.status}
                                defaultValue={values.status}
                                isInvalid={errors.status && touched.status}
                                name="status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="">Choose...</option>

                                <option value="placed">Placed</option>

                                <option value="shipping">Shipping</option>

                                <option value="arrived">Arrived</option>

                                <option value="collection">Collection</option>
                              </Form.Control>

                              {errors.status && touched.status && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.status}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Button
                            variant="primary"
                            style={{ float: "right" }}
                            type="submit"
                          >
                            Create Order
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Col>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orders: state.orders,

    suppliers: state.suppliers,

    moqs: state.moqs,
  };
};

export default connect(mapStateToProps, { addOrder, getMoqs, getSuppliers })(
  withRouter(Create)
);
