import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./../layouts/Index";
import BlockLoader from "./../contentloaders/BlockLoader";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  Form,
  FormControl,
  Accordion,
} from "react-bootstrap";
import {
  getProducts,
  deleteProduct,
  getMoreProducts,
  searchProduct,
  searchedProducts,
} from "./../../store/actions/ProductActions";
// import truncate from 'truncate-txt';
import { titleCase } from "./../../utils/index";
import ProductAccordionItem from "./../common/ProductAccordionItem";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoDialog: false,

      product: {},

      loading: false,

      search: "",

      searched: false,

      priviledge: 0,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const res = await this.props.getProducts();

    if (res) {
      this.setState({ loading: false });
    }
  };

  togglePhotosDialog = (product = null) => {
    if (product !== null) {
      this.setState({ photoDialog: !this.state.photoDialog, product });
    } else {
      this.setState({ photoDialog: !this.state.photoDialog, product: {} });
    }
  };

  delete = async () => {
    try {
      const { product } = this.state;

      const result = await this.props.deleteProduct(product.id);

      if (result) {
        toast.success("Product Deleted !", {
          position: "top-right",
        });

        await this.props.getProducts();

        this.setState({ photoDialog: !this.state.photoDialog, product: {} });
      }
    } catch (error) {
      console.log(error);

      this.setState({ photoDialog: !this.state.photoDialog, product: {} });
    }
  };

  fetchMoreProducts = async () => {
    try {
      this.setState({ loading: true });

      const {
        products: { next_page },
      } = this.props;

      const result = await this.props.getMoreProducts(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchProduct = async () => {
    try {
      let obj = { name: this.state.search };

      this.setState({ loading: true });

      const result = await this.props.searchProduct(obj);

      if (result) {
        await this.props.searchedProducts(result);

        this.setState({ loading: false, searched: true });

        toast.success("Product Found !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("Product Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13) {
        await this.searchProduct();
      }
    } catch (error) {
      // console.log(error);

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("Product Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  refresh = async () => {
    this.setState({ loading: true });

    const result = await this.props.getProducts();

    if (result) {
      this.setState({ loading: false, search: "", searched: false });
    }
  };

  render() {
    const {
      products,
      history,
      products: { page, pages },
      search,
    } = this.props;

    const { photoDialog, product, loading, searched, priviledge } = this.state;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await this.searchProduct();
                  }}
                >
                  <Row>
                    <Col md="auto">
                      <FormControl
                        type="text"
                        placeholder="Product Name"
                        value={search}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Button variant="outline-success" type="submit">
                        Search
                      </Button>
                    </Col>
                    {searched === true && (
                      <Col>
                        <Button
                          variant="outline-success"
                          type="button"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            this.refresh();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>

              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Products</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="100px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                {
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => {
                      return history.push("/admin/products/create");
                    }}
                  >
                    New Product
                  </Button>
                }
              </Col>

              <Col sm="12">
                {!loading && (
                  <Accordion defaultActiveKey="0">
                    {products.data.map((p, i) => (
                      <ProductAccordionItem
                        key={i}
                        product={p}
                        priviledge={priviledge}
                      />
                    ))}
                  </Accordion>
                )}
              </Col>
              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                  />
                )}
              </Col>

              {page < pages && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => this.fetchMoreProducts()}
                    disabled={loading}
                  >
                    {loading === true ? "Loading ..." : "Load More"}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </Col>

        <Modal show={photoDialog} onHide={this.togglePhotosDialog}>
          <Modal.Header closeButton>
            <Modal.Title>
              {product.name !== undefined && titleCase(product.name)}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h6>Are you sure you want to delete {product.name} ?</h6>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.togglePhotosDialog}>
              Close
            </Button>

            <Button
              variant="danger"
              onClick={() => {
                this.delete();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products,
  };
};

export default connect(mapStateToProps, {
  getProducts,
  deleteProduct,
  getMoreProducts,
  searchProduct,
  searchedProducts,
})(withRouter(Index));
