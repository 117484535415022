import React, { useEffect, useState } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Row, Col, Container, Card, Button, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import { Formik } from "formik";
import { createSchema } from "./../../helpers/validation/promocode";
import { getCategories } from "./../../store/actions/CategoryActions";
import { searchMoq } from "./../../store/actions/MoqActions";
import { instance, setAuthToken } from "../../helpers/instance";
import { toast } from "react-toastify";

function Index(props) {
  // Props
  const { categories, moqs } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    fetchCategories();
  }, []);

  // Functions
  const fetchCategories = async () => {
    try {
      await props.getCategories();
    } catch (error) {
      console.log(error);
    }
  };

  const create_promo_code = async (data) => {
    try {
      const {
        promo_name,
        category_id_list,
        moq_list,
        customer_msisdn_list,
        start_date,
        end_date,
        discount,
        status,
      } = data;

      // Validation
      // if (category_id_list?.length === 0) {
      //   return toast.error(`Please select atleast one category !`, {
      //     position: "top-right",
      //   });
      // }
      if (moq_list?.length === 0) {
        return toast.error(`Please select atleast one product !`, {
          position: "top-right",
        });
      }
      // if (customer_msisdn_list?.length === 0) {
      //   return toast.error(`Please select atleast one phone number !`, {
      //     position: "top-right",
      //   });
      // }

      let obj = {
        promo_name,
        discount,
        category_id_list:
          category_id_list?.length > 0
            ? category_id_list?.map((item) => `${item?.value}`)
            : [],
        moq_list: moq_list?.map((item) => item?.value),
        customer_msisdn_list:
          customer_msisdn_list?.length > 0
            ? customer_msisdn_list?.map((item) => item?.value)
            : [],
        start_date,
        end_date,
        status,
      };
      setAuthToken(instance);
      setLoading(true);
      const response = await instance.post("promo_code", obj);
      if (response?.data) {
        setLoading(false);
        toast.success(`Promo code created successfully !`, {
          position: "top-right",
        });
        return props.history.push(`/admin/promo-codes`);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-right",
        });
      }
    }
  };

  const loadOptions = (inputValue, callback) => {
    setAuthToken(instance);
    instance
      .post(`search/moqs`, { product_name: inputValue })
      .then((response) => {
        // Transform the response data to the format react-select expects
        const options = response?.data?.map((item) => ({
          value: item?.moq_number,
          label: item?.product[0]?.name,
        }));
        // Pass the options to the callback
        callback(options);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        callback([]);
      });
  };

  return (
    <Layout>
      <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
        <Container>
          <Row>
            <Col sm="12">
              <h3>Create Promo Code</h3>
            </Col>
            <Col sm="12">
              <Card>
                <Card.Body>
                  <Formik
                    initialValues={{
                      promo_name: "",
                      discount: "",
                      category_id_list: [],
                      moq_list: [],
                      start_date: "",
                      end_date: "",
                      customer_msisdn_list: [],
                      status: 0,
                    }}
                    validationSchema={createSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      create_promo_code(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <Form
                        noValidate
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      >
                        <Row>
                          <Col sm="6">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Promo Name</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="Promo Name"
                                name="promo_name"
                                isValid={
                                  touched.promo_name && !errors.promo_name
                                }
                                isInvalid={
                                  errors.promo_name && touched.promo_name
                                }
                                value={values.promo_name}
                                onChange={(e) => {
                                  let txt = e.target.value;
                                  setFieldValue("promo_name", txt);
                                }}
                              />

                              {errors.promo_name && touched.promo_name && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.promo_name}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="category_id_list"
                            >
                              <Form.Label>Categories</Form.Label>
                              <Select
                                name="category_id_list"
                                defaultValue={values.category_id_list}
                                options={categories?.data?.map((category) => ({
                                  value: category?.id,
                                  label: category?.name,
                                }))}
                                isMulti
                                onChange={(value) => {
                                  setFieldValue("category_id_list", value);
                                }}
                                onBlur={() => {
                                  setFieldTouched("category_id_list", true);
                                }}
                                touched={touched.category_id_list}
                              />

                              {errors.category_id_list &&
                                touched.category_id_list && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.category_id_list}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </Col>

                          <Col sm="12">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Discount (%)</Form.Label>

                              <Form.Control
                                type="number"
                                placeholder="Discount (%)"
                                name="discount"
                                min={1}
                                max={100}
                                isValid={touched.discount && !errors.discount}
                                isInvalid={errors.discount && touched.discount}
                                value={values.discount}
                                onChange={(e) => {
                                  let txt = e.target.value;
                                  if (txt >= 0 && txt <= 100) {
                                    setFieldValue("discount", txt);
                                  }
                                }}
                              />

                              {errors.discount && touched.discount && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.discount}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="moq_list"
                            >
                              <Form.Label>Products</Form.Label>
                              <AsyncSelect
                                name="moq_list"
                                cacheOptions
                                defaultOptions
                                defaultValue={values.moq_list}
                                loadOptions={loadOptions}
                                isMulti
                                onChange={(value) => {
                                  setFieldValue("moq_list", value);
                                }}
                                onBlur={() => {
                                  setFieldTouched("moq_list", true);
                                }}
                                touched={touched.moq_list}
                              />

                              {errors.moq_list && touched.moq_list && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.moq_list}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="customer_msisdn_list"
                            >
                              <Form.Label>Customer Phone Numbers</Form.Label>
                              <CreatableSelect
                                name="customer_msisdn_list"
                                defaultValue={values.customer_msisdn_list}
                                options={[]}
                                isMulti
                                onChange={(value) => {
                                  setFieldValue("customer_msisdn_list", value);
                                }}
                                onBlur={() => {
                                  setFieldTouched("customer_msisdn_list", true);
                                }}
                                touched={touched.customer_msisdn_list}
                              />

                              {errors.customer_msisdn_list &&
                                touched.customer_msisdn_list && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.customer_msisdn_list}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Start Date</Form.Label>

                              <Form.Control
                                type="datetime-local"
                                placeholder="Start Date"
                                name="start_date"
                                isValid={
                                  touched.start_date && !errors.start_date
                                }
                                isInvalid={
                                  errors.start_date && touched.start_date
                                }
                                value={values.start_date}
                                onChange={(e) => {
                                  let txt = e.target.value;
                                  setFieldValue("start_date", txt);
                                }}
                              />

                              {errors.promo_name && touched.promo_name && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.promo_name}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>End Date</Form.Label>

                              <Form.Control
                                type="datetime-local"
                                placeholder="End Date"
                                name="end_date"
                                isValid={touched.end_date && !errors.end_date}
                                isInvalid={errors.end_date && touched.end_date}
                                value={values.end_date}
                                onChange={(e) => {
                                  let txt = e.target.value;
                                  setFieldValue("end_date", txt);
                                }}
                              />

                              {errors.promo_name && touched.promo_name && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.promo_name}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="status"
                            >
                              <Form.Label>Status</Form.Label>

                              <Form.Control
                                as="select"
                                defaultValue={values.status}
                                isValid={touched.status && !errors.status}
                                isInvalid={errors.status && touched.status}
                                name="status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value={1}>Active</option>

                                <option value={0}>Not Active</option>
                              </Form.Control>

                              {errors.status && touched.status && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.status}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col
                            sm="12"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              disabled={loading}
                              style={{ marginTop: "1em" }}
                              variant="primary"
                              type="submit"
                            >
                              Create Promo Code
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Col>
    </Layout>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories,
    moqs: state.moqs,
  };
};

export default connect(mapStateToProps, { getCategories, searchMoq })(
  withRouter(Index)
);
