import { STATISTICS } from "./../types/index";

const initialState = {
  data: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATISTICS:
      return {
        ...state,

        data: action.payload,
      };
    default:
      return state;
  }
}
