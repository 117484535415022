import { instance, setAuthToken } from '../../helpers/instance';
import { TRANSACTIONS, FETCH_MORE_TRANSACTIONS, GET_TRANSACTION, SEARCHED_TRANSACTIONS } from '../types/index';

export const getTransactions = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('transactions');

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.transactions;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: TRANSACTIONS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreTransactions = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`transactions?page=${next_page}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.transactions;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_TRANSACTIONS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const createPoints = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post('transactions/points', data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchTransactions = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/transactions`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showTransaction = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`transactions/${id}`);

        if(response.data !== "") {

            // console.log(response.data);

            dispatch({ type: GET_TRANSACTION, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchedTransactions = (transactions) => async dispatch => {

    try {

        // console.log(transactions);

        let data = [];

        for(let i = 0; i < transactions.length; i++) {

            setAuthToken(instance);

            const response = await instance.get(`transactions/${transactions[i].id}`);

            // console.log(response.data);

            if(response.data !== "") {

                data.push(response.data);

            }

        }

        // console.log(data);

        dispatch({ type: SEARCHED_TRANSACTIONS, payload: data });

        return data;
        
    } catch (error) {

        throw(error);
        
    }

};