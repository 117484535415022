import * as yup from 'yup';

export const createSchema = yup.object().shape({

    msisdn: yup.string().required('Phone Number is required'),

    moq_id: yup.string().nullable().required('Product is required'),
    
    quantity: yup.number().required(), 
    
    shipping: yup.string().required()

});

export const statusSchema = yup.object().shape({
    
    status: yup.number().required(),

    total_shipping: yup.number()

});