import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Row, Col, Container, ListGroup, Table } from "react-bootstrap";
import { showUser } from "./../../store/actions/UserActions";
import { instance } from "./../../helpers/instance";
import BlockLoader from "./../contentloaders/BlockLoader";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      monthly_affiliate_earnings: [],
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;

    this.setState({ loading: true });

    const result = await this.props.showUser(params.id);

    if (result) {
      this.setState({ loading: false });
      this.fetchAffiliate(result?.affiliate_code);
    }
  };

  fetchAffiliate = async (code) => {
    try {
      this.setState({ loading: true });
      let result = await instance.get(`affiliates_tracking/${code}`);
      if (result?.data) {
        this.setState({ monthly_affiliate_earnings: result?.data || [] });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  render() {
    const {
      users: { user },
    } = this.props;

    const { loading, monthly_affiliate_earnings } = this.state;

    let earnings = [...monthly_affiliate_earnings].reverse() || [];

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              {
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  <h3>User Details</h3>
                </Col>
              }
              {loading && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                    style={{ marginBottom: "2em" }}
                  />
                </Col>
              )}

              {!loading && (
                <Col sm="12">
                  <ListGroup>
                    <ListGroup.Item style={{ display: "inline-block" }}>
                      <span style={{ fontWeight: "bold" }}>Firstname: </span>{" "}
                      <span>{user.first_name}</span>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <span style={{ fontWeight: "bold" }}>Lastname: </span>{" "}
                      <span>{user.last_name}</span>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <span style={{ fontWeight: "bold" }}>Phone Number: </span>{" "}
                      <span>
                        {user.msisdn !== undefined ? user.msisdn : "N/A"}
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <span style={{ fontWeight: "bold" }}>Username: </span>
                      <span>
                        {user.username !== undefined ? user.username : "N/A"}
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <span style={{ fontWeight: "bold" }}>
                        Email Address:{" "}
                      </span>
                      <span>
                        {user.email !== undefined ? user.email : "N/A"}
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              )}

              {!loading && user.bids !== undefined && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                    marginTop: "1em",
                  }}
                >
                  <h3>User Bids</h3>
                </Col>
              )}

              <Col sm="12">
                {!loading && user.bids !== undefined && (
                  <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>Product</th>

                        <th>MOQ</th>

                        <th>Bid Number</th>

                        {/* <th>Bid Attributes</th> */}

                        <th>Quantity</th>

                        <th>Amount</th>

                        <th>Total Shipping Fee</th>

                        <th>Status</th>

                        <th>Delivery Status</th>

                        <th>Collected</th>
                      </tr>
                    </thead>

                    <tbody>
                      {user.bids.map((bid, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>
                            <Link to={`/admin/products/${bid.product[0].id}`}>
                              {bid.product[0].name}
                            </Link>
                          </td>

                          <td>
                            <Link to={`/admin/moqs/${bid.moq_id}`}>View</Link>
                          </td>

                          <td>
                            <Link to={`/admin/bids/${bid.id}`}>
                              {bid.bid_number}
                            </Link>
                          </td>

                          {/* <td>

                                                <ul>

                                                    {bid.bid_attributes.map((attribute, i) => {

                                                        if(attribute !== undefined || attribute.colors !== undefined || attribute.sizes !== undefined) {

                                                            return <li key={i}>{attribute.quantity} {attribute.colors !== undefined ? attribute.colors.label : ''} {attribute.sizes !== undefined ? `size ${attribute.sizes.label}` : ''}</li>

                                                        }else{

                                                            return null;

                                                        }

                                                    })}

                                                </ul>

                                            </td> */}

                          <td>{bid.quantity}</td>

                          <td>{bid.amount}</td>

                          <td>
                            {bid.total_shipping === null
                              ? "N/A"
                              : bid.total_shipping}
                          </td>

                          <td>
                            {bid.status === 0 && (
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "3px 10px",
                                  borderRadius: "5px",
                                }}
                              >
                                Unpaid
                              </span>
                            )}

                            {bid.status === 1 && (
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "3px 10px",
                                  borderRadius: "5px",
                                }}
                              >
                                Paid
                              </span>
                            )}

                            {bid.status === 2 && "Collected"}
                          </td>

                          <td>
                            {bid && bid?.delivery_status === 0
                              ? "Undelivered"
                              : "Delivered"}
                          </td>

                          <td>
                            {bid.is_collected === 0
                              ? "Not Collected"
                              : "Collected"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              {!loading && earnings?.length > 0 && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                    marginTop: "1em",
                  }}
                >
                  <h3>Monthly Affiliates Earnings</h3>
                </Col>
              )}
              {!loading && earnings?.length > 0 && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                    marginTop: "1em",
                    height: "350px",
                  }}
                >
                  <Bar
                    style={{ width: "100%" }}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: "Earnings in KES",
                          },
                        },
                        x: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: "Month of the year (last 12 months)",
                          },
                          offset: true,
                          grid: {
                            offset: true,
                          },
                          ticks: {
                            align: "center",
                          },
                          barPercentage: 0.1,
                          categoryPercentage: 0,
                        },
                      },
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (tooltipItem, data) => {
                              // Access the index of the hovered bar
                              const index = tooltipItem.dataIndex;
                              // Access the data and index of the current bar
                              const earning = earnings[index] || null;

                              if (earning?.status === 1)
                                return `${Math.trunc(earning?.sum)} (paid out)`;
                              return `${Math.trunc(
                                earning?.sum
                              )} (not paid out)`;
                            },
                          },
                        },
                        legend: {
                          position: "top",
                          display: false,
                        },
                        title: {
                          display: false,
                          text: "",
                        },
                      },
                    }}
                    data={{
                      labels: [
                        ...earnings?.map(
                          (a) => `${a?.month_created}, ${a?.year_created}`
                        ),
                      ],
                      datasets: [
                        {
                          label: "",
                          data: [...earnings?.map((a) => a?.sum)],
                          backgroundColor: (context) => {
                            // Access the data and index of the current bar
                            const value = earnings[context.dataIndex]?.status;
                            // Set background color based on your condition (status)
                            return value === 1 ? "green" : "orange";
                          },
                        },
                      ],
                    }}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Col>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps, { showUser })(Show);
