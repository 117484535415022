import { USERS, GET_USER, FETCH_MORE_USERS, SEARCHED_USERS } from './../types/index';

const initialState = {

    data: [],

    user: {},

    pages: null,

    page: 1,

    next_page: null

};

export default function(state = initialState, action) {

    switch(action.type) {

        case USERS: 

            return {

                ...state,

                data: action.payload.data,

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case FETCH_MORE_USERS: 

            return {

                ...state,

                data: state.data.concat(action.payload.data),

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case GET_USER: 

            return {

                ...state,

                user: action.payload

            };

        case SEARCHED_USERS: 

            return {

                ...state,

                data: action.payload,

                pages: null,

                page: 1,

                next_page: null

            };

        default:

            return state;

    }

};