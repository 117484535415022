import { HOME_PROMOTIONS } from '../types/index';

const initialState = {

    data: [],

    pages: null,

    page: 1,

    next_page: null

};

export default function(state = initialState, action) {

    switch(action.type) {

        case HOME_PROMOTIONS: 

            return {

                ...state,

                data: action.payload.data,

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        default:

            return state;

    }

};