import { instance, setAuthToken } from '../../helpers/instance';
import { RECOMMENDATIONS, FETCH_MORE_RECOMMENDATIONS, UPDATE_RECOMMENDATION } from '../types/index';

export const getRecommendations = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('recommendations');

        if(response.data !== "" && response.data.recommendations !== undefined && response.data.pages !== undefined) {

            let payload = {};

            payload.data = response.data.recommendations;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: RECOMMENDATIONS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreRecommendations = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`recommendations?page=${next_page}`);

        if(!response.data.hasOwnProperty('recommendations')) {

            return response.data;

        };

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.recommendations;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_RECOMMENDATIONS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const updateRecommendation = (id, payload) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`recommendations/${id}`, payload);

        if(response?.data) {

            dispatch({ type: UPDATE_RECOMMENDATION, payload: response.data });

        }

        return response;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};