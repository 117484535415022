import { instance, setAuthToken } from './../../helpers/instance';
import { USERS, GET_USER, FETCH_MORE_USERS, SEARCHED_USERS } from './../types/index';

// Fetching All User's
export const getUsers = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('users?page=1');

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.users;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: USERS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreUsers = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`users?page=${next_page}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.users;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_USERS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showUser = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`users/${id}`);

        if(response.data !== "") {

            // console.log(response.data);

            dispatch({ type: GET_USER, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const clearUser = () => dispatch => dispatch({ type: GET_USER, payload: {} });

export const addUser = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post('users', data);

        // console.log(response);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editUser = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`users/${id}`, data);

        // console.log(response);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchUser = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/users`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchedUsers = (users) => async dispatch => {

    try {

        // console.log(users);

        let data = [];

        for(let i = 0; i < users.length; i++) {

            setAuthToken(instance);

            const response = await instance.get(`users/${users[i].id}`);

            // console.log(response.data);

            if(response.data !== "") {

                data.push(response.data);

            }

        }

        // console.log(data);

        dispatch({ type: SEARCHED_USERS, payload: data });

        return data;
        
    } catch (error) {

        throw(error);
        
    }

};