import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import { addMoq } from "./../../store/actions/MoqActions";
import { getAllProducts } from "./../../store/actions/ProductActions";
import { createSchema } from "./../../helpers/validation/moq";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import Select from "react-select";
import CurrencyCodes from "currency-codes/data";
import BlockLoader from "./../contentloaders/BlockLoader";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      fetching: false,

      products: [],

      varying_sizes: 0,

      moq_attributes: [
        {
          size_id: 0,
          supplier_price: "",
          price: "",
          shipping_fee_air: "",
          shipping_fee_sea: "",
          weight: "",
          below_moq_price: "",
        },
      ],

      sizes: [],
    };
  }

  componentDidMount = async () => {
    this.fetchProducts();
  };

  fetchProducts = async () => {
    try {
      this.setState({ fetching: true });
      const results = await this.props.getAllProducts();
      if (results?.products) {
        let data = [];
        for (let i = 0; i < results.products.length; i++) {
          data.push({
            value: results.products[i].id,
            label: results.products[i].name,
            varying_sizes: results.products[i].varying_sizes,
            sizes: results.products[i].readable_sizes,
          });
        }
        this.setState({ products: data });
      }
      this.setState({ fetching: false });
    } catch (error) {
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  create = async (data) => {
    try {
      let values = data;
      if (values.product_id === null || values.product_id === undefined) {
        return toast.error("Product is required !", {
          position: "top-right",
        });
      }

      if (values.weight === "" && this.state.varying_sizes === 0) {
        return toast.error("Weight is required !", {
          position: "top-right",
        });
      }

      if (values.below_moq_price === "" && this.state.varying_sizes === 0) {
        return toast.error("Below MOQ Price is required !", {
          position: "top-right",
        });
      }
      if (this.state.varying_sizes === 1) {
        let attributes = this.state.moq_attributes;

        let hasSupplierPrice = attributes.every(
          (attr) => attr?.supplier_price !== "" && attr?.supplier_price !== 0
        );
        if (!hasSupplierPrice) {
          return toast.error(
            "Supplier price(s) in MOQ attributes is required and should not have zero values !",
            {
              position: "top-right",
            }
          );
        }

        let hasPrice = attributes.every(
          (attr) => attr?.price !== "" && attr?.price !== 0
        );
        if (!hasPrice) {
          return toast.error(
            "Price(s) in MOQ attributes is required and should not have zero values !",
            {
              position: "top-right",
            }
          );
        }

        let hasBelowMOQPrice = attributes.every(
          (attr) => attr?.below_moq_price !== "" && attr?.below_moq_price !== 0
        );
        if (!hasBelowMOQPrice) {
          return toast.error(
            "Below MOQ Price(s) in MOQ attributes is required and should not have zero values !",
            {
              position: "top-right",
            }
          );
        }

        let hasWeight = attributes.every((attr) => attr?.weight !== "");
        if (!hasWeight) {
          return toast.error("Weight(s) in MOQ attributes is required !", {
            position: "top-right",
          });
        }

        let hasSize = attributes.every(
          (attr) => attr?.size_id !== "" && attr?.size_id !== 0
        );
        if (!hasSize) {
          return toast.error(
            "Please select appropriate size(s) in MOQ attributes !",
            {
              position: "top-right",
            }
          );
        }

        values.moq_attributes = this.state.moq_attributes;
      } else {
        if (values.supplier_price === "" || values.supplier_price === 0) {
          return toast.error(
            "Supplier Price is required and should not have zero value !",
            {
              position: "top-right",
            }
          );
        }

        if (values.price === "" || values.price === 0) {
          return toast.error(
            "Price is required and must not have zero value !",
            {
              position: "top-right",
            }
          );
        }

        if (values.weight === "") {
          return toast.error("Weight is required !", {
            position: "top-right",
          });
        }

        if (values.below_moq_price === "") {
          return toast.error("Below MOQ Price is required !", {
            position: "top-right",
          });
        }

        values.moq_attributes = [
          {
            shipping_fee_air: values.shipping_fee_air,
            shipping_fee_sea: values.shipping_fee_sea,
            weight: values.weight,
          },
        ];

        if (values.shipping_fee_air) delete values.shipping_fee_air;

        if (values.shipping_fee_sea) delete values.shipping_fee_sea;

        if (values.weight) delete values.weight;
      }

      this.setState({ loading: true });

      const result = await this.props.addMoq({
        ...values,
        product_id: values?.product_id?.value || null,
      });

      if (result) {
        this.setState({ loading: false });

        toast.success("MOQ Added Successfully !", {
          position: "top-right",
        });

        return this.props.history.push(`/admin/moqs`);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-right",
        });
      }
    }
  };

  handleInputChange = (e, index) => {
    const { moq_attributes } = this.state;

    const { name, value } = e.target;

    let attributes = [...moq_attributes];

    attributes[index][name] = parseInt(value);

    this.setState({ moq_attributes: attributes });
  };

  handleRemoveClick = (index) => {
    const { moq_attributes } = this.state;

    let list = [...moq_attributes];

    list.splice(index, 1);

    this.setState({ moq_attributes: list });
  };

  handleAddClick = () => {
    let { moq_attributes } = this.state;
    this.setState({
      moq_attributes: [
        ...moq_attributes,
        {
          size_id: 0,
          supplier_price: "",
          price: "",
          shipping_fee_air: "",
          shipping_fee_sea: "",
          weight: "",
          below_moq_price: "",
        },
      ],
    });
  };

  render() {
    const { loading, varying_sizes, moq_attributes, fetching } = this.state;
    return (
      <Layout>
        <Container>
          <Row>
            {!loading && (
              <Col sm="12">
                <h4>Create New Campaign</h4>
              </Col>
            )}
            <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
              {fetching ? (
                <BlockLoader
                  width="100%"
                  height="400px"
                  innerWidth="100%"
                  innerHeight="100%"
                />
              ) : (
                <Row>
                  {
                    <Col sm="12">
                      <Card>
                        <Card.Body>
                          <Formik
                            initialValues={{
                              product_id: null,
                              quantity: "",
                              min_quantity: "",
                              supplier_price: "",
                              price: 0,
                              start_date: "",
                              end_date: "",
                              shipping_fee_air: "",
                              shipping_fee_sea: "",
                              supplier_currency: "",
                              source: "",
                              below_moq_price: "",
                              weight: "",
                            }}
                            validationSchema={createSchema}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              if (varying_sizes === 1) {
                                delete values.supplier_price;

                                delete values.price;

                                delete values.shipping_fee_air;

                                delete values.shipping_fee_sea;

                                delete values.weight;

                                delete values.below_moq_price;
                              }

                              this.create(values);
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldTouched,
                              setFieldValue,
                            }) => (
                              <Form
                                noValidate
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                              >
                                <Row>
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="product_id"
                                    >
                                      <Form.Label>Product</Form.Label>
                                      <Select
                                        defaultValue={values.product_id}
                                        value={values.product_id}
                                        options={this.state.products}
                                        onChange={(value) => {
                                          this.setState({
                                            varying_sizes: value.varying_sizes,
                                            sizes: value.sizes,
                                          });
                                          console.log(value);
                                          setFieldValue("product_id", value);
                                        }}
                                        onBlur={() => {
                                          setFieldTouched("product_id", true);
                                        }}
                                        touched={touched.product_id}
                                      />

                                      {(errors.product_id ||
                                        touched.product_id) && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.product_id}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="source"
                                    >
                                      <Form.Label>Source</Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Source"
                                        name="source"
                                        isValid={
                                          touched.source && !errors.source
                                        }
                                        isInvalid={
                                          errors.source && touched.source
                                        }
                                        value={values.source}
                                        onChange={handleChange}
                                      />

                                      {errors.source && touched.source && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.source}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId=""
                                    >
                                      <Form.Label>Supplier MOQ</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="Supplier MOQ"
                                        name="quantity"
                                        isValid={
                                          touched.quantity && !errors.quantity
                                        }
                                        isInvalid={
                                          errors.quantity && touched.quantity
                                        }
                                        value={values.quantity}
                                        onChange={handleChange}
                                      />

                                      {errors.quantity && touched.quantity && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.quantity}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId=""
                                    >
                                      <Form.Label>MOQ Per Person</Form.Label>

                                      <Form.Control
                                        type="number"
                                        placeholder="MOQ Per Person"
                                        name="min_quantity"
                                        isValid={
                                          touched.min_quantity &&
                                          !errors.min_quantity
                                        }
                                        isInvalid={
                                          errors.min_quantity &&
                                          touched.min_quantity
                                        }
                                        value={values.min_quantity}
                                        onChange={handleChange}
                                      />

                                      {errors.min_quantity &&
                                        touched.min_quantity && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.min_quantity}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm="6">
                                    {varying_sizes !== 1 && (
                                      <Form.Group
                                        controlId=""
                                        style={{ marginTop: "1em" }}
                                      >
                                        <Form.Label>
                                          Approximate Shipping Fee Air
                                        </Form.Label>

                                        <Form.Control
                                          type="number"
                                          placeholder="Shipping Fee Air"
                                          name="shipping_fee_air"
                                          isValid={
                                            touched.shipping_fee_air &&
                                            !errors.shipping_fee_air
                                          }
                                          isInvalid={
                                            errors.shipping_fee_air &&
                                            touched.shipping_fee_air
                                          }
                                          value={values.shipping_fee_air}
                                          onChange={handleChange}
                                        />

                                        {errors.shipping_fee_air &&
                                          touched.shipping_fee_air && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.shipping_fee_air}
                                            </Form.Control.Feedback>
                                          )}
                                      </Form.Group>
                                    )}
                                  </Col>
                                  <Col sm="6">
                                    {varying_sizes !== 1 && (
                                      <Form.Group
                                        style={{ marginTop: "1em" }}
                                        controlId=""
                                      >
                                        <Form.Label>
                                          Approximate Shipping Fee Sea
                                        </Form.Label>

                                        <Form.Control
                                          type="number"
                                          placeholder="Shipping Fee Sea"
                                          name="shipping_fee_sea"
                                          isValid={
                                            touched.shipping_fee_sea &&
                                            !errors.shipping_fee_sea
                                          }
                                          isInvalid={
                                            errors.shipping_fee_sea &&
                                            touched.shipping_fee_sea
                                          }
                                          value={values.shipping_fee_sea}
                                          onChange={handleChange}
                                        />

                                        {errors.shipping_fee_sea &&
                                          touched.shipping_fee_sea && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.shipping_fee_sea}
                                            </Form.Control.Feedback>
                                          )}
                                      </Form.Group>
                                    )}
                                  </Col>
                                </Row>
                                {varying_sizes === 1 && (
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    as={Row}
                                    controlId="shipping"
                                  >
                                    <Form.Label column sm="12">
                                      MOQ Attributes
                                    </Form.Label>

                                    <Col sm="12">
                                      {moq_attributes.map((x, i) => {
                                        return (
                                          <Row
                                            key={i}
                                            style={{
                                              borderBottom: "1px solid #eee",
                                              paddingBottom: "1em",
                                            }}
                                          >
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                as="select"
                                                name="size_id"
                                                value={x.size_id}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              >
                                                <option value="">
                                                  Sizes ...
                                                </option>

                                                {this.state.sizes !== null &&
                                                  this.state.sizes.map(
                                                    (size, i) => (
                                                      <option
                                                        key={i}
                                                        value={size.id}
                                                      >
                                                        {size.label}
                                                      </option>
                                                    )
                                                  )}
                                              </Form.Control>
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Supplier Price"
                                                name="supplier_price"
                                                value={x.supplier_price}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              />
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Price"
                                                name="price"
                                                value={x.price}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                                min={0}
                                              />
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Below MOQ Price"
                                                name="below_moq_price"
                                                value={x.below_moq_price}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                                min={0}
                                              />
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Weight (in grams)"
                                                name="weight"
                                                value={x.weight}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                                min={0}
                                              />
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Approximate Shipping Fee Air"
                                                name="shipping_fee_air"
                                                value={x.shipping_fee_air}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              />
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Approximate Shipping Fee Sea"
                                                name="shipping_fee_sea"
                                                value={x.shipping_fee_sea}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              />
                                            </Col>
                                            <Col
                                              sm="3"
                                              style={{ marginTop: "1em" }}
                                            >
                                              {moq_attributes.length - 1 ===
                                                i && (
                                                <Button
                                                  variant="success"
                                                  style={{
                                                    marginRight: "1em",
                                                  }}
                                                  onClick={this.handleAddClick}
                                                >
                                                  <FiPlus />
                                                </Button>
                                              )}

                                              {moq_attributes.length !== 1 && (
                                                <Button
                                                  variant="danger"
                                                  onClick={() =>
                                                    this.handleRemoveClick(i)
                                                  }
                                                >
                                                  <FiTrash2 />
                                                </Button>
                                              )}
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </Col>
                                  </Form.Group>
                                )}
                                <Row>
                                  {varying_sizes === 0 && (
                                    <Col sm="6">
                                      <Form.Group
                                        controlId=""
                                        style={{ marginTop: "1em" }}
                                      >
                                        <Form.Label>Supplier Price</Form.Label>

                                        <Form.Control
                                          type="number"
                                          placeholder="Supplier Price"
                                          name="supplier_price"
                                          isValid={
                                            touched.supplier_price &&
                                            !errors.supplier_price
                                          }
                                          isInvalid={
                                            errors.supplier_price &&
                                            touched.supplier_price
                                          }
                                          value={values.supplier_price}
                                          onChange={handleChange}
                                        />

                                        {errors.supplier_price &&
                                          touched.supplier_price && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.supplier_price}
                                            </Form.Control.Feedback>
                                          )}
                                      </Form.Group>
                                    </Col>
                                  )}
                                  <Col sm={varying_sizes === 0 ? "6" : "12"}>
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId="supplier_currency"
                                    >
                                      <Form.Label>Supplier Currency</Form.Label>

                                      <Form.Control
                                        as="select"
                                        isValid={
                                          touched.supplier_currency &&
                                          !errors.supplier_currency
                                        }
                                        isInvalid={
                                          errors.supplier_currency &&
                                          touched.supplier_currency
                                        }
                                        name="supplier_currency"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={values.supplier_currency}
                                      >
                                        <option value="">Choose ...</option>

                                        {CurrencyCodes.map((currency, i) => (
                                          <option key={i} value={currency.code}>
                                            {currency.code} -{" "}
                                            {currency.currency}
                                          </option>
                                        ))}
                                      </Form.Control>

                                      {errors.supplier_currency &&
                                        touched.supplier_currency && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.supplier_currency}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  {varying_sizes === 0 && (
                                    <Col sm="6">
                                      <Form.Group
                                        style={{ marginTop: "1em" }}
                                        controlId="price"
                                      >
                                        <Form.Label>Price</Form.Label>

                                        <Form.Control
                                          type="number"
                                          placeholder="Price"
                                          name="price"
                                          isValid={
                                            touched.price && !errors.price
                                          }
                                          isInvalid={
                                            errors.price && touched.price
                                          }
                                          value={values.price}
                                          onChange={handleChange}
                                        />

                                        {errors.price && touched.price && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.price}
                                          </Form.Control.Feedback>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {varying_sizes === 0 && (
                                    <Col sm="6">
                                      <Form.Group
                                        style={{ marginTop: "1em" }}
                                        controlId="price"
                                      >
                                        <Form.Label>Below MOQ Price</Form.Label>

                                        <Form.Control
                                          type="number"
                                          placeholder="Price"
                                          name="below_moq_price"
                                          isValid={
                                            touched.below_moq_price &&
                                            !errors.below_moq_price
                                          }
                                          isInvalid={
                                            errors.below_moq_price &&
                                            touched.below_moq_price
                                          }
                                          value={values.below_moq_price}
                                          onChange={handleChange}
                                        />

                                        {errors.below_moq_price &&
                                          touched.below_moq_price && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.below_moq_price}
                                            </Form.Control.Feedback>
                                          )}
                                      </Form.Group>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId=""
                                    >
                                      <Form.Label>Start Date</Form.Label>

                                      <Form.Control
                                        type="date"
                                        placeholder="Start Date"
                                        name="start_date"
                                        isValid={
                                          touched.start_date &&
                                          !errors.start_date
                                        }
                                        isInvalid={
                                          errors.start_date &&
                                          touched.start_date
                                        }
                                        value={values.start_date}
                                        onChange={handleChange}
                                      />

                                      {errors.start_date &&
                                        touched.start_date && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.start_date}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </Col>
                                  <Col sm="6">
                                    <Form.Group
                                      style={{ marginTop: "1em" }}
                                      controlId=""
                                    >
                                      <Form.Label>End Date</Form.Label>

                                      <Form.Control
                                        type="date"
                                        placeholder="End Date"
                                        name="end_date"
                                        isValid={
                                          touched.end_date && !errors.end_date
                                        }
                                        isInvalid={
                                          errors.end_date && touched.end_date
                                        }
                                        value={values.end_date}
                                        onChange={handleChange}
                                      />

                                      {errors.end_date && touched.end_date && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.end_date}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                {varying_sizes !== 1 && (
                                  <Form.Group
                                    style={{ marginTop: "1em" }}
                                    controlId=""
                                  >
                                    <Form.Label>Weight (gms)</Form.Label>

                                    <Form.Control
                                      type="number"
                                      placeholder="Weight"
                                      name="weight"
                                      isValid={touched.weight && !errors.weight}
                                      isInvalid={
                                        errors.weight && touched.weight
                                      }
                                      value={values.weight}
                                      onChange={handleChange}
                                    />

                                    {errors.weight && touched.weight && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.weight}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                )}
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled={loading}
                                  style={{ marginTop: "12px" }}
                                >
                                  Create MOQ
                                </Button>
                              </Form>
                            )}
                          </Formik>
                        </Card.Body>
                      </Card>
                    </Col>
                  }
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    moqs: state.moqs,

    products: state.products,
  };
};

export default connect(mapStateToProps, { addMoq, getAllProducts })(
  withRouter(Create)
);
