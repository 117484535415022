import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Common Components
import PrivateRoute from "./components/common/PrivateRoute";

// Importing Components
import Index from "./components/Index";
import Home from "./components/home/Index";
import Users from "./components/users/Index";
import ShowUser from "./components/users/Show";
import EditUser from "./components/users/Edit";
import Products from "./components/products/Index";
import CreateProduct from "./components/products/Create";
import ShowProduct from "./components/products/Show";
import EditProduct from "./components/products/Edit";
import Moqs from "./components/moqs/Index";
import ShowMoq from "./components/moqs/Show";
import CreateMoq from "./components/moqs/Create";
import EditMoq from "./components/moqs/Edit";
import Orders from "./components/orders/Index";
import ShowOrder from "./components/orders/Show";
import CreateOrder from "./components/orders/Create";
import EditOrder from "./components/orders/Edit";
import Suppliers from "./components/suppliers/Index";
import Bids from "./components/bids/Index";
import ShowBid from "./components/bids/Show";
import Colors from "./components/colors/Index";
import Sizes from "./components/sizes/Index";
import SignIn from "./components/auth/SignIn";
import Transactions from "./components/transactions/Index";
import ShowTransaction from "./components/transactions/Show";
import SendSMS from "./components/common/SendSMS";
import MoqBids from "./components/moqs/Bids";
import Categories from "./components/categories/Index";
import ShowCategory from "./components/categories/Show";
import Settings from "./components/settings/Index";
import Recommendations from "./components/recommendations/Index";
import PromoCodes from "./components/promocode/Index";
import CreatePromoCode from "./components/promocode/Create";
import EditPromoCode from "./components/promocode/Edit";
import ShowPromoCode from "./components/promocode/Show";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact strict component={Index} />

        <Route path="/signin" exact strict component={SignIn} />

        <PrivateRoute path="/admin" exact strict component={Home} />

        <PrivateRoute path="/admin/users" exact strict component={Users} />

        <PrivateRoute
          path="/admin/users/:id"
          exact
          strict
          component={ShowUser}
        />

        <PrivateRoute
          path="/admin/users/:id/edit"
          exact
          strict
          component={EditUser}
        />

        <PrivateRoute
          path="/admin/products"
          exact
          strict
          component={Products}
        />

        <PrivateRoute
          path="/admin/products/create"
          exact
          strict
          component={CreateProduct}
        />

        <PrivateRoute
          path="/admin/products/:id"
          exact
          strict
          component={ShowProduct}
        />

        <PrivateRoute
          path="/admin/products/:id/edit"
          exact
          strict
          component={EditProduct}
        />

        <PrivateRoute
          path="/admin/promo-codes"
          exact
          strict
          component={PromoCodes}
        />

        <PrivateRoute
          path="/admin/promo-codes/create"
          exact
          strict
          component={CreatePromoCode}
        />

        <PrivateRoute
          path="/admin/promo-codes/:id"
          exact
          strict
          component={ShowPromoCode}
        />

        <PrivateRoute
          path="/admin/promo-codes/:id/edit"
          exact
          strict
          component={EditPromoCode}
        />

        <PrivateRoute path="/admin/moqs" exact strict component={Moqs} />

        <PrivateRoute
          path="/admin/moqs/create"
          exact
          strict
          component={CreateMoq}
        />

        <PrivateRoute path="/admin/moqs/:id" exact strict component={ShowMoq} />

        <PrivateRoute
          path="/admin/moqs/:id/bids"
          exact
          strict
          component={MoqBids}
        />

        <PrivateRoute
          path="/admin/moqs/:id/edit"
          exact
          strict
          component={EditMoq}
        />

        <PrivateRoute path="/admin/orders" exact strict component={Orders} />

        <PrivateRoute
          path="/admin/orders/create"
          exact
          strict
          component={CreateOrder}
        />

        <PrivateRoute
          path="/admin/orders/:id"
          exact
          strict
          component={ShowOrder}
        />

        <PrivateRoute
          path="/admin/orders/:id/edit"
          exact
          strict
          component={EditOrder}
        />

        <PrivateRoute
          path="/admin/suppliers"
          exact
          strict
          component={Suppliers}
        />

        <PrivateRoute path="/admin/bids" exact strict component={Bids} />

        <PrivateRoute path="/admin/bids/:id" exact strict component={ShowBid} />

        <PrivateRoute path="/admin/colors" exact strict component={Colors} />

        <PrivateRoute path="/admin/sizes" exact strict component={Sizes} />

        <PrivateRoute
          path="/admin/transactions"
          exact
          strict
          component={Transactions}
        />

        <PrivateRoute
          path="/admin/transactions/:id"
          exact
          strict
          component={ShowTransaction}
        />

        <PrivateRoute path="/admin/send-sms" exact strict component={SendSMS} />

        <PrivateRoute
          path="/admin/categories"
          exact
          strict
          component={Categories}
        />

        <PrivateRoute
          path="/admin/categories/:id"
          exact
          strict
          component={ShowCategory}
        />

        <PrivateRoute
          path="/admin/settings"
          exact
          strict
          component={Settings}
        />

        <PrivateRoute
          path="/admin/recommendations"
          exact
          strict
          component={Recommendations}
        />
      </Switch>
    </Router>
  );
};

export default App;
