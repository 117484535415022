import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BlockLoader from "./../contentloaders/BlockLoader";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Card,
  Alert,
  FormControl,
} from "react-bootstrap";
import {
  editOrder,
  showOrder,
  getOrders,
} from "./../../store/actions/OrderActions";
import { getMoqs, showMoq, editMoq } from "./../../store/actions/MoqActions";
import { getSuppliers } from "./../../store/actions/SupplierActions";
import { getProduct } from "./../../store/actions/ProductActions";
import { createSchema } from "./../../helpers/validation/order";
import { toast } from "react-toastify";
import { Formik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../index.css";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      checked: false,

      charLimit: 300,

      moq_attributes: [],

      sizes: [],

      varying_sizes: null,

      moq_id: null,

      eta: null,

      weight: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.getSuppliers();

    await this.props.getMoqs();

    const {
      match: { params },
    } = this.props;

    this.setState({ loading: true });

    const result = await this.props.showOrder(params.id);

    if (result) {
      if (result.eta !== null) {
        this.setState({ eta: new Date(result.eta) });
      }

      if (result.product !== undefined) {
        this.getProduct(result.product[0].id);
      }

      if (result.moq_id !== undefined) {
        this.setState({ moq_id: result.moq_id });

        let response = await this.props.showMoq(result.moq_id);

        if (
          response &&
          response.moq_attributes &&
          response.moq_attributes.length > 0 &&
          response.moq_attributes !== undefined
        ) {
          for (let i = 0; i < response.moq_attributes.length; i++) {
            if (
              response.moq_attributes[i].actual_shipping_fee_sea === undefined
            ) {
              response.moq_attributes[i].actual_shipping_fee_sea = 0;
            }

            if (
              response.moq_attributes[i].actual_shipping_fee_air === undefined
            ) {
              response.moq_attributes[i].actual_shipping_fee_air = 0;
            }
          }
          this.setState({ moq_attributes: response.moq_attributes });
        }
      }
      this.setState({ loading: false });
    }
  };

  componentWillUnmount = async () => {};

  editOrder = async (values) => {
    try {
      const {
        match: { params },
      } = this.props;

      const { moq_id, moq_attributes } = this.state;

      let hasWeight = moq_attributes.every(
        (attr) => attr?.weight !== "" && attr?.weight > 0
      );
      if (
        values.status === "Goods arrived at Litudian - Ready to collect" &&
        !hasWeight
      ) {
        return toast.error(
          "Weight(s) in MOQ attributes is required and must not have zero values !",
          {
            position: "top-right",
          }
        );
      }

      if (
        values.status === "Goods arrived at Litudian - Ready to collect" &&
        values.shipping_fee === ""
      ) {
        return toast.error("Shipping Fee Is Required !", {
          position: "top-right",
        });
      }

      if (
        values.status === "Shipper loaded - Goods in transit" &&
        this.state.eta === null
      ) {
        return toast.error("ETA field Is Required !", {
          position: "top-right",
        });
      }

      if (values.status === "Order Deleted" && values.order_remarks === "") {
        return toast.error(
          "Please provide reasons for deleting order in the remarks input !",
          {
            position: "top-right",
          }
        );
      }

      const { checked } = this.state;

      if (checked === true) {
        values.send_sms = 1;
      } else {
        delete values.send_sms;
      }

      if (this.state.eta !== null) {
        values.eta = moment(this.state.eta).format("YYYY-MM-DD");
      }

      this.setState({ loading: true });

      const result = await this.props.editOrder(params.id, values);

      if (result) {
        if (
          moq_id !== null &&
          moq_attributes.length > 0 &&
          values.status === "Goods arrived at Litudian - Ready to collect"
        ) {

          for (let i = 0; i <= moq_attributes.length; i++) {
            if (
              moq_attributes[i] !== undefined &&
              (moq_attributes[i].actual_shipping_fee_sea === 0 &&  moq_attributes[i].actual_shipping_fee_air === 0)
            ) {
              this.setState({ loading: false });

              return toast.error(
                "Either Actual Shipping Fee Sea Or Actual Shipping Fee Air Should Be More Than Zero For Each MOQ Attribute !",
                {
                  position: "top-right",
                }
              );
            } 
          }

          let obj = {};

          if (
            values.status === "Goods arrived at Litudian - Ready to collect"
          ) {
            obj.set_actual_shipping_fees = 1;
          }

          obj.moq_attributes = moq_attributes;

          await this.props.editMoq(moq_id, obj);
        }

        toast.success("Order Details Edited Successfully !", {
          position: "top-right",
        });

        await this.props.getOrders();

        this.setState({ showModal: false, checked: false, loading: false });

        return this.props.history.push(`/admin/orders`);
      }
    } catch (error) {
      console.log(error);

      this.setState({ checked: false, loading: false });
    }
  };

  getProduct = async (id) => {
    try {
      const result = await this.props.getProduct(id);

      this.setState({
        varying_sizes: result.varying_sizes,
        sizes: result.readable_sizes,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleInputChange = (e, index) => {
    const { moq_attributes } = this.state;

    const { name, value } = e.target;

    let attributes = [...moq_attributes];

    attributes[index][name] = parseInt(value);

    this.setState({ moq_attributes: attributes });
  };

  render() {
    const {
      suppliers,
      orders: { order },
      products: { product },
    } = this.props;

    const { loading, charLimit, moq_attributes } = this.state;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h1>Edit Order {order.order_number}</h1>
              </Col>

              {loading && (
                <Col sm="12" style={{ margin: 0, padding: 0 }}>
                  {loading && (
                    <BlockLoader
                      width="100%"
                      height="400px"
                      innerWidth="100%"
                      innerHeight="100%"
                    />
                  )}
                </Col>
              )}

              {!loading && order.moq_id !== undefined && (
                <Col sm="12">
                  <Card>
                    <Card.Body>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          moq_id: order.moq_id !== undefined ? order.moq_id : 0,
                          supplier_id:
                            order.supplier_id !== undefined
                              ? order.supplier_id
                              : "",
                          status:
                            order.status !== undefined ? order.status : "",
                          shipping_fee:
                            order.shipping_fee !== undefined
                              ? order.shipping_fee
                              : "",
                          total_cost:
                            order.total_cost !== undefined
                              ? order.total_cost
                              : 0,
                          shipping_company:
                            order.shipping_company !== undefined
                              ? order.shipping_company
                              : "",
                          order_remarks:
                            order.order_remarks !== undefined
                              ? order.order_remarks
                              : "",
                        }}
                        validationSchema={createSchema}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          this.editOrder(values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form
                            noValidate
                            onSubmit={(e) => {
                              e.preventDefault();

                              handleSubmit();
                            }}
                          >
                            <Row>
                              <Form.Group as={Col} sm="6" controlId="product">
                                <Form.Label column sm="12">
                                  Product
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    type="text"
                                    placeholder="Product"
                                    name="product"
                                    value={
                                      product.name !== undefined
                                        ? product.name
                                        : ""
                                    }
                                    disabled={true}
                                  />
                                </Col>
                              </Form.Group>

                              <Form.Group as={Col} sm="6" controlId="moq_id">
                                <Form.Label column sm="12">
                                  Moq
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    as="select"
                                    isValid={touched.moq_id && !errors.moq_id}
                                    isInvalid={errors.moq_id && touched.moq_id}
                                    name="moq_id"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={values.moq_id}
                                    disabled
                                  >
                                    {/* <option value={0}>Choose ...</option> */}

                                    {order.moq_id !== undefined && (
                                      <option value={order.moq[0].id}>
                                        {order.moq[0].moq_number}
                                      </option>
                                    )}

                                    {/* {moqs.data.map((moq, i) => <option key={i} value={moq.id} selected={values.moq_id === moq.id}>{moq.moq_number}</option>)} */}
                                  </Form.Control>

                                  {errors.moq_id && touched.moq_id && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.moq_id}
                                    </Form.Control.Feedback>
                                  )}
                                </Col>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col} sm="6" controlId="supplier">
                                <Form.Label column sm="12">
                                  Supplier
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    as="select"
                                    isValid={
                                      touched.supplier_id && !errors.supplier_id
                                    }
                                    isInvalid={
                                      errors.supplier_id && touched.supplier_id
                                    }
                                    name="supplier_id"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={values.supplier_id}
                                    disabled
                                  >
                                    <option value="">Choose ...</option>

                                    {suppliers.map((supplier, i) => (
                                      <option key={i} value={supplier.id}>
                                        {supplier.name}
                                      </option>
                                    ))}
                                  </Form.Control>

                                  {errors.supplier_id &&
                                    touched.supplier_id && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.supplier_id}
                                      </Form.Control.Feedback>
                                    )}
                                </Col>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm="6"
                                controlId="shipping_fee"
                              >
                                <Form.Label column sm="12">
                                  Total Shipping Fee
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    type="number"
                                    placeholder="Shipping Fee"
                                    name="shipping_fee"
                                    isValid={
                                      touched.shipping_fee &&
                                      !errors.shipping_fee
                                    }
                                    isInvalid={
                                      errors.shipping_fee &&
                                      touched.shipping_fee
                                    }
                                    value={values.shipping_fee}
                                    onChange={handleChange}
                                    disabled={
                                      values.status !==
                                      "Goods arrived at Litudian - Ready to collect"
                                    }
                                  />

                                  {errors.shipping_fee &&
                                    touched.shipping_fee && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.shipping_fee}
                                      </Form.Control.Feedback>
                                    )}
                                </Col>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group
                                as={Col}
                                sm="6"
                                controlId="total_cost"
                              >
                                <Form.Label column sm="12">
                                  Total Cost
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    type="number"
                                    placeholder="Total Cost"
                                    name="total_cost"
                                    isValid={
                                      touched.total_cost && !errors.total_cost
                                    }
                                    isInvalid={
                                      errors.total_cost && touched.total_cost
                                    }
                                    value={values.total_cost}
                                    onChange={handleChange}
                                    disabled={
                                      values.status !==
                                      "Supplier acknowledged - Preparing goods"
                                    }
                                  />

                                  {errors.total_cost && touched.total_cost && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.total_cost}
                                    </Form.Control.Feedback>
                                  )}
                                </Col>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm="6"
                                controlId="shipping_company"
                              >
                                <Form.Label column sm="12">
                                  Shipping Company
                                </Form.Label>

                                <Col sm="12">
                                  <Form.Control
                                    type="text"
                                    placeholder="Shipping Company"
                                    name="shipping_company"
                                    isValid={
                                      touched.shipping_company &&
                                      !errors.shipping_company
                                    }
                                    isInvalid={
                                      errors.shipping_company &&
                                      touched.shipping_company
                                    }
                                    value={values.shipping_company}
                                    onChange={handleChange}
                                  />

                                  {errors.shipping_company &&
                                    touched.shipping_company && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.shipping_company}
                                      </Form.Control.Feedback>
                                    )}
                                </Col>
                              </Form.Group>
                            </Row>
                            <Form.Group as={Col} sm="12" controlId="status">
                              <Form.Label column sm="12">
                                Status
                              </Form.Label>

                              <Col sm="12">
                                <Form.Control
                                  as="select"
                                  isValid={touched.status && !errors.status}
                                  defaultValue={values.status}
                                  isInvalid={errors.status && touched.status}
                                  name="status"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Choose...</option>

                                  <option value="Submitted - Pending supplier acknowledgement">
                                    Submitted - Pending supplier acknowledgement
                                  </option>

                                  <option value="Supplier acknowledged - Preparing goods">
                                    Supplier acknowledged - Preparing goods
                                  </option>

                                  <option value="At shipper - Loading goods">
                                    At shipper - Loading goods
                                  </option>

                                  <option value="Shipper loaded - Goods in transit">
                                    Shipper loaded - Goods in transit
                                  </option>

                                  <option value="Arrived - Customs clearance">
                                    Arrived - Customs clearance
                                  </option>

                                  <option value="Arrived - At shipper warehouse">
                                    Arrived - At shipper warehouse
                                  </option>

                                  <option value="Goods arrived at Litudian - Ready to collect">
                                    Goods arrived at Litudian - Ready to collect
                                  </option>

                                  <option value="Order cancelled">
                                    Order cancelled
                                  </option>

                                  <option value="Order Deleted">
                                    Order Deleted
                                  </option>
                                </Form.Control>

                                {errors.status && touched.status && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.status}
                                  </Form.Control.Feedback>
                                )}
                              </Col>
                            </Form.Group>

                            {values.status ===
                              "Goods arrived at Litudian - Ready to collect" &&
                              moq_attributes.length > 0 && (
                                <Form.Group as={Row} controlId="moq_attributes">
                                  <Form.Label column sm="12">
                                    MOQ Attributes
                                  </Form.Label>

                                  <Col sm="12">
                                    {moq_attributes.length > 0 &&
                                      moq_attributes.map((x, i) => {
                                        return (
                                          <Row
                                            style={{ marginBottom: "10px" }}
                                            key={i}
                                          >
                                            {x.size_id !== undefined && (
                                              <Col sm="2">
                                                <Form.Label column sm="12">
                                                  Size
                                                </Form.Label>

                                                <Form.Control
                                                  as="select"
                                                  name="size_id"
                                                  value={x.size_id}
                                                  onChange={(e) =>
                                                    this.handleInputChange(e, i)
                                                  }
                                                  disabled={true}
                                                >
                                                  <option value="">
                                                    Sizes ...
                                                  </option>

                                                  {this.state.sizes !== null &&
                                                    this.state.sizes.map(
                                                      (size, i) => (
                                                        <option
                                                          key={i}
                                                          value={size.id}
                                                        >
                                                          {size.label}
                                                        </option>
                                                      )
                                                    )}
                                                </Form.Control>
                                              </Col>
                                            )}

                                            {x.supplier_price !== undefined && (
                                              <Col sm="2">
                                                <Form.Label column sm="12">
                                                  Supplier Price
                                                </Form.Label>

                                                <Form.Control
                                                  type="number"
                                                  placeholder="Supplier Price"
                                                  name="supplier_price"
                                                  value={x.supplier_price}
                                                  onChange={(e) =>
                                                    this.handleInputChange(e, i)
                                                  }
                                                  disabled={true}
                                                />
                                              </Col>
                                            )}

                                            {x.price !== undefined && (
                                              <Col sm="2">
                                                <Form.Label column sm="12">
                                                  Price
                                                </Form.Label>

                                                <Form.Control
                                                  type="number"
                                                  placeholder="Price"
                                                  name="price"
                                                  value={x.price}
                                                  onChange={(e) =>
                                                    this.handleInputChange(e, i)
                                                  }
                                                  disabled={true}
                                                />
                                              </Col>
                                            )}

                                            <Col sm="3">
                                              <Form.Label column sm="12">
                                                Approximate Shipping Air
                                              </Form.Label>

                                              <Form.Control
                                                type="number"
                                                placeholder="Shipping Fee Air"
                                                name="shipping_fee_air"
                                                value={x.shipping_fee_air}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={true}
                                              />
                                            </Col>

                                            <Col sm="3">
                                              <Form.Label column sm="12">
                                                Approximate Shipping Sea
                                              </Form.Label>

                                              <Form.Control
                                                type="number"
                                                placeholder="Shipping Fee Sea"
                                                name="shipping_fee_sea"
                                                value={x.shipping_fee_sea}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={true}
                                              />
                                            </Col>

                                            <Col sm="3">
                                              <Form.Label column sm="12">
                                                Actual Shipping Fee Air
                                              </Form.Label>

                                              <Form.Control
                                                type="number"
                                                placeholder="Shipping Fee Air"
                                                name="actual_shipping_fee_air"
                                                value={
                                                  x.actual_shipping_fee_air
                                                }
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              />
                                            </Col>

                                            <Col sm="3">
                                              <Form.Label column sm="12">
                                                Actual Shipping Fee Sea
                                              </Form.Label>

                                              <Form.Control
                                                type="number"
                                                placeholder="Shipping Fee Sea"
                                                name="actual_shipping_fee_sea"
                                                value={
                                                  x.actual_shipping_fee_sea
                                                }
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              />
                                            </Col>

                                            <Col sm="3">
                                              <Form.Label column sm="12">
                                                Weight
                                              </Form.Label>

                                              <Form.Control
                                                type="number"
                                                placeholder="weight"
                                                name="weight"
                                                value={x.weight}
                                                onChange={(e) =>
                                                  this.handleInputChange(e, i)
                                                }
                                                disabled={loading}
                                              />
                                            </Col>

                                            <Col sm="12">
                                              <hr />
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                  </Col>
                                </Form.Group>
                              )}

                            <Form.Group as={Row} controlId="order_remarks">
                              <Form.Label column sm="12">
                                Remarks
                              </Form.Label>

                              <Col sm="12">
                                <FormControl
                                  as="textarea"
                                  aria-label="With textarea"
                                  name="order_remarks"
                                  placeholder="write ..."
                                  isValid={
                                    touched.order_remarks &&
                                    !errors.order_remarks
                                  }
                                  isInvalid={
                                    errors.order_remarks &&
                                    touched.order_remarks
                                  }
                                  value={values.order_remarks}
                                  onChange={(e) => {
                                    let txt = e.target.value;

                                    if (txt.length <= charLimit) {
                                      setFieldValue("order_remarks", txt);
                                    }
                                  }}
                                />

                                {errors.order_remarks &&
                                  touched.order_remarks && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.order_remarks}
                                    </Form.Control.Feedback>
                                  )}

                                <span
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {charLimit - values.order_remarks.length}{" "}
                                  characters remaining.
                                </span>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="eta">
                              <Form.Label column sm="12">
                                ETA
                              </Form.Label>

                              <Col sm="12">
                                <DatePicker
                                  value={this.state.eta}
                                  className="form-control"
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  style={{ width: "100%" }}
                                  selected={this.state.eta}
                                  onChange={(date) =>
                                    this.setState({ eta: date })
                                  }
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                />
                              </Col>
                            </Form.Group>

                            {values.status === "arrived" && (
                              <Form.Group controlId="formBasicCheckbox">
                                <Form.Check
                                  checked={this.state.checked}
                                  onChange={(e) => {
                                    this.setState({
                                      checked: e.target.checked,
                                    });
                                  }}
                                  type="checkbox"
                                  label="Send SMS"
                                />
                              </Form.Group>
                            )}

                            {this.state.checked === true && (
                              <Col sm="12">
                                <Alert variant="warning">
                                  Clicking submit will send an SMS with shipping
                                  fee as {values.shipping_fee}. Continue ?
                                </Alert>
                              </Col>
                            )}

                            <Button
                              variant="primary"
                              style={{ float: "right", marginTop: "1em" }}
                              type="submit"
                              disabled={
                                order.status !== undefined &&
                                order.status &&
                                order?.status === "Order Deleted"
                              }
                            >
                              Edit Order
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </Col>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orders: state.orders,

    suppliers: state.suppliers,

    moqs: state.moqs,

    products: state.products,
  };
};

export default connect(mapStateToProps, {
  editOrder,
  getMoqs,
  getSuppliers,
  showOrder,
  getOrders,
  showMoq,
  getProduct,
  editMoq,
})(withRouter(Edit));
