import { instance, setAuthToken } from "../../helpers/instance";
import { PROMO_CODES, SINGLE_PROMO_CODE } from "../types/index";

export const getPromoCodes = () => async (dispatch) => {
  try {
    setAuthToken(instance);
    const response = await instance.get("promo_code");
    if (response?.data?.promo_codes) {
      dispatch({
        type: PROMO_CODES,
        payload: response?.data?.promo_codes || [],
      });
    }
    return response?.data;
  } catch (error) {
    if (error.response !== undefined && error.response.status === 401) {
      return (window.location.href = "/signin");
    }
    throw error;
  }
};

export const getSinglePromoCode = (id) => async (dispatch) => {
  try {
    setAuthToken(instance);
    const response = await instance.get(`promo_code/${id}`);
    if (response?.data && response?.data?.length > 0) {
      dispatch({
        type: SINGLE_PROMO_CODE,
        payload: response?.data[0] || null,
      });
    }
    return response?.data;
  } catch (error) {
    if (error.response !== undefined && error.response.status === 401) {
      return (window.location.href = "/signin");
    }
    throw error;
  }
};
