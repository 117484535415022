import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
// import axios from 'axios';
import { instance, setAuthToken } from "../../helpers/instance";
import { Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import { sendMessageSchema } from "./../../helpers/validation/sms";

class SendSMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const { loading } = this.state;

    return (
      <Layout>
        <Row style={{ minHeight: "100vh", padding: "3em 0" }}>
          <Col md={{ span: 6, offset: 3 }}>
            <Card style={{ padding: "20px" }}>
              <Formik
                initialValues={{ msisdn: "", message: "" }}
                validationSchema={sendMessageSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // values.sender = "LITUDIAN";

                  let data = values.msisdn.split("\n");

                  values.msisdn = data;

                  // console.log(values);

                  this.setState({ loading: true });

                  setAuthToken(instance);

                  const response = await instance.post("sms", values);

                  if (response.data !== "") {
                    this.setState({ loading: false });

                    toast.success("Message Sent To Recepient !", {
                      position: "top-right",
                    });

                    resetForm();
                  } else {
                    this.setState({ loading: false });
                  }

                  return (window.location.href = "/admin");
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <div>
                    <Modal.Header style={{ marginBottom: "2em" }}>
                      <Modal.Title>Send SMS</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Form.Group
                        as={Row}
                        controlId="phone_number"
                        style={{ marginBottom: "1em" }}
                      >
                        <Form.Label column sm="3">
                          Phone Number
                        </Form.Label>

                        <Col sm="9">
                          {/* <Form.Control type="text" placeholder="Phone Number" name="msisdn" isValid={touched.msisdn && !errors.msisdn} isInvalid={errors.msisdn && touched.msisdn} value={values.msisdn} onChange={handleChange} /> */}

                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Phone Number"
                            name="msisdn"
                            isValid={touched.msisdn && !errors.msisdn}
                            isInvalid={errors.msisdn && touched.msisdn}
                            value={values.msisdn}
                            onChange={handleChange}
                          />

                          {errors.msisdn && touched.msisdn && (
                            <Form.Control.Feedback type="invalid">
                              {errors.msisdn}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        controlId="message"
                        style={{ marginBottom: "1em" }}
                      >
                        <Form.Label column sm="3">
                          Message
                        </Form.Label>

                        <Col sm="9">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Write..."
                            name="message"
                            isValid={touched.message && !errors.message}
                            isInvalid={errors.message && touched.message}
                            value={values.message}
                            onChange={handleChange}
                          />

                          {errors.message && touched.message && (
                            <Form.Control.Feedback type="invalid">
                              {errors.message}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        Send SMS
                      </Button>
                    </Modal.Footer>
                  </div>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default withRouter(SendSMS);
