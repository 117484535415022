import axios from "axios";
import Cookies from "js-cookie";

export const instance = axios.create({
  baseURL: "https://api.litudian.com/api/v1/",

  // timeout: 60000,

  // headers: {'Accept': 'application/json'},

  // withCredentials: true
});

export const setAuthToken = (instance) => {
  const token = Cookies.get("ltdn") || null;

  if (token) {
    // Apply To Every Request
    instance.defaults.headers.common["Authorization"] = "Bearer " + token + "";
  } else {
    // Delete Auth Header
    delete instance.defaults.headers.common["Authorization"];
  }
};
