import React from "react";
import {
  Button,
  ListGroup,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { titleCase } from "./../../utils/index";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Collapsible from "react-collapsible";

const MoqAccordionItem = ({ moq, toggleModal, priviledge }) => {
  return (
    <div style={{ marginBottom: "5px" }}>
      <Collapsible
        trigger={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1em 2em",
              cursor: "pointer",
              backgroundColor: "#F3F4F6",
              borderRadius: "5px",
            }}
          >
            <div>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <Link to={`/admin/products/${moq.product[0].id}`}>
                  {titleCase(moq.product[0].name)}
                </Link>{" "}
                - MOQ no. {moq.moq_number}
              </h6>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                Running from{" "}
                <Moment format="DD/MM/YYYY">{moq.start_date}</Moment> to{" "}
                <Moment format="DD/MM/YYYY">{moq.end_date}</Moment>
              </h6>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <a href={moq.source} target="_blank">
                  Source link
                </a>
              </h6>

              {moq.sort_order !== 1 && moq.sort_order !== 2 && (
                <span
                  style={{
                    backgroundColor: "#fff",
                    padding: "2px 10px",
                    borderRadius: "20px",
                    border: "1px solid #757575",
                    fontSize: "12px",
                  }}
                >
                  {moq.moq_order_status}
                </span>
              )}

              {moq.sort_order === 1 && (
                <span
                  style={{
                    backgroundColor: "#EF4444",
                    padding: "2px 10px",
                    borderRadius: "20px",
                    border: "1px solid #EF4444",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {moq.moq_order_status}
                </span>
              )}

              {moq.sort_order === 2 && (
                <span
                  style={{
                    backgroundColor: "#F59E0B",
                    padding: "2px 10px",
                    borderRadius: "20px",
                    border: "1px solid #F59E0B",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {moq.moq_order_status}
                </span>
              )}
            </div>
            <div>
              {moq.product !== undefined &&
                moq.product[0].images.length > 0 && (
                  <Image
                    src={moq.product[0].images[0]}
                    alt={moq.product[0].name}
                    rounded
                    style={{
                      height: "100px",
                      width: "100%",
                      objectFit: "contain",
                      marginTop: 0,
                      paddingTop: 0,
                      marginRight: "10px",
                    }}
                  />
                )}
            </div>

            <DropdownButton
              as={ButtonGroup}
              title="Action"
              id="bg-vertical-dropdown-1"
            >
              <Dropdown.Item
                eventKey="1"
                as={Link}
                to={`/admin/moqs/${moq.id}/bids`}
              >
                View Bids
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="2"
                as={Link}
                to={`/admin/moqs/${moq.id}`}
              >
                View MOQ
              </Dropdown.Item>

              {priviledge === 2 && (
                <Dropdown.Item
                  eventKey="3"
                  as={Link}
                  to={`/admin/moqs/${moq.id}/edit`}
                >
                  Edit MOQ
                </Dropdown.Item>
              )}

              <Dropdown.Item
                eventKey="4"
                as={Button}
                onClick={() => {
                  toggleModal(moq.id);
                }}
                disabled={
                  moq.moq_order_status !== "not placed" ||
                  moq.paid_bid_quantity === 0
                    ? true
                    : false
                }
              >
                Raise PO
              </Dropdown.Item>

              <CopyToClipboard
                text={moq.moq_text}
                onCopy={() => {
                  toast.success("MOQ Text Copied !", {
                    position: "top-right",
                  });
                }}
              >
                <Dropdown.Item eventKey="5" as={Button}>
                  Share Update
                </Dropdown.Item>
              </CopyToClipboard>
            </DropdownButton>
          </div>
        }
      >
        <div style={{ padding: "30px", backgroundColor: "#f9f9f9" }}>
          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>MOQ Price: </span>{" "}
            <span>
              KES.{" "}
              {moq.readable_price !== undefined
                ? moq.readable_price
                : moq.price}
            </span>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Below MOQ Price: </span>{" "}
            <span>
              KES. {moq.below_moq_price !== undefined ? moq.below_moq_price : 0}
            </span>
          </ListGroup.Item>

          {priviledge === 2 && (
            <ListGroup.Item>
              <span style={{ fontWeight: "bold" }}>Supplier Price: </span>{" "}
              <span>
                {moq.supplier_currency}{" "}
                {moq.readable_supplier_price !== undefined
                  ? moq.readable_supplier_price
                  : moq.supplier_price}
              </span>
            </ListGroup.Item>
          )}

          {moq.moq_attributes !== undefined &&
            moq.moq_attributes.length > 0 && (
              <ListGroup.Item>
                <div style={{ fontWeight: "bold" }}>MOQ Attributes: </div>

                {moq.moq_attributes.map((attribute, i) => (
                  <div key={i}>
                    <ul>
                      {Object.entries(attribute).map(([key, value], i) => (
                        <li key={i}>
                          {key}: {value} {key === 'weight' && 'gm'}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </ListGroup.Item>
            )}

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Supplier MOQ: </span>{" "}
            <span>{moq.quantity}</span>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>MOQ Per Person: </span>{" "}
            <span>{moq.min_quantity}</span>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Bid Balance: </span>{" "}
            <span>{moq.moq_bid_balance}</span>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Total Paid Bids: </span>{" "}
            <span>{moq.paid_bid_quantity}</span>
          </ListGroup.Item>

          <ListGroup.Item>
            <span style={{ fontWeight: "bold" }}>Total Paid Amount: </span>{" "}
            <span>KES. {moq.paid_bid_amount}</span>
          </ListGroup.Item>

          {/* <ListGroup.Item>

                    <span style={{ fontWeight: 'bold' }}>MOQ Full: </span> <Button onClick={() => { toggleModal(moq.id) }} variant="success" size="sm" disabled={moq.moq_order_status !== "not placed" ? true : false}>Raise PO</Button>

                </ListGroup.Item> */}

          {/* <ListGroup.Item>

                    <span style={{ fontWeight: 'bold' }}>MOQ Text: </span> 

                    <CopyToClipboard text={moq.moq_text} onCopy={() => {

                        toast.success('MOQ Text Copied !', {

                            position: "top-right"

                        });

                    }}>

                        <Button variant="info" size="sm">Share Update</Button>

                    </CopyToClipboard>

                </ListGroup.Item> */}
        </div>
      </Collapsible>
    </div>
  );
};

export default MoqAccordionItem;
