import { ORDERS, FETCH_MORE_ORDERS, GET_ORDER } from '../types/index';

const initialState = {

    data: [],

    order: {},

    pages: null,

    page: 1,

    next_page: null

};

export default function(state = initialState, action) {

    switch(action.type) {

        case ORDERS: 

            return {

                ...state,

                data: action.payload.data,

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case FETCH_MORE_ORDERS: 

            return {

                ...state,

                data: state.data.concat(action.payload.data),

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };
        
        case GET_ORDER:

            return {

                ...state,

                order: action.payload

            }

        default:

            return state;

    }

};