import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import BlockLoader from "./../contentloaders/BlockLoader";
import {
  Row,
  Col,
  Container,
  Table,
  Modal,
  Form,
  Button,
  FormControl,
  Alert,
} from "react-bootstrap";
import {
  getOrders,
  editOrder,
  getMoreOrders,
  searchOrder,
} from "./../../store/actions/OrderActions";
import { getMoqs } from "./../../store/actions/MoqActions";
import { getSuppliers } from "./../../store/actions/SupplierActions";
import { createSchema } from "./../../helpers/validation/order";
import { toast } from "react-toastify";
import { Formik } from "formik";
import Moment from "react-moment";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      order: {},

      loading: false,

      search: "",

      checked: false,

      searched: false,

      remarksModal: false,

      charLimit: 300,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    const { location } = this.props;

    if (location.search !== "") {
      const values = queryString.parse(location.search);

      if (values.order_modal !== undefined && values.order_modal === "true") {
        this.setState({ showModal: true });
      }
    }

    await this.props.getMoqs();

    await this.props.getSuppliers();

    const res = await this.props.getOrders();

    if (res) {
      this.setState({ loading: false });
    }
  };

  toggleModal = (order) => {
    if (order !== undefined) {
      this.setState({ order });
    }

    this.setState({ showModal: !this.state.showModal });
  };

  toggleRemarksModal = (order) => {
    if (order !== undefined) {
      this.setState({ order });
    }

    this.setState({ remarksModal: !this.state.remarksModal });
  };

  editOrder = async (values) => {
    try {
      if (
        values.status === "Goods arrived at Litudian - Ready to collect" &&
        values.shipping_fee === ""
      ) {
        return toast.error("Shipping Fee Is Required !", {
          position: "top-right",
        });
      }

      const { order, checked } = this.state;

      if (checked === true) {
        values.send_sms = 1;
      } else {
        delete values.send_sms;
      }

      this.setState({ loading: true });

      const result = await this.props.editOrder(order.id, values);

      if (result) {
        toast.success("Order Details Edited Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, checked: false, loading: false });

        await this.props.getOrders();
      }
    } catch (error) {
      console.log(error);

      this.setState({ checked: false, loading: false });
    }
  };

  fetchMoreOrders = async () => {
    try {
      this.setState({ loading: true });

      const {
        orders: { next_page },
      } = this.props;

      const result = await this.props.getMoreOrders(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchOrder = async () => {
    try {
      let obj = { order_number: this.state.search };

      this.setState({ loading: true });

      const result = await this.props.searchOrder(obj);

      if (result) {
        this.setState({ loading: false });

        toast.success("Order Found !", {
          position: "top-right",
        });

        return this.props.history.push(`/admin/orders/${result[0].id}`);
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("Order Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13) {
        await this.searchOrder();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  refresh = async () => {
    this.setState({ loading: true });

    const result = await this.props.getOrders();

    if (result) {
      this.setState({ loading: false, search: "", searched: false });
    }
  };

  render() {
    const {
      orders,
      suppliers,
      orders: { page, pages },
      search,
    } = this.props;

    const { order, loading, searched, charLimit } = this.state;

    // console.log(order);

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Form
                  inline
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await this.searchOrder();
                  }}
                >
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="PO Number"
                        value={search}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Button variant="outline-success" type="submit">
                        Search
                      </Button>
                    </Col>
                    {searched === true && (
                      <Col>
                        <Button
                          variant="outline-success"
                          type="button"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            this.refresh();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>

              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Orders</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="150px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}
              </Col>

              <Col sm="12">
                {!loading && (
                  <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    variant="light"
                    responsive="sm"
                  >
                    <thead>
                      <tr>
                        <th>#</th>

                        <th>Product Name</th>

                        <th>Varying Sizes</th>

                        <th>PO Number</th>

                        <th>Campaign Number</th>

                        <th>MOQ Bids</th>

                        <th>Mode Of Shipping</th>

                        <th>Shipping Fee</th>

                        <th>Total Collected</th>

                        <th>Total Cost</th>

                        <th>Shipping Company</th>

                        <th>Supplier</th>

                        <th>Remarks</th>

                        <th>Status</th>

                        <th>ETA</th>

                        <th>#</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.data.map((order, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>
                            <Link to={`/admin/products/${order.product[0].id}`}>
                              {order.product[0].name}
                            </Link>
                          </td>

                          <td>
                            {order.product[0].varying_sizes === 1
                              ? "Yes"
                              : "No"}
                          </td>

                          <td>{order.order_number}</td>

                          <td>
                            <Link to={`/admin/moqs/${order.moq[0].id}`}>
                              {order.moq[0].moq_number}
                            </Link>
                          </td>

                          <td>
                            <Link to={`/admin/moqs/${order.moq[0].id}/bids`}>
                              View Bids
                            </Link>
                          </td>

                          <td>{order.shipping}</td>

                          <td>{order.shipping_fee}</td>

                          <td>{order.total_collected}</td>

                          <td>{order.total_cost}</td>

                          <td>{order.shipping_company}</td>

                          <td>{order.supplier[0].name}</td>

                          <td>
                            {
                              <Button
                                variant="info"
                                style={{ color: "#fff" }}
                                size="sm"
                                onClick={() => this.toggleRemarksModal(order)}
                                disabled={order.remarks === undefined}
                              >
                                View
                              </Button>
                            }
                          </td>

                          <td>
                            {order.status &&
                            order?.status === "Order Deleted" ? (
                              <span style={{ color: "red" }}>
                                {order?.status}
                              </span>
                            ) : (
                              order?.status
                            )}
                          </td>

                          <td>
                            {order.eta !== null ? (
                              <Moment format="DD/MM/YYYY">{order.eta}</Moment>
                            ) : (
                              "N/A"
                            )}
                          </td>

                          <td>
                            {order.status !== undefined &&
                            order.status &&
                            order?.status.toLowerCase() !== "order deleted" ? (
                              <Button
                                as={Link}
                                variant="info"
                                style={{ color: "#fff" }}
                                size="sm"
                                to={`/admin/orders/${order.id}/edit`}
                              >
                                Edit
                              </Button>
                            ) : (
                              <Button variant="info" size="sm" disabled>
                                Edit
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>

              <Col sm="12">
                {loading && (
                  <BlockLoader
                    width="100%"
                    height="400px"
                    innerWidth="100%"
                    innerHeight="100%"
                  />
                )}
              </Col>

              {page < pages && (
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => this.fetchMoreOrders()}
                    disabled={loading}
                  >
                    {loading === true ? "Loading ..." : "Load More"}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </Col>

        {/* Edit Order Modal */}
        <Modal show={this.state.showModal} onHide={this.toggleModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Order</Modal.Title>
          </Modal.Header>

          <Formik
            enableReinitialize={true}
            initialValues={{
              moq_id: order.moq_id !== undefined ? order.moq_id : 0,
              supplier_id:
                order.supplier_id !== undefined ? order.supplier_id : "",
              status: order.status !== undefined ? order.status : "",
              shipping_fee:
                order.shipping_fee !== undefined ? order.shipping_fee : "",
              total_cost: order.total_cost !== undefined ? order.total_cost : 0,
              shipping_company:
                order.shipping_company !== undefined
                  ? order.shipping_company
                  : "",
              order_remarks:
                order.order_remarks !== undefined ? order.order_remarks : "",
            }}
            validationSchema={createSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              this.editOrder(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div>
                <Modal.Body>
                  <Form.Group as={Row} controlId="moq_id">
                    <Form.Label column sm="3">
                      Moq
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        as="select"
                        isValid={touched.moq_id && !errors.moq_id}
                        isInvalid={errors.moq_id && touched.moq_id}
                        name="moq_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.moq_id}
                        disabled
                      >
                        {/* <option value={0}>Choose ...</option> */}

                        {order.moq_id !== undefined && (
                          <option value={order.moq[0].id}>
                            {order.moq[0].moq_number}
                          </option>
                        )}

                        {/* {moqs.data.map((moq, i) => <option key={i} value={moq.id} selected={values.moq_id === moq.id}>{moq.moq_number}</option>)} */}
                      </Form.Control>

                      {errors.moq_id && touched.moq_id && (
                        <Form.Control.Feedback type="invalid">
                          {errors.moq_id}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="supplier">
                    <Form.Label column sm="3">
                      Supplier
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        as="select"
                        isValid={touched.supplier_id && !errors.supplier_id}
                        isInvalid={errors.supplier_id && touched.supplier_id}
                        name="supplier_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.supplier_id}
                        disabled
                      >
                        <option value="">Choose ...</option>

                        {suppliers.map((supplier, i) => (
                          <option key={i} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </Form.Control>

                      {errors.supplier_id && touched.supplier_id && (
                        <Form.Control.Feedback type="invalid">
                          {errors.supplier_id}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="shipping_fee">
                    <Form.Label column sm="3">
                      Shipping Fee
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="number"
                        placeholder="Shipping Fee"
                        name="shipping_fee"
                        isValid={touched.shipping_fee && !errors.shipping_fee}
                        isInvalid={errors.shipping_fee && touched.shipping_fee}
                        value={values.shipping_fee}
                        onChange={handleChange}
                        disabled={
                          values.status !==
                          "Goods arrived at Litudian - Ready to collect"
                        }
                      />

                      {errors.shipping_fee && touched.shipping_fee && (
                        <Form.Control.Feedback type="invalid">
                          {errors.shipping_fee}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="total_cost">
                    <Form.Label column sm="3">
                      Total Cost
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="number"
                        placeholder="Total Cost"
                        name="total_cost"
                        isValid={touched.total_cost && !errors.total_cost}
                        isInvalid={errors.total_cost && touched.total_cost}
                        value={values.total_cost}
                        onChange={handleChange}
                        disabled={
                          values.status !==
                          "Supplier acknowledged - Preparing goods"
                        }
                      />

                      {errors.total_cost && touched.total_cost && (
                        <Form.Control.Feedback type="invalid">
                          {errors.total_cost}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="shipping_company">
                    <Form.Label column sm="3">
                      Shipping Company
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Shipping Company"
                        name="shipping_company"
                        isValid={
                          touched.shipping_company && !errors.shipping_company
                        }
                        isInvalid={
                          errors.shipping_company && touched.shipping_company
                        }
                        value={values.shipping_company}
                        onChange={handleChange}
                      />

                      {errors.shipping_company && touched.shipping_company && (
                        <Form.Control.Feedback type="invalid">
                          {errors.shipping_company}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="status">
                    <Form.Label column sm="3">
                      Status
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        as="select"
                        isValid={touched.status && !errors.status}
                        defaultValue={values.status}
                        isInvalid={errors.status && touched.status}
                        name="status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose...</option>

                        <option value="Submitted - Pending supplier acknowledgement">
                          Submitted - Pending supplier acknowledgement
                        </option>

                        <option value="Supplier acknowledged - Preparing goods">
                          Supplier acknowledged - Preparing goods
                        </option>

                        <option value="At shipper - Loading goods">
                          At shipper - Loading goods
                        </option>

                        <option value="Shipper loaded - Goods in transit">
                          Shipper loaded - Goods in transit
                        </option>

                        <option value="Arrived - Customs clearance">
                          Arrived - Customs clearance
                        </option>

                        <option value="Arrived - At shipper warehouse">
                          Arrived - At shipper warehouse
                        </option>

                        <option value="Goods arrived at Litudian - Ready to collect">
                          Goods arrived at Litudian - Ready to collect
                        </option>

                        <option value="Order cancelled">Order cancelled</option>
                      </Form.Control>

                      {errors.status && touched.status && (
                        <Form.Control.Feedback type="invalid">
                          {errors.status}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="order_remarks">
                    <Form.Label column sm="3">
                      Remarks
                    </Form.Label>

                    <Col sm="9">
                      <FormControl
                        as="textarea"
                        aria-label="With textarea"
                        name="order_remarks"
                        placeholder="write ..."
                        isValid={touched.order_remarks && !errors.order_remarks}
                        isInvalid={
                          errors.order_remarks && touched.order_remarks
                        }
                        value={values.order_remarks}
                        onChange={(e) => {
                          let txt = e.target.value;

                          if (txt.length <= charLimit) {
                            setFieldValue("order_remarks", txt);
                          }
                        }}
                      />

                      {errors.order_remarks && touched.order_remarks && (
                        <Form.Control.Feedback type="invalid">
                          {errors.order_remarks}
                        </Form.Control.Feedback>
                      )}

                      <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                        {charLimit - values.order_remarks.length} characters
                        remaining.
                      </span>
                    </Col>
                  </Form.Group>

                  {values.status === "arrived" && (
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        checked={this.state.checked}
                        onChange={(e) => {
                          this.setState({ checked: e.target.checked });
                        }}
                        type="checkbox"
                        label="Send SMS"
                      />
                    </Form.Group>
                  )}

                  {this.state.checked === true && (
                    <Col sm="12">
                      <Alert variant="warning">
                        Clicking submit will send an SMS with shipping fee as{" "}
                        {values.shipping_fee}. Continue ?
                      </Alert>
                    </Col>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toggleModal}>
                    Close
                  </Button>

                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Edit Order
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>

        {/* Order Remarks Modal */}
        <Modal
          show={this.state.remarksModal}
          onHide={this.toggleRemarksModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Order {order.order_number} Remarks</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {order.remarks !== undefined && (
              <div>
                {order.remarks.map((remark) => (
                  <p style={{ textTransform: "capitalize" }}>
                    {remark.remarks} -{" "}
                    <span>
                      <Moment format="DD/MM/YYYY">{remark.date_created}</Moment>
                    </span>
                  </p>
                ))}
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleRemarksModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orders: state.orders,

    suppliers: state.suppliers,

    moqs: state.moqs,
  };
};

export default connect(mapStateToProps, {
  getOrders,
  editOrder,
  getMoqs,
  getSuppliers,
  getMoreOrders,
  searchOrder,
})(withRouter(Index));
