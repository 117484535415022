import React, { Component } from 'react';
import Layout from './../layouts/Index';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container, ListGroup, Button } from 'react-bootstrap';
import { showOrder } from './../../store/actions/OrderActions';
// import truncate from 'truncate-txt';
// import { titleCase } from './../../utils/index';

class Show extends Component {

    constructor(props) {

        super(props);

        this.state = {

            loading: false
            
        }

    }

    componentDidMount = async () => {

        const { match: { params } } = this.props;

        this.setState({ loading: true });

        // console.log(params);

        const result = await this.props.showOrder(params.id);

        if(result) {

            this.setState({ loading: false });

        }

    }

    render() {

        const { orders: { order }, history } = this.props;

        const { loading } = this.state;

        return (

            <Layout>

                <Col sm="12" style={{ minHeight: '80vh', padding: '1.5em 0' }}>

                    <Container>

                        <Row>

                            {!loading && <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: '1em' }}>

                                <h3>Order {order.order_number}</h3>

                                <Button variant="primary" size="sm" onClick={() => { return history.push(`/admin/orders/${order.id}/edit`) }}>Edit PO</Button>

                            </Col>}

                            {!loading && <Col sm="12">

                                <ListGroup>

                                    <ListGroup.Item style={{ display: 'inline-block' }}>

                                        <span style={{ fontWeight: 'bold' }}>PO Number: </span> <span>{order.order_number}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Campaign Number: </span> <span>{order.moq !== undefined && <Link to={`/admin/moqs/${order.moq[0].id}`}>{order.moq[0].moq_number}</Link>}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>MOQ Bids: </span> <span>{order.moq !== undefined && <Link to={`/admin/moqs/${order.moq[0].id}/bids`}>View Bids</Link>}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Supplier: </span><span>{order.supplier !== undefined ? order.supplier[0].name : 'N/A'}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Shipping: </span><span>{order.shipping}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item style={{ display: 'inline-block' }}>

                                        <span style={{ fontWeight: 'bold' }}>Shipping Fee: </span> <span>{order.shipping_fee}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item style={{ display: 'inline-block' }}>

                                        <span style={{ fontWeight: 'bold' }}>Status: </span> <span>{order.status}</span>

                                    </ListGroup.Item>

                                </ListGroup>

                            </Col>}

                        </Row>

                    </Container>

                </Col>
                
            </Layout>

        )

    }

};

const mapStateToProps = (state, ownProps) => {

    return {

        orders: state.orders,

    }

};

export default connect(mapStateToProps, { showOrder })(withRouter(Show));