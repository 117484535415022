import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = ({ width, height, innerWidth, innerHeight, style }) => {

    return (

        <ContentLoader width={width} height={height} foregroundColor="#e0e0e0" backgroundColor="#eee" style={style}>

            <rect x="0" y="0" rx="0" ry="0" width={innerWidth} height={innerHeight} />

        </ContentLoader>

    )

};

export default Loader;