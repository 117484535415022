import { BIDS, FETCH_MORE_BIDS, GET_BID, SEARCHED_BIDS } from '../types/index';

const initialState = {

    data: [],

    pages: null,

    page: 1,

    next_page: null,

    bid: {}

};

export default function(state = initialState, action) {

    switch(action.type) {

        case BIDS: 

            return {

                ...state,

                data: action.payload.data,

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case FETCH_MORE_BIDS: 

            return {

                ...state,

                data: state.data.concat(action.payload.data),

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case SEARCHED_BIDS: 

            return {

                ...state,

                data: action.payload,

                pages: null,

                page: 1,

                next_page: null

            };

        case GET_BID:

            return {

                ...state,

                bid: action.payload

            };

        default:

            return state;

    }

};