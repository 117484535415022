import React, { Component } from 'react';
import Layout from './../layouts/Index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Container, ListGroup, Button, Image } from 'react-bootstrap';
import { getProduct } from './../../store/actions/ProductActions';
// import truncate from 'truncate-txt';
// import { titleCase } from './../../utils/index';

class Show extends Component {

    constructor(props) {

        super(props);

        this.state = {

            loading: false
            
        }

    }

    componentDidMount = async () => {

        const { match: { params } } = this.props;

        this.setState({ loading: true });

        // console.log(params);

        const result = await this.props.getProduct(params.id);

        if(result) {

            this.setState({ loading: false });

        }

    }

    render() {

        const { products: { product } } = this.props;

        const { loading } = this.state;

        return (

            <Layout>

                <Col sm="12" style={{ minHeight: '80vh', padding: '1.5em 0' }}>

                    <Container>

                        <Row>

                            {!loading && <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: '1em' }}>

                                <h3>Product</h3>

                            </Col>}

                            {!loading && <Col sm="4">

                                {product.images !== undefined && product.images.length > 0 && <Image src={product.images[0]} alt={product.name} rounded style={{ height: '300px', width: '100%', objectFit: 'contain' }} /> }   
                                
                            </Col>}

                            {!loading && <Col sm="8">

                                <ListGroup>

                                    <ListGroup.Item style={{ display: 'inline-block' }}>

                                        <span style={{ fontWeight: 'bold' }}>Name: </span> <span>{product.name}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Description: </span> <br/><div style={{ marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: product.description ? product.description : '' }} />

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Category: </span><span>{product.category_name}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <div style={{ display: 'flex', flex: 'row' }}><span style={{ fontWeight: 'bold' }}>Colors: </span><span><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>{product.readable_colors !== undefined && product.readable_colors !== null && product.readable_colors.map((color, i) => <div key={i} style={{ height: '30px', width: '30px', backgroundColor: color.value, margin: '0 3px' }}></div>)}</div></span></div>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Sizes: </span><span>{product.readable_sizes !== undefined && product.readable_sizes !== null && product.readable_sizes.map((size, i) => <span key={i}>{size.label}, </span>)}</span>

                                    </ListGroup.Item>

                                    {/* <ListGroup.Item>

                                        <h5>Sizes</h5>

                                        <p>{JSON.stringify(product.sizes)}</p>

                                    </ListGroup.Item> */}

                                    {product.images !== undefined && product.images !== null && product.images.length > 1 && <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Images</span>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>{product.images !== undefined && product.images.length > 0 && product.images.map((image, i) => <div key={i} style={{ height: '50px', width: '50px', backgroundImage: `url("${image}")`, backgroundSize: 'cover', margin: '0 3px' }}></div>)}</div>
                                        
                                    </ListGroup.Item>}

                                </ListGroup>

                            </Col>}

                            <Col sm="4"></Col>

                            <Col sm="8">

                                {!loading && <Button style={{ marginTop: '2em' }} variant="success" size="sm" as={Link} to={`/admin/products/${product.id}/edit`}>Edit Product</Button>}

                            </Col>

                        </Row>

                    </Container>

                </Col>
                
            </Layout>

        )

    }

};

const mapStateToProps = (state, ownProps) => {

    return {

        products: state.products,

    }

};

export default connect(mapStateToProps, { getProduct })(Show);
