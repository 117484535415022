import { CATEGORIES, GET_CATEGORY, FETCH_MORE_CATEGORIES } from '../types/index';

const initialState = {

    data: [],

    category: {},

    pages: null,

    page: 1,

    next_page: null

};

export default function(state = initialState, action) {

    switch(action.type) {

        case CATEGORIES: 

            return {

                ...state,

                data: action.payload.data,

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case FETCH_MORE_CATEGORIES: 

            return {

                ...state,

                data: state.data.concat(action.payload.data),

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case GET_CATEGORY: 

            return {

                ...state,

                category: action.payload

            };

        default:

            return state;

    }

};