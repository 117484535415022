import { instance, setAuthToken } from '../../helpers/instance';
import { CATEGORY_PROMOTIONS } from '../types/index';

export const getCategoryPromotions = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('category_promotions');

        if(response.data !== "" && response.data.promotions !== undefined && response.data.pages !== undefined) {

            // console.log(response?.data)

            let payload = {};

            payload.data = response.data.promotions;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: CATEGORY_PROMOTIONS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

}

export const createCategoryPromotion = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`category_promotions`, data);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showCategoryPromotion = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`category_promotions/${id}`);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const updateCategoryPromotion = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`category_promotions/${id}`, data);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};