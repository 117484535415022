import React, { useEffect, useState } from "react";
import Layout from "./../layouts/Index";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { getStatistics } from "./../../store/actions/StatisticsActions";
import BlockLoader from "./../contentloaders/BlockLoader";
import CountUp from "react-countup";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Index(props) {
  // Props
  const {
    statistics: { data: statistics },
  } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    async function fetchStatistics() {
      try {
        setLoading(true);
        await props?.getStatistics();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchStatistics();
  }, []);

  // Functions
  const generateRandomColor = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.8)`;
      colors.push(color);
    }
    return colors;
  };

  let users_past_year = {},
    bids_past_year = {},
    transactions_past_year = {};

  if (statistics?.users_past_year) {
    const unique_months = [
      ...new Set(statistics?.users_past_year?.map((s) => s?.month)),
    ].sort((a, b) => {
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthOrder.indexOf(a) - monthOrder.indexOf(b);
    });
    users_past_year.labels = unique_months;
    const unique_years =
      [...new Set(statistics?.users_past_year?.map((item) => item.year))] || [];
    let datasets = [];
    if (unique_years?.length > 0) {
      let color = generateRandomColor(unique_years?.length);
      for (let i = 0; i < unique_years?.length; i++) {
        let dataset = {};
        dataset.label = unique_years[i];
        let d =
          statistics?.users_past_year?.filter(
            (u) => u?.year === unique_years[i]
          ) || [];
        if (d?.length > 0) {
          // Create a map to store data by month
          const monthMap = {};

          // Fill the map with existing data
          d.forEach((item) => {
            monthMap[item.month] = item;
          });

          // Define the months in order
          const monthsInOrder = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          // Create a new array with zero total_users for missing months
          const resultArray = monthsInOrder.map((month) => ({
            month,
            total_users: monthMap[month] ? monthMap[month].total_users : 0,
            year: monthMap[month] ? monthMap[month].year : unique_years[i],
          }));

          // Sort the result array by month
          resultArray.sort(
            (a, b) =>
              monthsInOrder.indexOf(a.month) - monthsInOrder.indexOf(b.month)
          );
          dataset.data = resultArray?.map((r) => r?.total_users);
        }
        dataset.borderColor = color[i];
        dataset.backgroundColor = color[i];
        datasets.push(dataset);
      }
    }
    users_past_year.datasets = datasets;
  }

  if (statistics?.bids_past_year) {
    const unique_months = [
      ...new Set(statistics?.bids_past_year?.map((s) => s?.month)),
    ].sort((a, b) => {
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthOrder.indexOf(a) - monthOrder.indexOf(b);
    });
    bids_past_year.labels = unique_months;
    const unique_years =
      [...new Set(statistics?.bids_past_year?.map((item) => item.year))] || [];
    let datasets = [];
    if (unique_years?.length > 0) {
      let color = generateRandomColor(unique_years?.length);
      for (let i = 0; i < unique_years?.length; i++) {
        let dataset = {};
        dataset.label = unique_years[i];
        let d =
          statistics?.bids_past_year?.filter(
            (u) => u?.year === unique_years[i]
          ) || [];
        if (d?.length > 0) {
          // Create a map to store data by month
          const monthMap = {};

          // Fill the map with existing data
          d.forEach((item) => {
            monthMap[item.month] = item;
          });

          // Define the months in order
          const monthsInOrder = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          // Create a new array with zero total_paid_bids for missing months
          const resultArray = monthsInOrder.map((month) => ({
            month,
            total_paid_bids: monthMap[month]
              ? monthMap[month].total_paid_bids
              : 0,
            year: monthMap[month] ? monthMap[month].year : unique_years[i],
          }));

          // Sort the result array by month
          resultArray.sort(
            (a, b) =>
              monthsInOrder.indexOf(a.month) - monthsInOrder.indexOf(b.month)
          );
          dataset.data = resultArray?.map((r) => r?.total_paid_bids);
        }
        let color = generateRandomColor(unique_years?.length);
        dataset.borderColor = color[i];
        dataset.backgroundColor = color[i];
        datasets.push(dataset);
      }
    }
    bids_past_year.datasets = datasets;
  }

  if (statistics?.transactions_past_year) {
    const unique_months = [
      ...new Set(statistics?.transactions_past_year?.map((s) => s?.month)),
    ].sort((a, b) => {
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthOrder.indexOf(a) - monthOrder.indexOf(b);
    });
    transactions_past_year.labels = unique_months;
    const unique_years =
      [
        ...new Set(
          statistics?.transactions_past_year?.map((item) => item.year)
        ),
      ] || [];
    let datasets = [];
    if (unique_years?.length > 0) {
      let color = generateRandomColor(unique_years?.length);
      for (let i = 0; i < unique_years?.length; i++) {
        let dataset = {};
        dataset.label = unique_years[i];
        let d =
          statistics?.transactions_past_year?.filter(
            (u) => u?.year === unique_years[i]
          ) || [];
        if (d?.length > 0) {
          // Create a map to store data by month
          const monthMap = {};

          // Fill the map with existing data
          d.forEach((item) => {
            monthMap[item.month] = item;
          });

          // Define the months in order
          const monthsInOrder = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          // Create a new array with zero total_amount for missing months
          const resultArray = monthsInOrder.map((month) => ({
            month,
            total_amount: monthMap[month] ? monthMap[month].total_amount : 0,
            year: monthMap[month] ? monthMap[month].year : unique_years[i],
          }));

          // Sort the result array by month
          resultArray.sort(
            (a, b) =>
              monthsInOrder.indexOf(a.month) - monthsInOrder.indexOf(b.month)
          );
          dataset.data = resultArray?.map((r) => r?.total_amount);
        }
        dataset.borderColor = color[i];
        dataset.backgroundColor = color[i];
        datasets.push(dataset);
      }
    }
    transactions_past_year.datasets = datasets;
  }

  let priviledge = parseInt(localStorage.getItem("ltdn_priv")) || null;

  return (
    <Layout>
      <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
        <Container>
          <Row>
            <Col
              sm="12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "2em",
              }}
            >
              {loading && (
                <BlockLoader
                  width="100%"
                  height="600px"
                  innerWidth="100%"
                  innerHeight="100%"
                  style={{ marginBottom: "2em" }}
                />
              )}
            </Col>
            {!loading && statistics?.total_products && (
              <Col
                sm="6"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "5em",
                }}
              >
                <h3>Total Products</h3>
                <CountUp
                  start={0}
                  end={statistics?.total_products || 0}
                  duration={3.75}
                  separator=""
                  decimal=","
                  prefix=""
                  suffix=""
                />
              </Col>
            )}
            {!loading && statistics?.total_successful_bids && (
              <Col
                sm="6"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "5em",
                }}
              >
                <h3>Total Successful Bids</h3>
                <CountUp
                  start={0}
                  end={statistics?.total_successful_bids || 0}
                  duration={3.75}
                  separator=""
                  decimal=","
                  prefix=""
                  suffix=""
                />
              </Col>
            )}
            {!loading && Object.keys(users_past_year)?.length > 0 && (
              <Col
                sm="6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Line
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Users Past Year",
                      },
                    },
                  }}
                  data={users_past_year}
                />
              </Col>
            )}
            {!loading && Object.keys(bids_past_year)?.length > 0 && (
              <Col
                sm="6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Line
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Bids Past Year",
                      },
                    },
                  }}
                  data={bids_past_year}
                />
              </Col>
            )}
            {!loading &&
              priviledge === 2 &&
              Object.keys(transactions_past_year)?.length > 0 && (
                <Col
                  sm="6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: "Transactions Past Year",
                        },
                      },
                    }}
                    data={transactions_past_year}
                  />
                </Col>
              )}
          </Row>
        </Container>
      </Col>
    </Layout>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    statistics: state.statistics,
  };
};

export default connect(mapStateToProps, { getStatistics })(Index);
