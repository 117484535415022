export const CURRENT_USER = "CURRENT_USER";
export const USERS = "USERS";
export const FETCH_MORE_USERS = "FETCH_MORE_USERS";
export const GET_USER = "GET_USER";
export const SEARCHED_USERS = "SEARCHED_USERS";
export const PRODUCTS = "PRODUCTS";
export const FETCH_MORE_PRODUCTS = "FETCH_MORE_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const SEARCHED_PRODUCTS = "SEARCHED_PRODUCTS";
export const SIZES = "SIZES";
export const COLORS = "COLORS";
export const ORDERS = "ORDERS";
export const FETCH_MORE_ORDERS = "FETCH_MORE_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const SUPPLIERS = "SUPPLIERS";
export const BIDS = "BIDS";
export const GET_BID = "GET_BID";
export const FETCH_MORE_BIDS = "FETCH_MORE_BIDS";
export const SEARCHED_BIDS = "SEARCHED_BIDS";
export const MOQS = "MOQS";
export const FETCH_MORE_MOQS = "FETCH_MORE_MOQS";
export const GET_MOQ = "GET_MOQ";
export const SEARCHED_MOQS = "SEARCHED_MOQS";
export const TRANSACTIONS = "TRANSACTIONS";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const FETCH_MORE_TRANSACTIONS = "FETCH_MORE_TRANSACTIONS";
export const SEARCHED_TRANSACTIONS = "SEARCHED_TRANSACTIONS";
export const CATEGORIES = "CATEGORIES";
export const FETCH_MORE_CATEGORIES = "FETCH_MORE_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const HOME_PROMOTIONS = "HOME_PROMOTIONS";
export const CATEGORY_PROMOTIONS = "CATEGORY_PROMOTIONS";
export const RECOMMENDATIONS = "RECOMMENDATIONS";
export const FETCH_MORE_RECOMMENDATIONS = "FETCH_MORE_RECOMMENDATIONS";
export const UPDATE_RECOMMENDATION = "UPDATE_RECOMMENDATION";
export const STATISTICS = "STATISTICS";
export const PROMO_CODES = "PROMO_CODES";
export const SINGLE_PROMO_CODE = "SINGLE_PROMO_CODE";
