import { PROMO_CODES, SINGLE_PROMO_CODE } from "../types/index";

const initialState = {
  promo_code: null,
  data: [],
};

export default function PromoCodeReducer (state = initialState, action) {
  switch (action.type) {
    case PROMO_CODES:
      return {
        ...state,
        data: action.payload,
      };
    case SINGLE_PROMO_CODE:
      return {
        ...state,
        promo_code: action.payload,
      };
    default:
      return state;
  }
}
