import { instance, setAuthToken } from '../../helpers/instance';
import { ORDERS, FETCH_MORE_ORDERS, GET_ORDER } from '../types/index';

export const getOrders = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('order');

        if(response.data !== "" && response.data.orders !== undefined && response.data.pages !== undefined) {

            let payload = {};

            payload.data = response.data.orders;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: ORDERS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreOrders = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`order?page=${next_page}`);

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.orders;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_ORDERS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const addOrder = (data) => async dispatch => {

    try {

        // data.status = 1;

        setAuthToken(instance);

        const response = await instance.post('order', data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editOrder = (id, data) => async dispatch => {

    try {

        // data.status = 1;

        setAuthToken(instance);

        const response = await instance.patch(`order/${id}`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};


export const showOrder = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`order/${id}`);

        if(response.data !== "") {

            dispatch({ type: GET_ORDER, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchOrder = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/orders`, data);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};