import {
  RECOMMENDATIONS,
  FETCH_MORE_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION,
} from "../types/index";

const initialState = {
  data: [],

  pages: null,

  page: 1,

  next_page: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECOMMENDATIONS:
      return {
        ...state,

        data: action.payload.data,

        pages: action.payload.pages,

        page: action.payload.page,

        next_page: action.payload.next_page,
      };

    case FETCH_MORE_RECOMMENDATIONS:
      return {
        ...state,

        data: state.data.concat(action.payload.data),

        pages: action.payload.pages,

        page: action.payload.page,

        next_page: action.payload.next_page,
      };

    case UPDATE_RECOMMENDATION:
      const data = state.data;
      const index = data.findIndex((obj) => obj.id === action?.payload?.id);
      if (index !== -1) {
        data[index] = action.payload;
      }
      return {
        ...state,
        data: data,
      };
    default:
      return state;
  }
}
