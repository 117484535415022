import * as yup from 'yup';

export const createSchema = yup.object().shape({

    points: yup.number().required(),

    amount: yup.number().required(),

    msisdn: yup.string().required(),

});