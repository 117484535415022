import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Modal,
  Form,
  FormControl,
  Alert,
} from "react-bootstrap";
import BlockLoader from "./../contentloaders/BlockLoader";
// import ContentLoader from "react-content-loader";
import {
  getBids,
  createBid,
  getMoreBids,
  editBid,
  searchBid,
  searchedBids,
} from "./../../store/actions/BidActions";
import { getMoqs } from "./../../store/actions/MoqActions";
import { getSizes } from "./../../store/actions/SizeActions";
import { getColors } from "./../../store/actions/ColorActions";
import { getProduct } from "./../../store/actions/ProductActions";
import { Formik } from "formik";
import { createSchema, statusSchema } from "../../helpers/validation/bid";
import { toast } from "react-toastify";
import Select from "react-select";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import LoadingOverlay from "react-loading-overlay-ts";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      moqs_data: [],

      bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],

      readable_colors: [],

      readable_sizes: [],

      product: {},

      loading: false,

      bid: {},

      editModal: false,

      search: "",

      searched: false,

      checked: false,

      moq: null,

      priviledge: 0,

      edit_bid_loading: false,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const res = await this.props.getBids();

    if (res) {
      this.setState({ loading: false });
    }

    await this.props.getSizes();

    await this.props.getColors();

    const result = await this.props.getMoqs();

    await this.getMoqs(result);
  };

  toggleEditModal = async (bid = null) => {
    // console.log(bid);

    const { moqs_data } = this.state;

    if (bid !== null) {
      this.setState({ edit_bid_loading: true });

      if (bid.product !== undefined) {
        await this.setProductSizesAndColors(bid.product[0].id);
      }

      // console.log(bid.product[0].id);

      const result = await this.props.getMoqs();

      await this.getMoqs(result);

      let moq = moqs_data.find((m) => {
        return m.value === bid.moq_id;
      });

      // console.log(bid.bid_attributes.length);

      // console.log(bid.bid_attributes);

      if (bid.bid_attributes !== undefined && bid.bid_attributes.length === 0) {
        this.setState({ moq });
      } else {
        this.setState({ bid_attributes: bid.bid_attributes, moq });
      }

      this.setState({ edit_bid_loading: false });
    }

    if (bid === null) {
      this.setState({
        bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],
        bid: {},
        editModal: !this.state.editModal,
      });
    } else {
      this.setState({
        editModal: !this.state.editModal,
        bid: bid === null ? {} : bid,
      });
    }
  };

  handleInputChange = (e, index) => {
    const { bid_attributes } = this.state;

    const { name, value } = e.target;

    let attributes = [...bid_attributes];

    attributes[index][name] = parseInt(value);

    this.setState({ bid_attributes: attributes });
  };

  handleRemoveClick = (index) => {
    const { bid_attributes } = this.state;

    let list = [...bid_attributes];

    list.splice(index, 1);

    this.setState({ bid_attributes: list });
  };

  handleAddClick = () => {
    // console.log('you clicked me');

    let { bid_attributes } = this.state;

    this.setState({
      bid_attributes: [
        ...bid_attributes,
        { size_id: 0, quantity: 0, color_id: 0 },
      ],
    });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  createBid = async (values) => {
    try {
      this.setState({ loading: true });

      let data = [],
        quantity = 0;

      const { bid_attributes, checked } = this.state;

      if (
        bid_attributes.length > 0 &&
        bid_attributes[0].size_id !== 0 &&
        bid_attributes[0].quantity !== 0 &&
        bid_attributes[0].color_id !== 0
      ) {
        data = bid_attributes;

        bid_attributes.forEach((attribute) => {
          // console.log(attribute);

          quantity = quantity + attribute.quantity;
        });

        if (parseInt(values.quantity) !== quantity) {
          toast.warning(
            "Sum Of Quantity On Bid Attrbutes Must Be Same As Quantity Provided !",
            {
              position: "top-right",
            }
          );

          return null;
        }
      }

      if (checked === true) {
        values.send_sms = 1;
      } else {
        delete values.send_sms;
      }

      // console.log(values);

      values.status = 0;

      // values.amount = 5;

      values.bid_attributes = data;

      // console.log(values);

      const result = await this.props.createBid(values);

      if (result) {
        // console.log(result);

        toast.success("New Bid Created Successfully !", {
          position: "top-right",
        });

        this.setState({
          showModal: false,
          bid_attributes: [{ size_id: 0, quantity: 0, color_id: 0 }],
          loading: false,
        });

        await this.props.getBids();
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });

      if (
        error &&
        error.response.data !== undefined &&
        error.response.data.message !== undefined
      ) {
        return toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      }

      toast.error("Please try again later !", {
        position: "top-right",
      });
    }
  };

  editBid = async (values) => {
    try {
      this.setState({ loading: true });

      let data = [],
        quantity = 0;

      const { bid_attributes, bid } = this.state;

      if (
        bid_attributes.length > 0 &&
        bid_attributes[0].size_id !== 0 &&
        bid_attributes[0].quantity !== 0 &&
        bid_attributes[0].color_id !== 0
      ) {
        data = bid_attributes;

        bid_attributes.forEach((attribute) => {
          quantity = quantity + attribute.quantity;
        });

        if (parseInt(values.quantity) !== quantity) {
          toast.warning(
            "Sum Of Quantity On Bid Attrbutes Must Be Same As Quantity Provided !",
            {
              position: "top-right",
            }
          );

          return null;
        }
      }

      // if(bid.user !== undefined) {

      //     values.msisdn = (bid.user[0].msisdn).toString();

      // }

      values.moq_id =
        values.moq_id.value !== undefined ? values.moq_id.value : null;

      // values.shipping = bid.shipping;

      values.bid_attributes = data;

      const result = await this.props.editBid(bid.id, values);

      if (result) {
        // console.log(result);

        toast.success("Bid Updated Successfully !", {
          position: "top-right",
        });

        this.setState({ editModal: false, loading: false });

        await this.props.getBids();
      }
    } catch (error) {
      // console.log(error);

      this.setState({ loading: false });

      toast.warning(
        "We could not finish your request. Please try again later !",
        {
          position: "top-right",
        }
      );
    }
  };

  getMoqs = async (result) => {
    try {
      // console.log(result);

      if (result.moqs !== undefined) {
        let moqs = result.moqs,
          data = [];

        for (let i = 0; i < moqs.length; i++) {
          // console.log(moqs[i]);

          data.push({
            value: moqs[i].id,

            label: moqs[i].product[0].name,

            product_id: moqs[i].product[0].id,

            min_quantity: moqs[i].min_quantity,
          });
        }

        // console.log(data);

        this.setState({ moqs_data: data });

        return data;
      }

      return null;

      // const result = await this.props.getMoqs();

      // if(result) {

      //     console.log(result);

      // let colours = result.colors, selectedColors = [];

      // this.setState({ selectedColors });

      // }
    } catch (error) {
      console.log(error);
    }
  };

  setProductSizesAndColors = async (id) => {
    try {
      const product = await this.props.getProduct(id);

      if (product) {
        this.setState({
          readable_colors: product.readable_colors,
          readable_sizes: product.readable_sizes,
          product,
        });
      }

      return product;
    } catch (error) {
      console.log(error);
    }
  };

  fetchMoreBids = async () => {
    try {
      this.setState({ loading: true });

      const {
        bids: { next_page },
      } = this.props;

      const result = await this.props.getMoreBids(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchBid = async () => {
    try {
      let obj = { bid_number: this.state.search };

      this.setState({ loading: true });

      const result = await this.props.searchBid(obj);

      if (result) {
        await this.props.searchedBids(result);

        this.setState({ loading: false, searched: true });

        toast.success("Bid Found !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("Bid Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13) {
        await this.searchBid();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  refresh = async () => {
    this.setState({ loading: true });

    const result = await this.props.getBids();

    if (result) {
      this.setState({ loading: false, search: "", searched: false });
    }
  };

  render() {
    const {
      bids,
      bids: { page, pages },
    } = this.props;

    const {
      moqs_data,
      bid_attributes,
      readable_colors,
      readable_sizes,
      product,
      loading,
      editModal,
      bid,
      search,
      searched,
      moq,
      edit_bid_loading,
      priviledge,
    } = this.state;

    // console.log(bid_attributes);

    return (
      <LoadingOverlay active={edit_bid_loading} spinner text="Wait ...">
        <Layout>
          <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
            <Container>
              <Row>
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  <Form
                    inline
                    onSubmit={async (e) => {
                      e.preventDefault();
                      await this.searchBid();
                    }}
                  >
                    <FormControl
                      type="text"
                      placeholder="Bid Number"
                      value={search}
                      className="mr-sm-2"
                      onKeyDown={this.keyPress}
                      onChange={this.handleChange}
                    />

                    <Button variant="outline-success" type="submit">
                      Search
                    </Button>

                    {searched === true && (
                      <Button
                        variant="outline-success"
                        type="button"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          this.refresh();
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Form>
                </Col>

                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1em",
                  }}
                >
                  {!loading && <h3>Bids</h3>}

                  {loading && (
                    <div style={{ width: "100px", height: "35px" }}>
                      <BlockLoader
                        width="100px"
                        height="35"
                        innerHeight="100%"
                        innerWidth="100%"
                        style={{ margin: "0", padding: "0" }}
                      />
                    </div>
                  )}

                  {!loading && (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => this.toggleModal()}
                    >
                      New Bid
                    </Button>
                  )}

                  {loading && (
                    <div style={{ width: "100px", height: "35px" }}>
                      <BlockLoader
                        width="100px"
                        height="35"
                        innerHeight="100%"
                        innerWidth="100%"
                        style={{ margin: "0", padding: "0" }}
                      />
                    </div>
                  )}
                </Col>

                <Col sm="12">
                  {!loading && (
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      variant="light"
                      responsive="sm"
                    >
                      <thead>
                        <tr>
                          <th>#</th>

                          <th>Product</th>

                          <th>Bidder</th>

                          <th>Bidder Contact</th>

                          <th>Bid Number</th>

                          <th>Bid Attributes</th>

                          <th>Quantity</th>

                          <th>Amount</th>

                          <th>Status</th>

                          <th>MOQ</th>

                          <th>#</th>
                        </tr>
                      </thead>

                      <tbody>
                        {bids.data.map((bid, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>

                            <td>
                              <Link to={`/admin/products/${bid.product[0].id}`}>
                                {bid.product[0].name}
                              </Link>
                            </td>

                            <th>
                              {bid.user !== undefined
                                ? `${bid.user[0].first_name} ${bid.user[0].last_name}`
                                : "N/A"}
                            </th>

                            <td>
                              {bid.user !== undefined
                                ? bid.user[0].msisdn
                                : "N/A"}
                            </td>

                            <td>{bid.bid_number}</td>

                            <td>
                              <ul>
                                {bid.bid_attributes.map((attribute, i) => {
                                  return (
                                    <li key={i}>
                                      {attribute.quantity}{" "}
                                      {attribute.colors !== undefined
                                        ? attribute.colors.label
                                        : ""}{" "}
                                      size{" "}
                                      {attribute.sizes !== undefined
                                        ? attribute.sizes.label
                                        : ""}
                                    </li>
                                  );
                                })}
                              </ul>
                            </td>

                            <td>{bid.quantity}</td>

                            <td>{bid.amount}</td>

                            <td>
                              {bid.status === 0 && (
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    padding: "3px 10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Unpaid
                                </span>
                              )}

                              {bid.status === 1 && (
                                <span
                                  style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    padding: "3px 10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Paid
                                </span>
                              )}

                              {bid.status === 2 && "Collected"}
                            </td>

                            <td>
                              <Link to={`/admin/moqs/${bid.moq_id}`}>View</Link>
                            </td>

                            <td>
                              <Button
                                variant="info"
                                size="sm"
                                as={Link}
                                to={`/admin/bids/${bid.id}`}
                                style={{ margin: "10px 0" }}
                              >
                                View Bid
                              </Button>

                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => this.toggleEditModal(bid)}
                              >
                                Edit Bid
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>

                {/* {loading && <BlockLoader width="300" height="300" innerWidth="150" innerHeight="150" />} */}

                <Col sm="12">
                  {loading && (
                    <BlockLoader
                      width="100%"
                      height="400px"
                      innerWidth="100%"
                      innerHeight="100%"
                    />
                  )}
                </Col>

                {page < pages && (
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "2em",
                    }}
                  >
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => this.fetchMoreBids()}
                      disabled={loading}
                    >
                      {loading === true ? "Loading ..." : "Load More"}
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </Col>

          {/* New Bid Modal */}
          <LoadingOverlay
            active={loading}
            spinner
            text="Wait ..."
            styles={{ zIndex: 1000 }}
          >
            <Modal
              show={this.state.showModal}
              onHide={this.toggleModal}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>New Bid</Modal.Title>
              </Modal.Header>

              <Formik
                initialValues={{
                  moq_id: "",
                  quantity: "",
                  shipping: "",
                  msisdn: "",
                }}
                validationSchema={createSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // console.log(values);

                  // if(values.quantity < values.moq_id.min_quantity) {

                  //     return toast.warning(`Minimum Quantity To Ordered Is ${values.moq_id.min_quantity} !`, {

                  //         position: "top-right"

                  //     });

                  // }

                  values.moq_id =
                    values.moq_id.value !== undefined
                      ? values.moq_id.value.toString()
                      : null;

                  values.quantity = values.quantity.toString();

                  values.msisdn = values.msisdn.toString();

                  this.createBid(values);

                  // console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <div>
                    <Modal.Body>
                      <Form.Group as={Row} controlId="msisdn">
                        <Form.Label column sm="3">
                          Phone Number
                        </Form.Label>

                        <Col sm="9">
                          <Form.Control
                            type="number"
                            placeholder="Phone Number"
                            name="msisdn"
                            isValid={touched.msisdn && !errors.msisdn}
                            isInvalid={errors.msisdn && touched.msisdn}
                            value={values.msisdn}
                            onChange={handleChange}
                          />

                          {errors.msisdn && touched.msisdn && (
                            <Form.Control.Feedback type="invalid">
                              {errors.msisdn}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="moq_id">
                        <Form.Label column sm="3">
                          Product
                        </Form.Label>

                        <Col sm="9">
                          <Select
                            value={values.moq_id}
                            options={moqs_data}
                            onChange={async (value) => {
                              // console.log(value);

                              setFieldValue("moq_id", value);

                              this.setState({ loading: true });

                              await this.setProductSizesAndColors(
                                value.product_id
                              );

                              this.setState({ loading: false });
                            }}
                            onBlur={() => {
                              setFieldTouched("moq_id", true);
                            }}
                            touched={touched.moq_id}
                          />

                          {/* <Form.Control as="select" isValid={touched.moq_id && !errors.moq_id} defaultValue={values.moq_id} isInvalid={errors.moq_id && touched.moq_id} name="moq_id" onChange={handleChange} onBlur={handleBlur}>

                                                <option value="">Choose ...</option>

                                                {moqs.data.map((moq, i) => <option key={i} value={moq.id}>{moq.product[0].name}</option>)}

                                            </Form.Control> */}

                          {/* {errors.moq_id && touched.moq_id && <Form.Control.Feedback type="invalid">{errors.moq_id}</Form.Control.Feedback>} */}

                          {errors.moq_id && touched.moq_id && (
                            <Form.Text style={{ color: "#dc3545" }}>
                              {errors.moq_id}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="quantity">
                        <Form.Label column sm="3">
                          Quantity
                        </Form.Label>

                        <Col sm="9">
                          <Form.Control
                            type="number"
                            placeholder="Quantity"
                            name="quantity"
                            isValid={touched.quantity && !errors.quantity}
                            isInvalid={errors.quantity && touched.quantity}
                            value={values.quantity}
                            onChange={handleChange}
                          />

                          {errors.quantity && touched.quantity && (
                            <Form.Control.Feedback type="invalid">
                              {errors.quantity}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="shipping">
                        <Form.Label column sm="3">
                          Shipping
                        </Form.Label>

                        <Col sm="9">
                          <Form.Control
                            as="select"
                            isValid={touched.shipping && !errors.shipping}
                            defaultValue={values.shipping}
                            isInvalid={errors.shipping && touched.shipping}
                            name="shipping"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Choose ...</option>

                            <option value="Air">Air</option>

                            <option value="Sea">Sea</option>
                          </Form.Control>

                          {errors.shipping && touched.shipping && (
                            <Form.Control.Feedback type="invalid">
                              {errors.shipping}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                      </Form.Group>

                      {(product.readable_colors !== null ||
                        product.readable_sizes !== null) && (
                        <Form.Group as={Row} controlId="shipping">
                          <Form.Label column sm="3">
                            Bid Attributes
                          </Form.Label>

                          <Col sm="9">
                            {bid_attributes.map((x, i) => {
                              return (
                                <Row style={{ marginBottom: "10px" }} key={i}>
                                  <Col sm="3">
                                    <Form.Control
                                      as="select"
                                      name="size_id"
                                      value={x.size_id}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                    >
                                      <option value="">Sizes ...</option>

                                      {readable_sizes !== null &&
                                        readable_sizes.map((size, i) => (
                                          <option key={i} value={size.id}>
                                            {size.label}
                                          </option>
                                        ))}
                                    </Form.Control>
                                  </Col>

                                  <Col sm="3">
                                    <Form.Control
                                      as="select"
                                      name="color_id"
                                      value={x.color_id}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                    >
                                      <option value="">Colors ...</option>

                                      {readable_colors !== null &&
                                        readable_colors.map((color, i) => (
                                          <option key={i} value={color.id}>
                                            {color.label}
                                          </option>
                                        ))}
                                    </Form.Control>
                                  </Col>

                                  <Col sm="3">
                                    <Form.Control
                                      type="number"
                                      placeholder="Quantity"
                                      name="quantity"
                                      value={x.quantity}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                    />
                                  </Col>

                                  <Col sm="3">
                                    {bid_attributes.length - 1 === i && (
                                      <Button
                                        variant="success"
                                        style={{ marginRight: "10px" }}
                                        onClick={this.handleAddClick}
                                      >
                                        <FiPlus />
                                      </Button>
                                    )}

                                    {bid_attributes.length !== 1 && (
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          this.handleRemoveClick(i)
                                        }
                                      >
                                        <FiTrash2 />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>

                          {/* <Col sm="12"><div style={{ marginTop: 20 }}>{JSON.stringify(bid_attributes)}</div></Col> */}
                        </Form.Group>
                      )}

                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          checked={this.state.checked}
                          onChange={(e) => {
                            this.setState({ checked: e.target.checked });
                          }}
                          type="checkbox"
                          label="Send SMS"
                        />
                      </Form.Group>

                      {this.state.checked === true && (
                        <Col sm="12">
                          <Alert variant="warning">
                            An SMS with bid details will be sent to{" "}
                            {values.msisdn}. Proceed ?
                          </Alert>
                        </Col>
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.toggleModal}>
                        Close
                      </Button>

                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        Create Bid
                      </Button>
                    </Modal.Footer>
                  </div>
                )}
              </Formik>
            </Modal>
          </LoadingOverlay>

          {/* Edit Color Modal */}
          <Modal show={editModal} onHide={this.toggleEditModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Bid</Modal.Title>
            </Modal.Header>

            <Formik
              enableReinitialize
              initialValues={{
                status: bid.status !== undefined ? bid.status : 0,
                quantity: bid.quantity !== undefined ? bid.quantity : "",
                moq_id: moq !== undefined ? moq : "",
                msisdn:
                  bid.user !== undefined ? bid.user[0].msisdn.toString() : "",
                shipping: bid.shipping !== undefined ? bid.shipping : "",
              }}
              validationSchema={statusSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                values.quantity = values.quantity.toString();

                this.editBid(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <div>
                  <Modal.Body>
                    <Form.Group as={Row} controlId="msisdn">
                      <Form.Label column sm="12">
                        Phone Number
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="number"
                          placeholder="Phone Number"
                          name="msisdn"
                          isValid={touched.msisdn && !errors.msisdn}
                          isInvalid={errors.msisdn && touched.msisdn}
                          value={values.msisdn}
                          onChange={handleChange}
                          disabled={priviledge === 1 || priviledge === 2}
                        />

                        {errors.msisdn && touched.msisdn && (
                          <Form.Control.Feedback type="invalid">
                            {errors.msisdn}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="moq_id">
                      <Form.Label column sm="12">
                        Product
                      </Form.Label>

                      <Col sm="12">
                        <Select
                          isDisabled
                          defaultValue={values.moq_id}
                          options={moqs_data}
                          onChange={async (value) => {
                            // console.log(value);

                            setFieldValue("moq_id", value);

                            this.setState({ loading: true });

                            await this.setProductSizesAndColors(
                              value.product_id
                            );

                            this.setState({ loading: false });
                          }}
                          onBlur={() => {
                            setFieldTouched("moq_id", true);
                          }}
                          touched={touched.moq_id}
                        />

                        {/* <Form.Control as="select" isValid={touched.moq_id && !errors.moq_id} defaultValue={values.moq_id} isInvalid={errors.moq_id && touched.moq_id} name="moq_id" onChange={handleChange} onBlur={handleBlur}>

                                                <option value="">Choose ...</option>

                                                {moqs.data.map((moq, i) => <option key={i} value={moq.id}>{moq.product[0].name}</option>)}

                                            </Form.Control> */}

                        {/* {errors.moq_id && touched.moq_id && <Form.Control.Feedback type="invalid">{errors.moq_id}</Form.Control.Feedback>} */}

                        {errors.moq_id && touched.moq_id && (
                          <Form.Text style={{ color: "#dc3545" }}>
                            {errors.moq_id}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="quantity">
                      <Form.Label column sm="12">
                        Quantity
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          type="number"
                          placeholder="Quantity"
                          name="quantity"
                          isValid={touched.quantity && !errors.quantity}
                          isInvalid={errors.quantity && touched.quantity}
                          value={values.quantity}
                          onChange={handleChange}
                        />

                        {errors.quantity && touched.quantity && (
                          <Form.Control.Feedback type="invalid">
                            {errors.quantity}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="shipping">
                      <Form.Label column sm="12">
                        Shipping
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          as="select"
                          isValid={touched.shipping && !errors.shipping}
                          defaultValue={values.shipping}
                          isInvalid={errors.shipping && touched.shipping}
                          name="shipping"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Choose ...</option>

                          <option value="Air">Air</option>

                          <option value="Sea">Sea</option>
                        </Form.Control>

                        {errors.shipping && touched.shipping && (
                          <Form.Control.Feedback type="invalid">
                            {errors.shipping}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>

                    {(product.readable_colors !== null ||
                      product.readable_sizes !== null) && (
                      <Form.Group as={Row} controlId="shipping">
                        <Form.Label column sm="12">
                          Bid Attributes
                        </Form.Label>

                        <Col sm="12">
                          {bid_attributes !== undefined &&
                            bid_attributes.map((x, i) => {
                              return (
                                <Row style={{ marginBottom: "10px" }} key={i}>
                                  <Col sm="3">
                                    <Form.Control
                                      as="select"
                                      name="size_id"
                                      value={x.size_id}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                    >
                                      <option value="">Sizes ...</option>

                                      {readable_sizes !== null &&
                                        readable_sizes.map((size, i) => (
                                          <option
                                            key={i}
                                            value={size.id}
                                            selected={x.size_id === size.id}
                                          >
                                            {size.label}
                                          </option>
                                        ))}
                                    </Form.Control>
                                  </Col>

                                  <Col sm="3">
                                    <Form.Control
                                      as="select"
                                      name="color_id"
                                      value={x.color_id}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                    >
                                      <option value="">Colors ...</option>

                                      {readable_colors !== null &&
                                        readable_colors.map((color, i) => (
                                          <option
                                            key={i}
                                            value={color.id}
                                            selected={x.color_id === color.id}
                                          >
                                            {color.label}
                                          </option>
                                        ))}
                                    </Form.Control>
                                  </Col>

                                  <Col sm="3">
                                    <Form.Control
                                      type="number"
                                      placeholder="Quantity"
                                      name="quantity"
                                      value={x.quantity}
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                      disabled={loading}
                                    />
                                  </Col>

                                  <Col sm="3">
                                    {bid_attributes.length - 1 === i && (
                                      <Button
                                        variant="success"
                                        style={{ marginRight: "10px" }}
                                        onClick={this.handleAddClick}
                                      >
                                        <FiPlus />
                                      </Button>
                                    )}

                                    {bid_attributes.length !== 1 && (
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          this.handleRemoveClick(i)
                                        }
                                      >
                                        <FiTrash2 />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              );
                            })}
                        </Col>

                        {/* <Col sm="12"><div style={{ marginTop: 20 }}>{JSON.stringify(bid_attributes)}</div></Col> */}
                      </Form.Group>
                    )}

                    <Form.Group as={Row} controlId="status">
                      <Form.Label column sm="12">
                        Status
                      </Form.Label>

                      <Col sm="12">
                        <Form.Control
                          as="select"
                          isValid={touched.status && !errors.status}
                          defaultValue={values.status}
                          isInvalid={errors.status && touched.status}
                          name="status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={priviledge !== 2}
                        >
                          <option>Choose ...</option>

                          <option
                            value={0}
                            selected={
                              bid.status !== undefined && bid.status === 0
                                ? true
                                : false
                            }
                          >
                            Unpaid
                          </option>

                          <option
                            value={1}
                            selected={
                              bid.status !== undefined && bid.status === 1
                                ? true
                                : false
                            }
                          >
                            Paid
                          </option>

                          <option
                            value={2}
                            selected={
                              bid.status !== undefined && bid.status === 2
                                ? true
                                : false
                            }
                          >
                            Collected
                          </option>
                        </Form.Control>

                        {errors.status && touched.status && (
                          <Form.Control.Feedback type="invalid">
                            {errors.status}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        this.toggleEditModal(null);
                      }}
                    >
                      Close
                    </Button>

                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      Edit Bid
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Modal>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bids: state.bids,

    moqs: state.moqs,

    sizes: state.sizes,

    colors: state.colors,
  };
};

export default connect(mapStateToProps, {
  getBids,
  getMoqs,
  createBid,
  getSizes,
  getColors,
  getProduct,
  getMoreBids,
  editBid,
  searchBid,
  searchedBids,
})(Index);
