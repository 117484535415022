import { instance, setAuthToken } from '../../helpers/instance';
import { SUPPLIERS } from '../types/index';

export const getSuppliers = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('suppliers');

        if(response.data !== "" && response.data.suppliers !== undefined) {

            dispatch({ type: SUPPLIERS, payload: response.data.suppliers });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const addSupplier = (data) => async dispatch => {

    try {

        data.status = 1;

        setAuthToken(instance);

        const response = await instance.post('suppliers', data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editSupplier = (id, data) => async dispatch => {

    try {

        data.status = 1;

        setAuthToken(instance);

        const response = await instance.patch(`suppliers/${id}`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};