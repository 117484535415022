import * as yup from 'yup';

export const createSchema = yup.object().shape({

    moq_id: yup.string().required(),

    supplier_id: yup.string().required(),

    shipping_fee: yup.string(),

    status: yup.string().required(),

    total_cost: yup.number(),

    order_remarks: yup.string()

});