import { instance, setAuthToken } from '../../helpers/instance';
import { COLORS } from '../types/index';

export const getAllColors = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('colors?paginate=false');

        if(response.data !== "") {

            dispatch({ type: COLORS, payload: response.data.colors });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getColors = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('colors');

        if(response.data !== "") {

            dispatch({ type: COLORS, payload: response.data.colors });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const addColor = (data) => async dispatch => {

    try {

        data.status = 1;

        setAuthToken(instance);

        const response = await instance.post('colors', data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editColor = (id, data) => async dispatch => {

    try {

        data.status = 1;

        setAuthToken(instance);

        const response = await instance.patch(`colors/${id}`, data);

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};