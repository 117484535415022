import { instance, setAuthToken } from '../../helpers/instance';
import { HOME_PROMOTIONS } from '../types/index';

export const getHomePromotions = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('home_promotions');

        if(response.data !== "" && response.data.promotions !== undefined && response.data.pages !== undefined) {

            let payload = {};

            payload.data = response.data.promotions;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: HOME_PROMOTIONS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

}

export const createHomePromotion = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`home_promotions`, data);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showHomePromotion = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`home_promotions/${id}`);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const updateHomePromotion = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`home_promotions/${id}`, data);

        return response.data;
        
    } catch (error) {

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};