import { instance, setAuthToken } from '../../helpers/instance';
import { MOQS, GET_MOQ, FETCH_MORE_MOQS, SEARCHED_MOQS } from '../types/index';

export const getMoqs = () => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get('moq');

        if(response.data !== "" && response.data.moqs !== undefined && response.data.pages !== undefined) {

            let payload = {};

            payload.data = response.data.moqs;

            payload.pages = response.data.pages;

            payload.page = 1;

            payload.next_page = 2;

            dispatch({ type: MOQS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getMoreMoqs = (next_page) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`moq?page=${next_page}`);

        if(!response.data.hasOwnProperty('moqs')) {

            return response.data;

        };

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.moqs;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_MOQS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const addMoq = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post('moq', data);

        if(response.data !== "") {

            dispatch({ type: GET_MOQ, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const showMoq = (id) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.get(`moq/${id}`);

        if(response.data !== "") {

            dispatch({ type: GET_MOQ, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const editMoq = (id, data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.patch(`moq/${id}`, data);

        if(response.data !== "") {

            dispatch({ type: GET_MOQ, payload: response.data });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const clearMoq = (id) => async dispatch => dispatch({ type: GET_MOQ, payload: {} })

export const searchMoq = (data) => async dispatch => {

    try {

        setAuthToken(instance);

        const response = await instance.post(`search/moqs`, data);

        if (
          response?.data
        ) {
          let payload = {};

          payload.data = response.data;

          payload.pages = null;

          payload.page = 1;

          payload.next_page = null;

          dispatch({ type: MOQS, payload });
        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const searchMoqByNumber = (data) => async (dispatch) => {
  try {
    setAuthToken(instance);

    const response = await instance.post(`search/campaigns`, data);

    return response.data;
  } catch (error) {
    // console.log(error);

    if (error.response !== undefined && error.response.status === 401) {
      return (window.location.href = "/signin");
    }

    throw error;
  }
};

export const searchedMoqs = (moqs) => async dispatch => {

    try {

        let data = [];

        for(let i = 0; i < moqs.length; i++) {

            setAuthToken(instance);

            const response = await instance.get(`moq/${moqs[i].id}`);

            if(response.data !== "" && response.data.id !== undefined) {

                data.push(response.data);

            }

        }

        if(data.length > 0) {

            dispatch({ type: SEARCHED_MOQS, payload: data });

        }

        return data;
        
    } catch (error) {

        throw(error);
        
    }

};