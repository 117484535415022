import React, { Component } from 'react';
import Layout from './../layouts/Index';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import { showTransaction } from './../../store/actions/TransactionActions';
// import truncate from 'truncate-txt';
// import { titleCase } from './../../utils/index';

class Show extends Component {

    constructor(props) {

        super(props);

        this.state = {

            loading: false
            
        }

    }

    componentDidMount = async () => {

        const { match: { params } } = this.props;

        this.setState({ loading: true });

        // console.log(params);

        const result = await this.props.showTransaction(params.id);

        if(result) {

            this.setState({ loading: false });

        }

    }

    render() {

        const { transactions: { transaction } } = this.props;

        const { loading } = this.state;

        return (

            <Layout>

                <Col sm="12" style={{ minHeight: '80vh', padding: '1.5em 0' }}>

                    <Container>

                        <Row>

                            {!loading && <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: '1em' }}>

                                <h3>Transaction Details</h3>

                            </Col>}

                            {!loading && <Col sm="12">

                                <ListGroup>

                                    <ListGroup.Item style={{ display: 'inline-block' }}>

                                        <span style={{ fontWeight: 'bold' }}>Account Number: </span> <span>{transaction.account_number}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Amount: </span> <span>{transaction.amount}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Description: </span> <span>{transaction.description}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Reference: </span><span>{transaction.reference}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Sender Name(s): </span><span>{transaction.user}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Sender Phone Number: </span><span>{transaction.sender}</span>

                                    </ListGroup.Item>

                                </ListGroup>

                            </Col>}

                        </Row>

                    </Container>

                </Col>
                
            </Layout>

        )

    }

};

const mapStateToProps = (state, ownProps) => {

    return {

        transactions: state.transactions,

    }

};

export default connect(mapStateToProps, { showTransaction })(Show);