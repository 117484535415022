import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from './../layouts/Index';
import { connect } from 'react-redux';
import Moment from 'react-moment';
// import { Link } from 'react-router-dom';
import { Row, Col, Container, ListGroup, Image, Button } from 'react-bootstrap';
import { showMoq } from './../../store/actions/MoqActions';

class Show extends Component {

    constructor(props) {

        super(props);

        this.state = {

            loading: false,

            priviledge: 0
            
        }

    }

    componentDidMount = async () => {

        const { match: { params } } = this.props;

        this.setState({ loading: true, edge: parseInt(localStorage.getItem('ltdn_privprivil')) });

        // console.log(params);

        const result = await this.props.showMoq(params.id);

        if(result) {

            this.setState({ loading: false });

        }

    }

    render() {

        const { moqs: { moq } } = this.props;

        const { loading, priviledge } = this.state;

        return (

            <Layout>

                <Col sm="12" style={{ minHeight: '80vh', padding: '1.5em 0' }}>

                    <Container>

                        <Row>

                            {!loading && <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: '1em' }}>

                                <h3>MOQ Details</h3>

                            </Col>}

                            {!loading && <Col sm="8">
                                
                                <ListGroup>

                                    <ListGroup.Item>
                                        
                                        <span style={{ fontWeight: 'bold' }}>Campaign Number: </span><span>{moq.moq_number}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        
                                        <span style={{ fontWeight: 'bold' }}>Product Name: </span><span>{moq.product !== undefined ? moq.product[0].name : ''}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Source: </span> <span>{moq.source}</span>

                                    </ListGroup.Item>

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Price: </span><span>{moq.readable_price !== undefined ? moq.readable_price : moq.price}</span>

                                    </ListGroup.Item>

                                    {priviledge === 2 && <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>Supplier Price: </span><span>{moq.readable_supplier_price !== undefined ? moq.readable_supplier_price : moq.supplier_price}</span>

                                    </ListGroup.Item>} 

                                    {/* <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}></span><span></span>

                                        <h5>MOQ Quantity</h5>

                                        <p style={{ textTransform: 'capitalize' }}>{moq.quantity}</p>

                                    </ListGroup.Item>   

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}></span><span></span>

                                        <h5>MOQ Min Quantity</h5>

                                        <p style={{ textTransform: 'capitalize' }}>{moq.min_quantity}</p>

                                    </ListGroup.Item> */}

                                    <ListGroup.Item>

                                        <span style={{ fontWeight: 'bold' }}>From: </span><span><Moment format="DD/MM/YYYY">{moq.start_date}</Moment> To <Moment format="DD/MM/YYYY">{moq.end_date}</Moment></span>

                                    </ListGroup.Item>
                                    
                                </ListGroup>    
                                
                            </Col>}

                            {!loading && <Col sm="4">

                                {moq.product !== undefined && moq.product[0].images.length > 0 && <Image src={moq.product[0].images[0]} alt={moq.product[0].name} rounded style={{ height: '200px', width: '100%', objectFit: 'contain', marginTop: 0, paddingTop: 0 }} /> }   
                                
                            </Col>}

                            {!loading && priviledge === 2 && <Col sm="12" style={{ marginTop: '2em' }}>

                                <Button variant="primary" size="sm" as={Link} to={`/admin/moqs/${moq.id}/edit`}>Edit MOQ</Button>
                            
                            </Col>}

                        </Row>

                    </Container>

                </Col>
                
            </Layout>

        )

    }

};

const mapStateToProps = (state, ownProps) => {

    return {

        moqs: state.moqs,

    }

};

export default connect(mapStateToProps, { showMoq })(Show);
